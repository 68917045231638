import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { canvasElementToImageFile } from '../../../utils/canvas';

type CanvasInputProps = {
  className?: string;
  onChange: (
    file:
      | [File, ArrayBuffer, string]
      | ([File, ArrayBuffer, string] | null)[]
      | null,
  ) => void;
};

const CanvasInput = ({ className = '', onChange }: CanvasInputProps) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const pointsRef = useRef<{ x: number; y: number }[]>([]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const scale = 2;
      canvas.width = canvas.clientWidth * scale;
      canvas.height = canvas.clientHeight * scale;

      const context = canvas.getContext('2d');
      if (context) {
        context.scale(scale, scale);
        context.lineCap = 'round';
        context.strokeStyle = 'black';
        context.lineWidth = 3;
        contextRef.current = context;
      }
    }
  }, []);

  const getOffset = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    if ('nativeEvent' in event && 'offsetX' in event.nativeEvent) {
      // Mouse Event
      return {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      };
    } else if ('touches' in event && event.touches.length > 0) {
      // Touch Event for mobile
      const rect = canvasRef.current?.getBoundingClientRect();
      if (rect) {
        const touch = event.touches[0];
        return {
          x:
            (((touch.clientX - rect.left) / rect.width) *
              canvasRef.current!.width) /
            2,
          y:
            (((touch.clientY - rect.top) / rect.height) *
              canvasRef.current!.height) /
            2,
        };
      }
    }
    return null;
  };

  const startDrawing = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    event.preventDefault();
    setIsDrawing(true);
    const point = getOffset(event);
    if (point) {
      pointsRef.current = [point];
    }
  };

  const draw = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    event.preventDefault();
    if (!isDrawing || !contextRef.current) return;

    const point = getOffset(event);
    if (!point) return;

    pointsRef.current.push(point);

    const context = contextRef.current;
    const points = pointsRef.current;

    if (points.length < 2) {
      context.beginPath();
      context.moveTo(points[0].x, points[0].y);
      context.lineTo(points[0].x + 0.1, points[0].y + 0.1);
      context.stroke();
      context.closePath();
      return;
    }

    context.beginPath();
    context.moveTo(points[points.length - 2].x, points[points.length - 2].y);
    context.lineTo(points[points.length - 1].x, points[points.length - 1].y);
    context.stroke();
    context.closePath();

    setIsCanvasEmpty(false);
  };

  const handleSaveAsImageFile = useCallback(() => {
    if (canvasRef.current && !isCanvasEmpty) {
      const file = canvasElementToImageFile(canvasRef.current);
      onChange(file);
    }
  }, [canvasRef, isCanvasEmpty, onChange]);

  const debouncedSave = useMemo(() => debounce(handleSaveAsImageFile, 300), [
    handleSaveAsImageFile,
  ]);

  const stopDrawing = (
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    event.preventDefault();
    setIsDrawing(false);
    if (contextRef.current) {
      contextRef.current.closePath();
      debouncedSave();
    }
    pointsRef.current = [];
  };

  const clearCanvas = (event: any) => {
    event.preventDefault();
    if (canvasRef.current && contextRef.current) {
      contextRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height,
      );
      setIsCanvasEmpty(true);
      onChange(null);
    }
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        className={classNames(className, 'w-full touch-none')}
        onMouseDown={startDrawing}
        onMouseUp={stopDrawing}
        onMouseMove={draw}
        onTouchStart={startDrawing}
        onTouchEnd={stopDrawing}
        onTouchMove={draw}
      />
      {!isCanvasEmpty && (
        <span className="absolute top-0 left-0 flex gap-1 m-2">
          <IconX
            size={24}
            onClick={clearCanvas}
            className="rounded-full stroke-slate-500 border-slate-400 border opacity-50 hover:opacity-100"
          />
        </span>
      )}
    </>
  );
};

export default CanvasInput;

import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { ErrorText } from '@noloco/components';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import { validationBorder } from '@noloco/components/src/utils/validation';
import HelpText from './sections/HelpText';

type Props = {
  className?: string;
  checked?: boolean;
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
};

const Checkbox = forwardRef<any, Props>(
  (
    {
      className,
      children,
      checked,
      // @ts-expect-error TS(2339): Property 'elementId' does not exist on type 'Props... Remove this comment to see the full error message
      elementId,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Props'.
      size,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Props'.
      value,
      // @ts-expect-error TS(2339): Property 'readOnly' does not exist on type 'Props'... Remove this comment to see the full error message
      readOnly,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
      disabled,
      onClick,
      onChange,
      // @ts-expect-error TS(2339): Property 'tabIndex' does not exist on type 'Props'... Remove this comment to see the full error message
      tabIndex,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'help' does not exist on type 'Props'.
      help,
      ...rest
    },
    ref,
  ) => (
    <>
      <Box
        is="label"
        className={classNames('checkbox', className)}
        ref={ref}
        onClick={onClick}
        {...rest}
      >
        <input
          type="checkbox"
          data-testid={elementId}
          id={`${elementId}-checkbox`}
          name={`${elementId}-checkbox`}
          disabled={disabled}
          readOnly={readOnly}
          tabIndex={tabIndex || undefined}
          checked={checked || value === true}
          value={value}
          className={classNames(
            'appearance-none flex-shrink-0 select-none border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-500',
            `text-${theme.brandColors.primary}`,
            {
              'h-3 w-3 rounded-sm': size === SM,
              'h-5 w-5': size === MD,
              'h-6 w-6': size === LG,
              'h-8 w-8': size === XL,
              'rounded-md': size !== SM,
            },
            validationBorder(validationError, theme),
          )}
          onChange={onChange}
        />
        {(children || help) && (
          <div className="flex flex-col max-w-full">
            {children && (
              <span
                className={classNames('max-w-full overflow-hidden ml-4', {
                  'opacity-75 cursor-not-allowed': disabled,
                })}
              >
                {children}
              </span>
            )}
            {help && (
              <div className="max-w-full ml-4">
                <HelpText>{help}</HelpText>
              </div>
            )}
          </div>
        )}
      </Box>
      {validationError && <ErrorText>{validationError}</ErrorText>}
    </>
  ),
);

Checkbox.defaultProps = {
  className: '',
  // @ts-expect-error TS(2322): Type '{ className: string; size: ShirtSize; }' is ... Remove this comment to see the full error message
  size: MD,
};

export default withTheme(Checkbox);

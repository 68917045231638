import React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { ErrorText } from '@noloco/components';
import HelpText from './sections/HelpText';

const RadioInputGroup = ({
  className,
  disabled,
  name,
  options,
  onChange,
  validationError,
  value,
}: any) => (
  <div
    className={classNames(className, 'flex flex-col space-y-3')}
    role="radiogroup"
  >
    {options.map((option: any, index: any) => (
      <div key={`${option.value}-${value}`}>
        <div className="flex flex-col ">
          <div className="flex items-center">
            <input
              className="focus:ring-2 default:ring-2 ring-blue-300 w-5 h-5 rounded-full flex-shrink-0 disabled:opacity-75"
              id={`${name}-${option.value}`}
              disabled={disabled}
              type="radio"
              name={name}
              aria-checked={value === option.value}
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              tabIndex={
                value === option.value || (isNil(value) && index === 0) ? 0 : -1
              }
            />
            <label
              htmlFor={`${name}-${option.value}`}
              className="ml-4 overflow-hidden"
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </label>
          </div>
          {option.help && (
            <div className="max-w-full ml-9">
              <HelpText>{option.help}</HelpText>
            </div>
          )}
        </div>
      </div>
    ))}
    {validationError && <ErrorText>{validationError}</ErrorText>}
  </div>
);

export default RadioInputGroup;

import React, { useMemo } from 'react';
import { Surface } from '@noloco/components';
import {
  FLAT_PLANS_V2,
  SEAT_BASED_PLANS,
} from '@noloco/core/src/constants/accountPlanTypes';
import { AccountPlan } from '@noloco/core/src/constants/accountPlans';
import {
  ACTIVE_INTERNAL_USERS,
  ACTIVE_USERS,
  ACTIVE_WORKFLOWS,
  EXTERNAL_USERS,
} from '@noloco/core/src/constants/features';
import { ExtendedPlanUsage } from '@noloco/core/src/models/Usage';
import Usage from './Usage';
import UsageHighlight from './UsageHighlight';

type Props = {
  className?: string;
  planType: AccountPlan;
  surface?: Surface;
  usage: ExtendedPlanUsage;
};

const AccountUsage = ({ className, planType, surface, usage }: Props) => {
  const isSeatBasedPlan = SEAT_BASED_PLANS.includes(planType);
  const isFlatV2Plan = FLAT_PLANS_V2.includes(planType);

  const accountUsage = useMemo(() => {
    if (!usage) {
      return null;
    }

    if (!isSeatBasedPlan) {
      return usage;
    }

    return {
      externalActiveUsers: usage.externalActiveUsers,
    };
  }, [isSeatBasedPlan, usage]);

  return (
    <div className={className}>
      {(isSeatBasedPlan || isFlatV2Plan) && (
        <div className="grid grid-cols-3 gap-2 mb-6 mt-">
          <UsageHighlight
            value={
              isSeatBasedPlan ? usage.internalActiveUsers : usage.activeUsers
            }
            surface={surface}
            feature={ACTIVE_USERS}
          />
          {isFlatV2Plan && (
            <>
              <UsageHighlight
                value={usage.internalActiveUsers ?? 0}
                surface={surface}
                feature={ACTIVE_INTERNAL_USERS}
              />
              <UsageHighlight
                value={usage.externalActiveUsers ?? 0}
                surface={surface}
                feature={EXTERNAL_USERS}
              />
            </>
          )}
          {isSeatBasedPlan && (
            <UsageHighlight
              value={usage.activeWorkflows}
              surface={surface}
              feature={ACTIVE_WORKFLOWS}
            />
          )}
        </div>
      )}
      <Usage
        filterFeatures={(feature: any) =>
          (feature !== 'internalActiveUsers' || isFlatV2Plan) &&
          feature !== 'rollingActiveUsers'
        }
        surface={surface}
        usage={accountUsage}
      />
    </div>
  );
};

export default AccountUsage;

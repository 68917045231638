import React from 'react';
import { IconComet } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Badge, Tooltip } from '@noloco/components';
import { BUSINESS } from '@noloco/core/src/constants/accountPlans';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import useIsTrialing from '../utils/hooks/useIsTrialing';
import { usePlans } from '../utils/hooks/usePlans';
import useValidFeaturePlan from '../utils/hooks/useValidFeaturePlan';

const ProFeatureBadge = ({
  compact,
  currentNumber,
  feature,
  inline,
  showAfterTrial = false,
  tooltipPlacement,
}: any) => {
  const featureName = getText('features', feature);

  const lowestPlan = useValidFeaturePlan(feature);
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const lowestPlanName = getText('billing.plans', lowestPlan, 'name');

  const isTrialing = useIsTrialing();
  const plans = usePlans();
  const trialLimit = get(plans, [BUSINESS, feature], 'unlimited');

  return (
    lowestPlan &&
    (isTrialing || showAfterTrial) && (
      <Tooltip
        delayHide={300}
        disabled={!isTrialing}
        placement={tooltipPlacement}
        content={
          <div className="flex flex-col text-sm">
            {getText(
              {
                feature: featureName.toLowerCase(),
                lowestPlan: lowestPlanName,
                trialLimit,
              },
              'features.tooltip.trial',
              isNil(currentNumber) || typeof currentNumber !== 'number'
                ? 'boolean'
                : 'usage',
            )}
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              className="mt-2 text-teal-500 hover:underline"
            >
              {getText('features.explore')}
            </a>
          </div>
        }
        bg="white"
      >
        <Badge
          color="pink-500"
          className={classNames('flex items-center space-x-1', {
            '-mt-3 -mr-4': !compact && !inline,
            '-mt-2 -mr-2': compact && !inline,
            'absolute right-0 top-0 z-10': !inline,
          })}
          p={{ x: compact ? 0 : 3, y: 0 }}
        >
          <IconComet size={14} />
          {!compact && <span>{lowestPlanName}</span>}
        </Badge>
      </Tooltip>
    )
  );
};

export default ProFeatureBadge;

import React, { memo } from 'react';
import { IconExternalLink } from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  className?: string;
  surface?: Surface;
};

const PlansHelpLink = memo(({ className, surface }: Props) => {
  return (
    <a
      className={classNames(className, 'flex items-center font-medium ', {
        'text-pink-500 hover:text-pink-400': surface !== DARK,
        'text-teal-500 hover:text-teal-400': surface === DARK,
      })}
      href="https://noloco.io/pricing"
      target="_blank"
      rel="noreferrer noopener"
    >
      <span className="mr-1"> {getText('billing.plan.help')}</span>
      <IconExternalLink size={14} />
    </a>
  );
});

export default PlansHelpLink;

import React, { useCallback, useMemo } from 'react';
import { IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { DARK, LIGHT } from '../../constants/surface';
import { Surface } from '../../models/index';
import { Tooltip } from '../popover/index';

export type CheckListOption = {
  value: string;
  label: string | React.ReactNode;
  tooltip?: string;
  disabled?: boolean | ((value: string[]) => boolean);
};

export type CheckListGroup = {
  groupName: string;
  options: CheckListOption[];
};

export type CheckListOptions = CheckListOption[] | CheckListGroup[];

export type CheckListProps = {
  onChange: (value: string[]) => void;
  options: CheckListOptions;
  selectAllLabel?: string;
  surface?: Surface;
  useColumns?: boolean;
  value: string[];
};

const CheckGroup = ({
  selectAllLabel,
  label,
  options,
  onChange,
  value,
  surface,
}: {
  selectAllLabel?: string;
  label: string;
  options: CheckListOption[];
  onChange: (value: string[] | string) => void;
  value: string[];
  surface: Surface;
}) => {
  const [scopesToAdd, scopesToRemove] = useMemo(
    () =>
      options.reduce(
        (acc: [string[], string[]], option) => {
          if (!value.includes(option.value)) {
            acc[0].push(option.value);
            return acc;
          }

          acc[1].push(option.value);
          return acc;
        },
        [[], []],
      ),
    [options, value],
  );
  return (
    <div className="flex flex-col my-2 space-y-2">
      <div className="flex justify-between">
        <h3 className="text-base">{label}</h3>
        {selectAllLabel && scopesToAdd.length > 0 && (
          <button
            className="text-sm text-blue-500"
            onClick={() => {
              onChange(scopesToAdd);
            }}
          >
            {selectAllLabel}
          </button>
        )}
      </div>
      <ul className="flex flex-col space-y-2">
        {options.map((option) => (
          <CheckBox
            key={option.value}
            value={option.value}
            label={option.label}
            onChange={onChange}
            checked={scopesToRemove.includes(option.value)}
            disabled={
              typeof option.disabled === 'function'
                ? option.disabled(scopesToRemove)
                : option.disabled
            }
            surface={surface}
            tooltip={option.tooltip}
          />
        ))}
      </ul>
    </div>
  );
};

const CheckBox = ({
  value,
  label,
  onChange,
  checked,
  disabled,
  surface,
  tooltip,
}: {
  value: string;
  label: string | React.ReactNode;
  onChange: (value: string) => void;
  checked: boolean;
  disabled?: boolean;
  surface: Surface;
  tooltip?: string;
}) => {
  const listItem = (
    <li
      key={value}
      className={classNames(
        `border  rounded-lg flex items-center justify-between p-2 ${
          surface === LIGHT
            ? 'border-slate-300 hover:border-slate-400'
            : 'border-slate-700 hover:border-slate-600'
        }`,
        {
          'bg-slate-300': checked && surface !== DARK,
          'bg-slate-800': checked && surface === DARK,
          'bg-slate-200': !checked && surface !== DARK,
          'bg-slate-900': !checked && surface === DARK,
          'opacity-50 cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        },
      )}
      aria-checked={checked}
      aria-labelledby={value}
      role="checkbox"
      aria-disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!disabled) {
          onChange(value);
        }
      }}
    >
      <label htmlFor={String(value)}>
        <span className="my-auto text-sm"> {label}</span>
      </label>
      {checked ? (
        <IconCircleCheck
          className="text-cyan-500 flex-shrink-0 my-auto w-10"
          size={22}
        />
      ) : (
        <span className="flex-shrink-0 w-10" />
      )}
    </li>
  );

  return tooltip ? <Tooltip content={tooltip}>{listItem}</Tooltip> : listItem;
};

const CheckList = ({
  options,
  onChange,
  selectAllLabel,
  surface = LIGHT,
  value,
}: CheckListProps) => {
  const handleChange = useCallback(
    (newValue: string | string[]) => {
      let newCheckedItems;

      if (Array.isArray(newValue)) {
        newCheckedItems = newValue.reduce(
          (acc: string[], item: string) => {
            if (value.includes(item)) {
              acc.splice(acc.indexOf(item), 1);
              return acc;
            }

            acc.push(item);
            return acc;
          },
          [...value],
        );
      } else {
        newCheckedItems = value.includes(newValue)
          ? value.filter((item) => item !== newValue)
          : [...value, newValue];
      }

      onChange(newCheckedItems);
    },
    [onChange, value],
  );

  return (
    <ul
      className={classNames(
        'flex flex-col space-y-2 overflow-y-auto rounded-lg pr-2',
      )}
    >
      {options.map((item) => {
        if ('groupName' in item) {
          return (
            <CheckGroup
              key={item.groupName}
              label={item.groupName}
              options={item.options}
              onChange={handleChange}
              value={value}
              selectAllLabel={selectAllLabel}
              surface={surface}
            />
          );
        }

        return CheckBox({
          value: item.value,
          label: item.label,
          onChange: handleChange,
          checked: value.includes(item.value),
          disabled:
            typeof item.disabled === 'function'
              ? item.disabled(value)
              : item.disabled,
          surface,
        });
      })}
    </ul>
  );
};

export default CheckList;

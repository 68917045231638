import React from 'react';
import { Redirect } from 'react-router';
import { Loader } from '@noloco/components';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import DashboardNav from './DashboardNav';

const DashboardWrapper = ({ children }: any) => {
  const { fetchedUser, user } = useDashboardAuth();

  if (fetchedUser && !user) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="w-full min-h-screen flex flex-col bg-slate-700 pb-12">
      <DashboardNav />
      <div className="w-full bg-slate-900 bg-opacity-50 h-36" />
      <div className="-mt-24 w-full px-8 sm:px-1">
        <div className="w-full bg-white rounded-lg">
          {!fetchedUser && (
            <div className="w-full h-96 flex items-center justify-center">
              <Loader size="md" className="text-blue-500" />
            </div>
          )}
          {fetchedUser && children}
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;

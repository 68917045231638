import { JsonMaps } from './BaseArrayTypeMap';
import { DataFieldOption } from './DataTypeFields';
import { NameArrayTypeMap } from './ProjectArrayTypeMap';

class DataFieldOptions extends NameArrayTypeMap<DataFieldOption> {
  static fromJSON(
    json: JsonMaps<DataFieldOption, { nameMap: Record<string, number> }>,
  ) {
    return DataFieldOptions._fromJSON<DataFieldOption>(
      json,
    ) as DataFieldOptions;
  }

  displayMap: Record<string, number>;

  constructor(
    arrayOrLength?:
      | Number
      | DataFieldOption[]
      | NameArrayTypeMap<DataFieldOption>,
  ) {
    super(arrayOrLength);

    if (arrayOrLength instanceof DataFieldOptions) {
      //Avoid re-calculating these maps if already populated
      this.displayMap = arrayOrLength.displayMap;
    } else {
      this.displayMap = {};
    }

    this.maps.displayMap = this.nameMap;
  }

  _mapEntry(entry: DataFieldOption) {
    super._mapEntry(entry);
    if (this.idMap) {
      this.displayMap[entry.display] = entry.id;
    }
  }

  getByDisplay(display: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIdOrUndefined(this.displayMap[display]);
  }
}

export default DataFieldOptions;

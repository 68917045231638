import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { BaseModal, Button, Loader, TextArea } from '@noloco/components';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { ONBOARDING_MUTATION } from '../../queries/onboarding';

const LANG_KEY = 'billing.upgrade';
const steps = {
  WHY: 'why',
};

const UpgradeSubscriptionModal = ({ onClose }: any) => {
  const errorAlert = useGraphQlErrorAlert();
  const [whyText, setWhyText] = useState('');
  const [step] = useState(steps.WHY);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveOnboardingDetails] = useMutation(ONBOARDING_MUTATION);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setSaveLoading(true);
      saveOnboardingDetails({
        variables: {
          onboardingDetails: { whyUpgrade: whyText },
        },
      })
        .then(onClose)
        .catch((error) => {
          errorAlert(getText(LANG_KEY, 'error'), error);
          setSaveLoading(true);
        });
    },
    [errorAlert, onClose, saveOnboardingDetails, whyText],
  );

  return (
    <BaseModal size={MD} closeOnOutsideClick={false}>
      <div className="w-full p-8 bg-flex flex-col items-center">
        {step === steps.WHY && (
          <div>
            <h2 className="text-xl text-center text-gray-800">
              {getText(LANG_KEY, 'why.title')}
            </h2>
            <h2 className="mt-1 text-sm text-center text-gray-500">
              {getText(LANG_KEY, 'why.subtitle')}
            </h2>
            <div className="flex flex-col mt-4">
              <TextArea
                onChange={({ target: { value } }: any) => setWhyText(value)}
                rows={6}
                placeholder={getText(LANG_KEY, 'why.placeholder')}
                autofocus={true}
              />
              <Button
                className="mt-4 flex items-center justify-center"
                size="md"
                onClick={handleSubmit}
                disabled={!whyText}
              >
                {saveLoading && <Loader size="sm" className="mr-2" />}
                {getText(LANG_KEY, 'submit')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default UpgradeSubscriptionModal;

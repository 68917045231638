import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  IconArrowNarrowRight,
  IconChevronRight,
  IconCircleNumber1,
  IconCircleNumber2,
} from '@tabler/icons-react';
import classNames from 'classnames';
import {
  Badge,
  Button,
  ErrorText,
  FormField,
  HelpTooltip,
  Label,
  Loader,
  SelectInput,
  TextInput,
} from '@noloco/components';
import { TEXT } from '@noloco/components/src/components/button/buttonTypes';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { XANO } from '@noloco/core/src/constants/dataSources';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import xanoLogo from '../../img/xano-logo.png';
import { GET_XANO_WORKSPACES } from '../../queries/project';
import { useAddDataSource } from '../../utils/hooks/useAddDataSource';
import Guide from '../Guide';
import DataSourceImportStatus from './DataSourceImportStatus';

const LANG_KEY = 'data.xano';

const FORM_STEPS = 2;

type XanoWorkspace = {
  id: number;
  name: string;
  description?: string;
  domain: string;
  customDomain?: string;
  instanceDisplay: string;
  instanceName: string;
  connected: boolean;
};

const AddXanoWorkspace = ({
  inOnboarding = false,
  project,
  onConnect,
  surface = DARK,
}: any) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const {
    query: { id, sourceName },
  }: any = useRouter();
  const [workspaceName, setWorkspaceName] = useState(sourceName ?? 'Xano');
  const [authToken, setAuthToken] = useState<string | null>('');
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | null>(
    null,
  );

  useSetDocumentTitle(`${getText(LANG_KEY, 'title')} Xano`);

  const {
    data: workspacesData,
    error: workspaceListError,
    refetch,
    loading,
  } = useQuery(GET_XANO_WORKSPACES, {
    skip: !authToken && !id,
    variables: {
      authToken,
      dataSourceId: !authToken && id ? id : undefined,
      projectId: project.name,
    },
    notifyOnNetworkStatusChange: true,
  });

  const connection = useMemo(() => {
    if (!selectedWorkspaceId || !workspacesData) {
      return undefined;
    }

    const selectedWorkspace:
      | XanoWorkspace
      | undefined = workspacesData.xanoWorkspaces.find(
      (workspace: XanoWorkspace) => workspace.id === selectedWorkspaceId,
    );

    if (!selectedWorkspace) {
      return undefined;
    }

    return {
      authToken,
      workspaceId: selectedWorkspace.id,
      instanceName: selectedWorkspace.instanceName,
      domain: selectedWorkspace.domain,
      customDomain: selectedWorkspace.customDomain,
    };
  }, [authToken, selectedWorkspaceId, workspacesData]);

  const {
    createdDataSource,
    error,
    existingDataSource,
    hasSyncedData,
    isConnect,
    isConnecting,
    isNameValid,
    isUpdate,
    isUpdating,
    onClickNext,
    onDataTypesSelected,
    onFinish,
    step,
  } = useAddDataSource({
    project,
    connection,
    display: workspaceName,
    type: XANO,
    formSteps: FORM_STEPS,
    onAuthenticationFail: null,
    inOnboarding,
    canShowTableChooser: true,
  });

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTo({
        top: bottomRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [step, bottomRef]);

  useEffect(() => {
    if (existingDataSource && Object.keys(existingDataSource).length > 0) {
      setWorkspaceName(existingDataSource.display);
      setAuthToken(null);
      setSelectedWorkspaceId(existingDataSource.connection.workspaceId);
    }
  }, [existingDataSource]);

  const stepValidStatus = useMemo(
    () => [
      isNameValid && (!!authToken || existingDataSource),
      !!selectedWorkspaceId,
    ],
    [authToken, existingDataSource, isNameValid, selectedWorkspaceId],
  );

  const isStepValid = useMemo(() => stepValidStatus[step], [
    step,
    stepValidStatus,
  ]);

  const workspaces = useMemo(() => {
    if (!workspacesData) {
      return [];
    }

    return workspacesData.xanoWorkspaces.map((workspace: XanoWorkspace) => ({
      value: workspace.id,
      disabled: workspace.connected,
      label: (
        <div className="flex items-center space-x-2 truncate text-base font-medium">
          <span>{workspace.instanceDisplay}</span>
          <IconChevronRight size={16} className="flex-shrink-0 opacity-75" />
          <span>{workspace.name}</span>
        </div>
      ),
      help: workspace.domain,
    }));
  }, [workspacesData]);

  return (
    <div className="w-full flex">
      <div
        className={classNames('w-full max-w-xl text-white p-8', {
          'bg-slate-700': !inOnboarding,
          'border-r border-gray-100': inOnboarding,
        })}
      >
        {!inOnboarding && (
          <>
            <h1 className="text-xl flex items-center">
              <span>{getText(LANG_KEY, 'title')}</span>
              <img src={xanoLogo} alt="Xano logo" className="h-8 ml-2" />
              <Badge color="pink" m={{ l: 'auto' }} ignoreDarkMode={true}>
                {getText('leftSidebar.data.beta')}
              </Badge>
            </h1>
            <Guide
              className="mt-4 mb-8"
              href="https://guides.noloco.io/data/xano"
            >
              {getText(LANG_KEY, 'help')}
            </Guide>
          </>
        )}
        <FormField
          name="name"
          type="text"
          readOnly={step > 0}
          disabled={step > 0}
          onChange={({ target: { value } }: any) => setWorkspaceName(value)}
          required
          errorMessage={
            !workspaceName || isNameValid
              ? null
              : getText(LANG_KEY, 'name.invalid')
          }
          errorType="below-solid"
          label={getText(LANG_KEY, 'name.label')}
          help={getText(LANG_KEY, 'name.help')}
          placeholder={getText(LANG_KEY, 'name.placeholder')}
          value={workspaceName}
          surface={surface}
        />
        <label
          className={classNames(
            'flex items-center text-sm leading-5 mt-8 mb-1 font-medium',
            {
              'text-gray-700': surface === LIGHT,
              'text-gray-100': surface === DARK,
            },
          )}
        >
          <IconCircleNumber1 size={22} className="mr-2" />
          <span>{getText(LANG_KEY, 'connection.label')}</span>
        </label>
        <div className="flex flex-col mt-8">
          <Label surface={surface}>
            {getText(LANG_KEY, 'connection.authToken.label')}
          </Label>
          <ul
            className={classNames('list-disc text-sm mb-4 pl-4 space-y-1', {
              'text-gray-600':
                surface === LIGHT || !surface || surface === 'default',
              'text-gray-200': surface === DARK,
            })}
          >
            <li>
              <a
                className="text-teal-500 hover:underline"
                href="https://app.xano.com/admin/account"
                target="_blank"
                rel="noopener noreferrer"
              >
                {getText(LANG_KEY, 'connection.authToken.steps.navigate')}
              </a>
            </li>
            <li>
              <a
                className="text-teal-500 hover:underline"
                href="https://docs.xano.com/metadata-api#personal-access-token"
                target="_blank"
                rel="noopener noreferrer"
              >
                {getText(LANG_KEY, 'connection.authToken.steps.create')}
              </a>
            </li>
            <li>
              {getText(LANG_KEY, 'connection.authToken.steps.permissions')}
            </li>
            <li>{getText(LANG_KEY, 'connection.authToken.steps.copy')}</li>
          </ul>
        </div>
        <TextInput
          name="authToken"
          onChange={({ target: { value } }: any) => setAuthToken(value)}
          placeholder="*************"
          required
          surface={surface}
          type="password"
          value={existingDataSource && !authToken ? '--encrypted--' : authToken}
        />
        {step > 0 && (
          <div className="flex flex-col mt-8">
            <div className="flex items-center justify-between mb-2">
              <Label
                className={classNames('flex items-center', {
                  'text-white': surface !== LIGHT,
                  'text-gray-600': surface === LIGHT,
                })}
                m={0}
              >
                <IconCircleNumber2 size={22} className="mr-2" />
                <span>{getText(LANG_KEY, 'connection.workspace.label')}</span>
                <HelpTooltip className="ml-2">
                  <span>{getText(LANG_KEY, 'connection.workspace.help')}</span>
                </HelpTooltip>
              </Label>
              <div className="ml-auto">
                <Button
                  type={TEXT}
                  size="sm"
                  surface={surface}
                  className="flex items-center justify-center"
                  onClick={() => {
                    refetch();
                    setSelectedWorkspaceId(null);
                  }}
                  disabled={loading}
                >
                  {loading && <Loader size="xs" className="mr-2" />}
                  {getText(LANG_KEY, 'connection.workspace.refresh')}
                </Button>
              </div>
            </div>
            <SelectInput
              options={workspaces}
              value={selectedWorkspaceId}
              onChange={setSelectedWorkspaceId}
              placeholder={getText(
                LANG_KEY,
                'connection.workspace.placeholder',
              )}
              searchable={true}
              surface={surface}
            />
            {workspaceListError && !loading && (
              <ErrorText className="flex flex-col space-y-1">
                <span className="text-sm">
                  {getText(LANG_KEY, 'connection.workspace.error')}
                </span>
                <span className="text-xs opacity-75">
                  {getTextFromError(workspaceListError).message}
                </span>
              </ErrorText>
            )}
          </div>
        )}
        {step < 2 && (
          <Button
            className="disabled:opacity-75 flex items-center mt-6"
            disabled={!isStepValid}
            onClick={onClickNext}
          >
            {isUpdate && isUpdating ? (
              <>
                <span>{getText(LANG_KEY, 'updating')}</span>
                <Loader size="sm" className="ml-2" />
              </>
            ) : (
              <>
                <span>{getText(LANG_KEY, 'next')}</span>
                <IconArrowNarrowRight size={16} className="opacity-75 ml-2" />
              </>
            )}
          </Button>
        )}
      </div>
      <div
        className={classNames('w-full max-h-screen overflow-y-auto', {
          'bg-slate-800': !inOnboarding,
        })}
        ref={inOnboarding ? null : bottomRef}
      >
        <div
          className={classNames('w-full flex items-center justify-center', {
            'max-h-screen-75 overflow-y-auto': inOnboarding,
          })}
          ref={inOnboarding ? bottomRef : null}
        >
          {((isConnect && step >= FORM_STEPS) || error) && (
            <DataSourceImportStatus
              createdDataSource={createdDataSource}
              error={error}
              formSteps={FORM_STEPS}
              hasSyncedData={hasSyncedData}
              isConnecting={isConnecting}
              onDataTypesSelected={onDataTypesSelected}
              onFinish={inOnboarding ? onConnect : onFinish}
              project={project}
              sourceType={XANO}
              step={step}
              inOnboarding={inOnboarding}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddXanoWorkspace;

import React, { forwardRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { SelectInput } from '@noloco/components';
import { AUTH_WRAPPER_ID } from '@noloco/core/src/constants/auth';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import {
  EMPTY,
  FALSE,
  IN,
  NOT_EMPTY,
  NOT_IN,
  TRUE,
} from '@noloco/core/src/constants/operators';
import { Project } from '@noloco/core/src/models/Project';
import { getDataItemDataType } from '@noloco/core/src/utils/data';
import { getFieldFromDependency } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { getOperatorsForFieldType } from '@noloco/core/src/utils/operator';
import ConditionValueEditor from '../canvas/ConditionValueEditor';
import ContentDisplayName from '../canvas/ContentDisplayName';

type OwnProps = {
  contained: boolean;
  inline: boolean;
  project: Project;
  fieldOptions: any[];
  updateCondition: (...args: any[]) => any;
  elementPath: any[];
  condition: {
    name?: any;
    operator?: string;
    value?: any;
  };
  FieldItem?: React.ForwardRefExoticComponent<
    { value: any } & React.RefAttributes<any>
  >;
  ValueInput?: React.ReactNode;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ConditionEditor.defaultProps;

// @ts-expect-error TS(7022): 'ConditionEditor' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ConditionEditor = ({
  contained,
  dataType,
  inline,
  fieldOptions = [],
  updateCondition,
  condition,
  elementPath,
  project,
  FieldItem,
  ValueInput,
}: Props) => {
  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
  const DefaultFieldItem = forwardRef(({ value }, ref) => (
    <ContentDisplayName
      dataOptions={fieldOptions}
      data={value}
      parentPath={elementPath}
      ref={ref}
    />
  ));

  const onFieldChange = (newField: any) => updateCondition(['field'], newField);
  const onOperatorChange = (newOperator: any) =>
    updateCondition(['operator'], newOperator);

  const onValueChange = useCallback(
    (newValue) => updateCondition(['value'], newValue),
    [updateCondition],
  );

  const debouncedOnValueChange = useMemo(() => debounce(onValueChange, 500), [
    onValueChange,
  ]);

  const conditionDataType = useMemo(() => {
    const conditionFieldId = get(condition, ['field', 'id']);
    if (conditionFieldId === AUTH_WRAPPER_ID) {
      return project.dataTypes.getByName(USER);
    }

    return dataType;
  }, [condition, dataType, project.dataTypes]);

  const { field, fieldDataType, fieldType, parent } = useMemo(() => {
    if (
      !condition ||
      !condition.field ||
      !condition.field.path ||
      !conditionDataType
    ) {
      return {};
    }

    const baseDataType = getDataItemDataType(condition.field);
    const fieldResult = getFieldFromDependency(
      // We can remove `._columns.id` to trick it into using the parent multi-field
      condition.field.path.replace(/\._columns\.id$/, '').split('.'),
      conditionDataType,
      project.dataTypes,
    );

    if (!fieldResult) {
      return {
        fieldType: baseDataType,
      };
    }
    return {
      fieldDataType: fieldResult.dataType,
      fieldType: fieldResult.field.type,
      field: fieldResult.field,
      parent: fieldResult.parent,
    };
  }, [condition, conditionDataType, project.dataTypes]);

  const operatorOptions = useMemo(
    () =>
      (condition.field ? getOperatorsForFieldType(fieldType) : []).map(
        (op) => ({
          label: getText('operators', op, 'label.default'),
          value: op,
        }),
      ),
    [condition.field, fieldType],
  );

  return (
    <div
      className={classNames('flex', {
        'flex-col justify-center space-y-4': !inline,
        'items-center gap-2 flex-wrap': inline,
      })}
    >
      <SelectInput
        Button={FieldItem ?? DefaultFieldItem}
        contained={contained}
        onChange={onFieldChange}
        options={fieldOptions}
        placeholder={getText('conditionEditor.field.placeholder')}
        searchable={true}
        shiftRight={true}
        value={condition.field}
      />
      <SelectInput
        value={condition.operator}
        contained={contained}
        options={operatorOptions}
        onChange={onOperatorChange}
        placeholder={getText('conditionEditor.operator.placeholder')}
      />
      {![EMPTY, NOT_EMPTY, TRUE, FALSE].includes(condition.operator) && (
        <ValueInput
          field={field}
          dataType={fieldDataType}
          project={project}
          contained={contained}
          onChange={debouncedOnValueChange}
          disabled={!condition.operator}
          multiple={[IN, NOT_IN].includes(condition.operator)}
          elementPath={elementPath}
          parent={parent}
          placeholder={getText('conditionEditor.value.placeholder')}
          value={condition.value || []}
        />
      )}
    </div>
  );
};

ConditionEditor.defaultProps = {
  contained: true,
  ValueInput: ConditionValueEditor,
};

export default ConditionEditor;

import React, { memo, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconClock } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Notice } from '@noloco/components/src';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import { CANCELED } from '@noloco/core/src/constants/accountStatus';
import { BillingOrTeamPlan } from '@noloco/core/src/models/BillingPlan';
import { isTrialActive } from '@noloco/core/src/utils/billing';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import { getText } from '@noloco/core/src/utils/lang';

type TrialBannerProps = {
  children: any;
  plan: BillingOrTeamPlan;
  href?: string;
  to?: string;
  target?: string;
  rel?: string;
  linkIs?: any;
};

const TrialBanner = memo(
  ({ children, plan, linkIs, ...rest }: TrialBannerProps) => {
    const hasActiveTrial = isTrialActive(plan as BillingOrTeamPlan);

    const trialHasExpired = useIsTrialExpired();
    const isCancelled = useMemo(() => plan?.status === CANCELED, [
      plan?.status,
    ]);

    const remainingTrialDays = hasActiveTrial
      ? Math.ceil(
          DateTime.fromISO(plan.trialEnd as string)
            .diffNow(['days'])
            .toObject().days as number,
        )
      : null;

    if (hasActiveTrial || trialHasExpired || isCancelled) {
      return (
        <Notice
          className="my-6"
          type={PRIMARY}
          icon={IconClock}
          title={getText(
            'billing.trial',
            trialHasExpired ? 'expired' : isCancelled ? 'canceled' : 'free',
            'title',
          )}
          subtitle={getText(
            {
              remainingDays: remainingTrialDays as number,
              endDate: DateTime.fromISO(plan.trialEnd as string).toLocaleString(
                DateTime.DATE_MED,
              ),
            },
            'billing.trial',
            trialHasExpired ? 'expired' : isCancelled ? 'canceled' : 'free',
            'description',
          )}
        >
          <div className="flex items-center justify-center ml-6">
            <Box
              is={linkIs}
              {...rest}
              className="flex text-sm bg-pink-300 text-pink-800 font-medium hover:bg-pink-200 py-2 px-3 rounded-lg hover:text-pink-700 whitespace-nowrap"
            >
              {children}
            </Box>
          </div>
        </Notice>
      );
    }

    return null;
  },
);

export default TrialBanner;

import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/fp/omit';
import get from 'lodash/get';

const formFieldsSlice = createSlice({
  name: 'formFields',
  initialState: { updateAllRecords: false } as Record<any, any>,
  reducers: {
    setFieldValue: (
      state,
      { payload: { dataTypeName, id, fieldName, value } },
    ) => {
      if (!state[dataTypeName]) {
        state[dataTypeName] = {};
      }

      if (!state[dataTypeName][id]) {
        state[dataTypeName][id] = {
          values: {},
          metadata: {},
        };
      }

      state[dataTypeName][id].values[fieldName] = value;
      state[dataTypeName][id].metadata[fieldName] = Date.now();
    },
    clearFieldValues: (
      state,
      { payload: { dataTypeName, id, fieldNames, updateTime } },
    ) => {
      if (get(state, [dataTypeName, id])) {
        const fieldsToClear = fieldNames.filter(
          (fieldName: string) =>
            (get(
              state,
              [dataTypeName, id, 'metadata', fieldName],
              0,
            ) as number) < updateTime,
        );

        state[dataTypeName][id].values = omit(
          fieldsToClear,
          state[dataTypeName][id].values,
        );
        state[dataTypeName][id].metadata = omit(
          fieldsToClear,
          state[dataTypeName][id].metadata,
        );
      }
    },
    setUpdateAllRecords: (state, { payload }) => {
      state.updateAllRecords = payload;
    },
  },
});

export const {
  clearFieldValues,
  setFieldValue,
  setUpdateAllRecords,
} = formFieldsSlice.actions;

export default formFieldsSlice.reducer;

import React from 'react';
import { getText } from '@noloco/core/src/utils/lang';
import airReading from '../img/airreading.png';
import redCross from '../img/american-red-cross.png';
import baldridge from '../img/baldridge.png';
import fdWorks from '../img/fdworks.png';
import fergmar from '../img/fergmar.png';
import fiera from '../img/fiera.png';
import flatiron from '../img/flatiron.png';
import gbstax from '../img/gbstax.png';
import airtableBoard from '../img/login/airtable-table.png';
import nolocoUiComponents from '../img/noloco-ui-components.svg';
import phoenix from '../img/phoenix.png';
import saasStock from '../img/saastock.png';

const logos = [
  redCross,
  flatiron,
  saasStock,
  gbstax,
  fergmar,
  fiera,
  phoenix,
  fdWorks,
  baldridge,
  airReading,
];

const SidePanel = () => (
  <div className="bg-gray-100 w-full h-full flex items-center py-32">
    <div className="h-full w-full ml-auto -mr-16 lg:-mr-16 rounded-lg bg-cover bg-top-left max-w-5xl bg-no-repeat flex items-center">
      <div className="grid grid-rows-1 grid-cols-1 relative z-20">
        <img
          src={airtableBoard}
          alt="Airtable base"
          className="w-full h-auto shadow col-span-1 col-start-1 row-start-1 row-span-1 rounded"
        />
        <div className="grid grid-rows-1 grid-cols-1 w-full h-auto row-start-1 row-span-1 col-span-1 col-start-1 ml-8 mt-10">
          <img
            src={nolocoUiComponents}
            key={nolocoUiComponents}
            className="w-full h-auto top-0 left-0 col-span-1 col-start-1 row-start-1 row-span-1 rounded"
            alt="Noloco UI Components"
          />
        </div>
        <div className="mt-8 pr-32">
          <h1 className="text-2xl font-bold">
            {getText('auth.testimonial.title')}
          </h1>
          <h2 className="text-lg">{getText('auth.testimonial.description')}</h2>
          <div className="mt-4">
            <h1 className="text-lg">{getText('auth.testimonial.name')}</h1>
            <h1 className="text-sm text-gray-400">
              {getText('auth.testimonial.occupation')},{' '}
              {getText('auth.testimonial.company')}
            </h1>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 h-12 w-7/12 opacity-40 overflow-hidden">
        <div className="overflow-hidden">
          <div className="flex -mx-4 img-ticker bg-gray-100">
            {logos.map((logo) => (
              <img
                src={logo}
                key={logo}
                className="w-auto h-8 mx-4 self-start flex-none"
                alt={logo}
              />
            ))}
          </div>

          <div className="absolute top-0 flex -mx-4 img-ticker2 bg-gray-100">
            {logos.map((logo) => (
              <img
                src={logo}
                key={logo}
                className="w-auto h-8 mx-4 self-start flex-none"
                alt={logo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SidePanel;

import React, { useCallback, useMemo } from 'react';
import first from 'lodash/first';
import { Surface } from '@noloco/components';
import RelationalDataFieldInput from '@noloco/core/src/elements/sections/collections/filters/RelationalDataFieldInput';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import {
  BaseRecord,
  CollectionConnection,
} from '@noloco/core/src/models/Record';
import DynamicSwitch from './DynamicSwitch';

const ADDITIONAL_FIELDS = {};

type StaticRelatedFieldInputProps = {
  field: DataField;
  multiple: boolean;
  project: Project;
  isOpen: boolean;
  onClose: () => void;
  placeholder: string | undefined;
  value: StringPropSegment[];
  surface: Surface;
  onChange: (value: StringPropSegment[] | null) => void;
  onSetDynamic: (dynamic: boolean) => void;
};

const StaticRelatedFieldInput = ({
  field,
  isOpen,
  multiple,
  project,
  placeholder,
  value,
  surface,
  onChange,
  onClose,
  onSetDynamic,
}: StaticRelatedFieldInputProps) => {
  const fieldValue = useMemo(() => {
    if (value.length === 0) {
      return null;
    }

    if (multiple) {
      const edges = value
        .filter((valueItem) => valueItem.text && valueItem.static)
        .map((valueItem) => ({ node: { id: valueItem.text } }));

      return { edges };
    }

    const firstValue = first(
      value.filter((valueItem) => valueItem.text && valueItem.static),
    );

    if (firstValue) {
      return { id: firstValue.text };
    }

    return null;
  }, [multiple, value]);

  const onValueChange = useCallback(
    (nextValue: CollectionConnection | BaseRecord | null) => {
      if (!nextValue) {
        onChange(null);
      } else {
        if (multiple) {
          const connectionValue = nextValue as CollectionConnection;

          onChange(
            connectionValue.edges.reduce((acc, edge, index) => {
              if (index > 0) {
                acc.push({ text: ',' });
              }

              acc.push({ text: String(edge.node.id), static: true });

              return acc;
            }, [] as StringPropSegment[]),
          );
        } else {
          const connectionValue = nextValue as BaseRecord;
          onChange([{ text: String(connectionValue.id), static: true }]);
        }
      }
    },
    [multiple, onChange],
  );

  return (
    <RelationalDataFieldInput
      className="sm:w-full min-w-64"
      additionalFields={ADDITIONAL_FIELDS}
      authQuery={true}
      field={field}
      footer={<DynamicSwitch value={false} onChange={onSetDynamic} />}
      multiple={multiple}
      dataTypes={project.dataTypes}
      projectName={project.name}
      placeholder={placeholder}
      placement="top-start"
      open={isOpen ? true : undefined}
      onOpenChange={isOpen ? onClose : undefined}
      value={fieldValue}
      onChange={onValueChange}
      surface={surface}
      project={project}
    />
  );
};

export default StaticRelatedFieldInput;

import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FIELD_VALIDATION_RULES } from '../../../constants/features';
import { failedConditions, staticConditions } from '../../../utils/data';
import { formFieldIsRequired, isFormFieldEmpty } from '../../../utils/fields';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE } from '../../../utils/scope';
import { renderValidationRuleErrors } from '../../../utils/validationRules';
import HelpText from '../HelpText';
import DataFieldInput from './DataFieldInput';

const AutoFormField = ({
  authQuery,
  className,
  config,
  dataItem,
  dataType,
  dataTypes,
  depth,
  disabled,
  field,
  handleBlur,
  hasChanged,
  inline,
  isFieldConditionallyRequired,
  Label = 'div',
  mutationType,
  onFieldFailsValidation,
  onFieldPassesValidation,
  project,
  readOnly,
  ReadOnlyCell,
  removeFiles,
  scope,
  surface,
  updateDataItemField,
  additionalRelatedFields,
  canBulkUpdate,
}: any) => {
  const fieldValue = get(scope, [RECORD_SCOPE, field.name]);

  const fieldIsProvided = useMemo(() => !isFormFieldEmpty(field, fieldValue), [
    field,
    fieldValue,
  ]);

  const isValidationEnabled = useIsFeatureEnabled(FIELD_VALIDATION_RULES);

  const fieldIsRequired = useMemo(
    () =>
      formFieldIsRequired(
        field,
        config,
        mutationType,
        hasChanged,
        isValidationEnabled,
        isFieldConditionallyRequired,
      ),
    [
      field,
      config,
      isValidationEnabled,
      isFieldConditionallyRequired,
      mutationType,
      hasChanged,
    ],
  );
  const requiredFieldIsEmpty = useMemo(
    () => fieldIsRequired && !fieldIsProvided,
    [fieldIsRequired, fieldIsProvided],
  );

  const configuredConditionsToRender = useMemo(
    () =>
      isValidationEnabled
        ? failedConditions(config.validationRules, scope, project)
        : [],
    [config.validationRules, isValidationEnabled, project, scope],
  );
  const staticConditionsToRender = useMemo(
    () => failedConditions(staticConditions(field), scope, project),
    [field, project, scope],
  );
  const conditionsToRender = useMemo(
    () => configuredConditionsToRender.concat(staticConditionsToRender),
    [configuredConditionsToRender, staticConditionsToRender],
  );
  const fieldFailsValidationRules = useMemo(
    () => fieldIsProvided && conditionsToRender.length > 0,
    [conditionsToRender.length, fieldIsProvided],
  );

  const validationError = useMemo(
    () =>
      requiredFieldIsEmpty && hasChanged
        ? getText(
            { fieldName: config.label },
            'elements.VIEW.fields.validationRules.required',
          )
        : fieldFailsValidationRules
        ? renderValidationRuleErrors(conditionsToRender, field)
        : undefined,
    [
      conditionsToRender,
      config.label,
      fieldFailsValidationRules,
      hasChanged,
      requiredFieldIsEmpty,
      field,
    ],
  );

  const failsValidation = useMemo(
    () => requiredFieldIsEmpty || fieldFailsValidationRules,
    [fieldFailsValidationRules, requiredFieldIsEmpty],
  );

  useEffect(() => {
    if (failsValidation) {
      onFieldFailsValidation && onFieldFailsValidation(field);
    } else {
      onFieldPassesValidation && onFieldPassesValidation(field);
    }
  }, [
    config.validationRules,
    failsValidation,
    field,
    onFieldFailsValidation,
    onFieldPassesValidation,
  ]);

  return (
    <div
      className={classNames(
        'flex flex-col',
        className,
        config.className,
        `form-field-${field.id}`,
      )}
      key={`input-${field.name}-${get(dataItem, 'id')}`}
    >
      {(config.label || config.helpText) && (
        <div className="mb-2">
          <div className="flex">
            {config.label && (
              <Label
                config={config}
                dataTypes={dataTypes}
                field={field}
                htmlFor={field.name}
                label={config.label}
                required={fieldIsRequired}
              />
            )}
          </div>
          {config.helpText && HelpText && (
            <HelpText>{config.helpText}</HelpText>
          )}
        </div>
      )}
      <DataFieldInput
        id={`input-${dataType.name}-${field.name}`}
        autoFocus={config.autoFocus}
        authQuery={authQuery}
        canEdit={!config.readOnly && !readOnly}
        customFilters={config.customFilters}
        collectionFilter={config.collectionFilter}
        dataTypes={dataTypes}
        disabled={disabled}
        depth={depth}
        field={field}
        fileLayout={config.fileLayout}
        inline={inline}
        inputType={config.inputType}
        orderBy={config.orderBy}
        onBlur={handleBlur}
        onChange={updateDataItemField(field)}
        onRemoveFiles={removeFiles(field)}
        allowNewRecords={config.allowNewRecords}
        onlyAllowNewRecords={config.onlyAllowNewRecords}
        newRecordFormFields={
          config.allowNewRecords ? config.newRecordFields : undefined
        }
        newRecordFormTitle={
          config.allowNewRecords ? config.newRecordTitle : undefined
        }
        placeholder={config.placeholder}
        validationError={validationError}
        value={get(dataItem, [field.apiName])}
        projectName={project.name}
        project={project}
        surface={surface}
        ReadOnlyCell={config.ReadOnlyCell || ReadOnlyCell}
        required={config.required}
        additionalRelatedFields={additionalRelatedFields}
        canBulkUpdate={canBulkUpdate}
        optionsConfig={config.optionsConfig}
        fileTypeValidation={config.fileTypeValidation}
      />
    </div>
  );
};

export default AutoFormField;

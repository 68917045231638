import React, { forwardRef, useMemo } from 'react';
import {
  TailwindThemeProvider,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconX } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { DARK, LIGHT } from '../../constants/surface';
import { MD } from '../../constants/tShirtSizes';
import { PRIMARY } from '../../constants/variants';
import { oneOfVariants } from '../../utils';
import { getColorShade } from '../../utils';
import Button from '../button/Button';
import { Loader } from '../loading';
import BaseModal from './BaseModal';

type Props = {
  children?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  confirmText?: string | React.ReactNode;
  title: string | React.ReactNode;
  closeOnOutsideClick?: boolean;
  contextual?: boolean;
  loading?: boolean;
  onCancel?: (...args: any[]) => any;
  onConfirm?: (...args: any[]) => any;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  confirmDisabled?: boolean;
  open?: boolean;
  canCancel?: boolean;
  canConfirm?: boolean;
  rootSelector?: string;
  size?: any; // TODO: PropTypes.oneOf(tShirtSizes)
  // @ts-expect-error TS(2749): 'oneOfVariants' refers to a value, but is being us... Remove this comment to see the full error message
  variant?: oneOfVariants;
};

const stopPropagation = (e: any) => {
  try {
    if (
      !e.target ||
      !get(e, ['target', 'className'], '').includes('editor-listitem')
    ) {
      e.stopPropagation();
    }
  } catch (error) {
    console.log('Error stopping propagation. Target:', e.target);
  }
};

const Modal = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'additionalButtons' does not exist on typ... Remove this comment to see the full error message
      additionalButtons,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      canCancel,
      canConfirm,
      cancelText,
      confirmText,
      closeOnOutsideClick,
      confirmDisabled,
      contextual,
      loading,
      open,
      onConfirm,
      onCancel,
      onClose,
      // @ts-expect-error TS(2339): Property 'icon' does not exist on type 'Props'.
      icon,
      size,
      variant,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      title,
      rootSelector,
      ...rest
    },
    ref,
  ) => {
    const handleConfirm = (event: any) => {
      if (!confirmDisabled) {
        if (onConfirm) {
          onConfirm(event);
        }
      }
    };

    const handleCancel = (event: any) => {
      if (onCancel) {
        onCancel(event);
      }
    };

    const variantColor = theme.brandColors[variant];
    const iconBg = getColorShade(variantColor, '200');

    const lightTheme = useMemo(() => {
      const lightThemeObject = {
        surface: LIGHT,
        textColor: 'text-gray-800',
      };
      return set(
        'textInput',
        lightThemeObject,
        set(
          'selectInput',
          lightThemeObject,
          set('dropzone', lightThemeObject, theme),
        ),
      );
    }, [theme]);

    const darkTheme = useMemo(() => {
      const lightThemeObject = {
        surface: DARK,
        textColor: 'white',
      };
      return set(
        'textInput',
        lightThemeObject,
        set(
          'selectInput',
          lightThemeObject,
          set('dropzone', lightThemeObject, theme),
        ),
      );
    }, [theme]);

    return (
      <BaseModal
        className={className}
        closeOnOutsideClick={closeOnOutsideClick}
        contextual={contextual}
        onClose={onClose}
        open={open}
        ref={ref}
        rootSelector={rootSelector}
        size={size}
        {...rest}
      >
        <TailwindThemeProvider
          theme={theme?.modal?.surface === DARK ? darkTheme : lightTheme}
        >
          <div
            className={`w-full flex flex-shrink-0 p-6 pb-4 sm:p-4 ${
              theme?.modal?.backgroundColor
            } border-b ${theme?.modal?.borderColor ?? ''}`}
            onClick={stopPropagation}
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                {icon && (
                  <div
                    className={`ml-0 mr-4 flex-shrink-0 flex items-center justify-center sm:h-12 sm:w-12 rounded-full bg-${iconBg} bg-opacity-75 text-gray-700 sm:mx-auto h-10 w-10`}
                  >
                    {icon}
                  </div>
                )}
                <h3
                  className={`text-lg leading-6 font-medium ${theme?.modal?.textColor}`}
                  id="modal-headline"
                >
                  {title}
                </h3>
              </div>
              {canCancel && (
                <IconX
                  onClick={handleCancel}
                  className="text-slate-400 cursor-pointer"
                  size={16}
                />
              )}
            </div>
          </div>
          {children && (
            <div
              className={`sm:px-4 pt-0 sm:pb-4 p-6 pb-4 overflow-y-auto ${theme?.modal?.backgroundColor} sm:h-screen`}
              onClick={stopPropagation}
            >
              {children && (
                <div className="mt-2">
                  <div
                    className={`text-sm leading-5 flex flex-col flex-grow modal-body ${theme?.modal?.secondaryTextColor}`}
                  >
                    {children}
                  </div>
                </div>
              )}
            </div>
          )}
          {(canConfirm || canCancel) && (
            <div
              className={`flex justify-end flex-shrink-0 space-x-3 px-6 py-3 sm:gap-2 sm:px-4 sm:space-x-0 sm:grid sm:grid-cols-2 border-t ${
                theme?.modal?.secondaryBackgroundColor
              } ${theme?.modal?.borderColor ?? ''}`}
              onClick={stopPropagation}
            >
              {canCancel && (
                <span className="mt-0 flex w-auto sm:w-full">
                  <Button
                    disabled={loading}
                    onClick={handleCancel}
                    type="text"
                    className="w-full hover:bg-gray-200"
                    data-testid="modal-cancel"
                    variant="secondary"
                  >
                    {cancelText}
                  </Button>
                </span>
              )}
              {additionalButtons}
              {canConfirm && (
                <span className="flex shadow-sm w-auto sm:w-full">
                  <Button
                    disabled={confirmDisabled || loading}
                    variant={variant}
                    className="border border-transparent sm:text-base sm:leading-6 font-medium shadow-sm text-sm leading-5 w-full"
                    data-testid="modal-confirm"
                    onClick={handleConfirm}
                  >
                    {!loading ? (
                      confirmText
                    ) : (
                      <Loader className="mx-auto" size="sm" />
                    )}
                  </Button>
                </span>
              )}
            </div>
          )}
        </TailwindThemeProvider>
      </BaseModal>
    );
  },
);

Modal.defaultProps = {
  confirmDisabled: false,
  closeOnOutsideClick: true,
  contextual: false,
  cancelText: 'Cancel',
  confirmText: 'Okay',
  loading: false,
  open: true,
  canCancel: true,
  canConfirm: true,
  size: MD,
  variant: PRIMARY,
  rootSelector: '#root',
};

export default withTheme(Modal);

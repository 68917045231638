import React from 'react';
import classNames from 'classnames';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { WHITE_LABEL } from '../constants/features';
import { getText } from '../utils/lang';
import NolocoWordmark from './NolocoWordmark';

type PoweredByNolocoProps = {
  className?: string;
  projectName?: string;
  utmSource: string;
  isMobileTopNav?: boolean;
};

const PoweredByNoloco = ({
  className,
  projectName,
  utmSource,
  isMobileTopNav = false,
}: PoweredByNolocoProps) => {
  const canWhiteLabel = useIsFeatureEnabled(WHITE_LABEL, { blockTrial: true });

  if (canWhiteLabel) {
    return null;
  }

  return (
    <a
      className={classNames('flex items-center overflow-hidden', className, {
        'w-full': !isMobileTopNav,
        'ml-auto': isMobileTopNav,
      })}
      href={`https://noloco.io?utm_source=${utmSource}&utm_medium=powered&utm_content=${projectName}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        position: 'relative',
        visibility: 'visible',
        opacity: '100',
        zIndex: 20,
      }}
    >
      <span className="font-medium tracking-wider truncate">
        {getText('leftSidebar.powered')}
      </span>
      <NolocoWordmark className="h-4 ml-2 mb-1" />
    </a>
  );
};

export default PoweredByNoloco;

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import useRefCallback from '../../utils/hooks/useRefCallback';
import { getInputStyles } from './TextInput';

const BUFFER_MARGIN = 8;
const TEXT_AREA_BUFFER_MARGIN = 24;

const NBSP = '\u00A0';

type Props = {
  minWidth?: number;
  onChange?: (...args: any[]) => any;
  onFocusChange?: (...args: any[]) => any;
  value: number | string;
  placeholder: number | string | React.ReactNode;
  noWrap?: boolean;
  backgroundColorOnFocus?: string;
};

const AutoSizedTextInput = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'autoFocus' does not exist on type 'Props... Remove this comment to see the full error message
      autoFocus,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      minWidth,
      onChange,
      onFocusChange,
      placeholder,
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'Props'.
      type,
      value,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'onBlur' does not exist on type 'Props'.
      onBlur,
      // @ts-expect-error TS(2339): Property 'onFocus' does not exist on type 'Props'.
      onFocus,
      noWrap = false,
      backgroundColorOnFocus,
      ...rest
    },
    ref,
  ) => {
    const [localValue, setLocalValue] = useState(value || placeholder);
    const [isFocused, setIsFocused] = useState(false);
    const [textWidth, setTextWidth] = useState(0);
    const inputRef = useRef();
    const hiddenTextWidthRef = useRef();

    useEffect(() => {
      if (autoFocus && inputRef.current) {
        (inputRef.current as any).el.current.focus();
      }
    }, [autoFocus]);

    const handleFocusChange = useCallback(
      (newFocus: any) => {
        setIsFocused(newFocus);
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onFocusChange(newFocus);
      },
      [onFocusChange],
    );

    const handleClick = (event: any) => {
      event.stopPropagation();
      event.preventDefault();
    };

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const inputStyleProps = useMemo(
      () =>
        getInputStyles({
          theme,
          ...rest,
        }),
      [theme, rest],
    );

    const formatEvent = useCallback(
      (event: any) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        event.target.value = inputRef.current.el.current.innerText;
        if (type !== 'textarea') {
          event.target.value = event.target.value.replace(/\n/g, '');
        }
      },
      [type],
    );

    const handleOnChange = useCallback(
      (event: any) => {
        formatEvent(event);
        if (onChange) {
          onChange(event);
        }
        setLocalValue(event.target.value);
      },
      [formatEvent, onChange],
    );

    const handleKeyDown = useRefCallback(
      (event: any) => {
        if (
          event.code === 'Enter' &&
          (!event.shiftKey || type !== 'textarea')
        ) {
          event.preventDefault();

          if (!event.shiftKey) {
            const target = event.target;
            if (target) {
              target.blur();
            }
          }
        }
      },
      [type],
    );

    const updateWidth = useCallback(() => {
      if (hiddenTextWidthRef.current) {
        const newWidth = (hiddenTextWidthRef.current as any).offsetWidth;
        if (type === 'textarea') {
          setTextWidth(TEXT_AREA_BUFFER_MARGIN + newWidth);
          // setTextHeight(newHeight);
        } else {
          setTextWidth(BUFFER_MARGIN + newWidth);
        }
      }
    }, [type]);

    const handleOnBlur = useCallback(
      (event: any) => {
        formatEvent(event);
        if (onBlur) {
          onBlur(event);
        }
        handleFocusChange(false);
      },
      [formatEvent, handleFocusChange, onBlur],
    );

    const handleOnFocus = useCallback(
      (event: any) => {
        formatEvent(event);
        if (onFocus) {
          onFocus(event);
        }
        handleFocusChange(true);
      },
      [formatEvent, handleFocusChange, onFocus],
    );

    useLayoutEffect(() => {
      updateWidth();
    }, [updateWidth]);

    useLayoutEffect(() => {
      updateWidth();
    }, [localValue, value, placeholder, updateWidth]);

    const styleProps = !isFocused
      ? {
          border: 'transparent',
          bg: 'transparent',
        }
      : backgroundColorOnFocus
      ? {
          bg: backgroundColorOnFocus,
          border: backgroundColorOnFocus,
        }
      : {};

    return (
      <div
        className={classNames(
          'cursor-text max-w-full overflow-hidden mr-auto',
          { 'm-px': !isFocused && false },
          className,
        )}
        ref={ref}
        style={{
          // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
          width: textWidth === 0 ? 10000 : Math.max(textWidth, minWidth),
        }}
      >
        <Box
          is={ContentEditable}
          className={classNames('resize-none overflow-hidden', {
            'whitespace-pre-wrap': !noWrap,
            'whitespace-no-wrap px-2 h-6 flex items-center': noWrap,
          })}
          m={{ b: 0 }}
          p={{ x: 0, y: 0 }}
          h={type === 'textarea' ? 'full' : undefined}
          rounded={true}
          {...inputStyleProps}
          {...styleProps}
          onChange={handleOnChange}
          ref={inputRef}
          onClick={handleClick}
          placeholder={placeholder || NBSP}
          onKeyDown={handleKeyDown}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          html={String(value || '')}
          {...rest}
        />
        <span
          // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
          ref={hiddenTextWidthRef}
          className="flex flex-col p-1 overflow-hidden absolute invisible top-0 left-0 whitespace-pre-wrap"
        >
          {localValue || placeholder || NBSP}
        </span>
      </div>
    );
  },
);

AutoSizedTextInput.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ minHeight: number; minWidth: number; onFoc... Remove this comment to see the full error message
  minHeight: 20,
  minWidth: 100,
  onFocusChange: () => null,
};

export default withTheme(AutoSizedTextInput);

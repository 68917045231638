import React, { memo } from 'react';
import { IconCheck, IconComet } from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge } from '../badge';

const BlockRadioGroupOption = memo(
  ({
    // @ts-expect-error TS(2339): Property 'active' does not exist on type '{}'.
    active,
    // @ts-expect-error TS(2339): Property 'badgeText' does not exist on type '{}'.
    badgeText,
    // @ts-expect-error TS(2339): Property 'checked' does not exist on type '{}'.
    checked,
    children,
    // @ts-expect-error TS(2339): Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
    description,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{}'.
    disabled,
    // @ts-expect-error TS(2339): Property 'Icon' does not exist on type '{}'.
    Icon,
    // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
    onClick,
    // @ts-expect-error TS(2339): Property 'showBadge' does not exist on type '{}'.
    showBadge = false,
  }) => (
    <div
      className={classNames(
        'relative rounded-lg shadow-md cursor-pointer flex focus:outline-none mb-2 bg-slate-700 hover:bg-slate-600 px-2 py-1',
        {
          'ring-1 ring-offset-0 ring-offset-light-blue-300 ring-white ring-opacity-50': active,
          'border-white': checked,
          'border-brand-light': !checked,
          'bg-opacity-10': disabled,
          'hover:border-gray-200': !disabled,
        },
      )}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }

        if (onClick && !disabled) {
          onClick(e);
        }
      }}
    >
      <div className="flex items-center justify-between w-full space-x-1 text-white">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center">
            {Icon && <Icon size={16} className="mr-2" />}
            <p
              className={classNames(
                'font-medium text-sm',
                checked
                  ? 'text-white'
                  : disabled
                  ? 'text-gray-600'
                  : 'text-gray-200',
              )}
            >
              {children}
            </p>
          </div>
          {description && (
            <span
              className={classNames(
                'inline text-xs mt-1',
                checked ? 'text-gray-100' : 'text-gray-300',
              )}
            >
              {description}
            </span>
          )}
        </div>
        {showBadge && badgeText && (
          <Badge
            color="pink-500"
            className="z-10 flex items-center flex-grow-0 opacity-100 ml-auto"
          >
            <IconComet size={14} />
            <span>{badgeText}</span>
          </Badge>
        )}
        {checked && (
          <div className="p-1 bg-gray-200 rounded-full text-brand">
            <IconCheck className="w-3 h-3" />
          </div>
        )}
      </div>
    </div>
  ),
);

export default BlockRadioGroupOption;

import { FILE } from '../constants/builtInDataTypes';
import { DataSourceType } from '../constants/dataSources';
import { DataFieldType } from '../constants/dataTypes';
import { FieldFormat } from '../constants/fieldFormats';
import { Relationship } from '../constants/relationships';
import {
  getBaseFieldReverseName,
  getFieldKey,
  getFieldReverseMutationInputName,
  getFieldReverseName,
} from '../utils/fields';
import { isFormulaField } from '../utils/formulas/isFormula';
import { isOptionType } from '../utils/options';
import { JsonMaps } from './BaseArrayTypeMap';
import DataFieldOptions from './DataFieldOptions';
import { Lookup } from './Lookup';
import ProjectArrayTypeMap, {
  DataFieldIdentifier,
  DataTypeIdentifier,
  DataTypeName,
} from './ProjectArrayTypeMap';
import { Rollup } from './Rollup';

export type SubField = {
  options?: BaseDataFieldOption[];
  type: DataFieldType;
  typeOptions?: {
    format: FieldFormat;
  };
};

export type BaseDataFieldOption = {
  display: string;
};

export type DataFieldOption = BaseDataFieldOption & {
  color: string;
  id: number;
  name: string;
  order: number;
};

export interface BaseDataField extends DataFieldIdentifier {
  display: string;
  type: string;
  reverseName?: string | undefined | null;
  reverseDisplayName?: string | undefined | null;
  relatedField?: BaseDataField;
  relationship?: string | null;
  fieldKey?: string;
  isReverse?: boolean;
}

export type FieldTypeOptions = {
  max?: number;
  format?: FieldFormat;
  precision?: number;
  symbol?: string;
  timeZone?: string;
  formula?: string;
  allowNegative?: boolean;
  min?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  subFields?: Record<string, SubField>;
  time?: boolean;
};

export interface DataField extends BaseDataField {
  hidden?: boolean;
  internal?: boolean;
  lookup?: Lookup;
  multiple?: boolean;
  // on the frontend we can pretty much guarantee it's the mapped version
  // but this is used so much on the backend that updating the type fully was getting tricky
  options?: DataFieldOption[] | DataFieldOptions;
  order?: number | null;
  readOnly?: boolean;
  relatedField?: DataField;
  relationship?: Relationship | null;
  required?: boolean;
  rollup?: Rollup;
  source?: DataSourceType;
  type: DataFieldType | string;
  typeOptions?: FieldTypeOptions;
  unique?: boolean;
}

export class DataFieldArray<
  T extends BaseDataField & { relatedField?: T }
> extends ProjectArrayTypeMap<T> {
  mutationNameMap: Record<string, string>;
  dataQueryNameMap: Record<string, string>;
  whereFilterNameMap: Record<string, string>;
  linkedFieldNames: string[];
  reverseRelatedFieldNames: string[];
  reverseRelatedApiFieldNames: string[];
  formulaFields: number[];
  optionFields: number[];
  dataTypeNameToRelatedDataTypesMap: Record<string, string[]>;

  constructor(arrayOrLength?: T[] | number | DataFieldArray<T>) {
    super(arrayOrLength);

    if (arrayOrLength instanceof DataFieldArray) {
      this.mutationNameMap = arrayOrLength.mutationNameMap || {};
      this.dataQueryNameMap = arrayOrLength.dataQueryNameMap || {};
      this.whereFilterNameMap = arrayOrLength.whereFilterNameMap || {};
      this.dataTypeNameToRelatedDataTypesMap =
        arrayOrLength.dataTypeNameToRelatedDataTypesMap || {};
      this.linkedFieldNames = arrayOrLength.linkedFieldNames || [];
      this.reverseRelatedFieldNames =
        arrayOrLength.reverseRelatedFieldNames || [];
      this.reverseRelatedApiFieldNames =
        arrayOrLength.reverseRelatedApiFieldNames || [];
      this.formulaFields = arrayOrLength.formulaFields || [];
      this.optionFields = arrayOrLength.optionFields || [];
    } else {
      this.mutationNameMap = {};
      this.dataQueryNameMap = {};
      this.whereFilterNameMap = {};
      this.dataTypeNameToRelatedDataTypesMap = {};
      this.linkedFieldNames = [];
      this.reverseRelatedFieldNames = [];
      this.reverseRelatedApiFieldNames = [];
      this.formulaFields = [];
      this.optionFields = [];
    }

    this.maps.mutationNameMap = this.mutationNameMap;
    this.maps.dataQueryNameMap = this.dataQueryNameMap;
    this.maps.whereFilterNameMap = this.whereFilterNameMap;
    this.maps.linkedFieldNames = this.linkedFieldNames;
    this.maps.reverseRelatedFieldNames = this.reverseRelatedFieldNames;
    this.maps.reverseRelatedApiFieldNames = this.reverseRelatedApiFieldNames;
    this.maps.formulaFields = this.formulaFields;
    this.maps.optionFields = this.optionFields;
    this.maps.dataTypeNameToRelatedDataTypesMap = this.dataTypeNameToRelatedDataTypesMap;
  }

  static fromJSON<
    T extends BaseDataField & { options?: DataFieldOptions; relatedField?: T }
  >(
    json: JsonMaps<
      T,
      {
        mutationNameMap: Record<string, string>;
        dataQueryNameMap: Record<string, string>;
        whereFilterNameMap: Record<string, string>;
        linkedFieldNames: string[];
        reverseRelatedFieldNames: string[];
        formulaFields: number[];
        optionFields: number[];
        dataTypeNameToRelatedDataTypesMap: Record<string, string[]>;
      }
    >,
  ): DataFieldArray<T> {
    const jsonMap = json;

    const selfReferencingFieldsToAdd: T[] = [];

    Object.keys(json.idMap).forEach((fieldKey) => {
      const fieldId = Number(fieldKey);
      const field = jsonMap.idMap[fieldId];

      if (field.options && !Array.isArray(field.options)) {
        field.options = DataFieldOptions.fromJSON(field.options);
      }

      // TODO: @darraghmckay - remove this when reverse related fields have their own ID
      // We need to do this because when we deserialize there is only one entry for fields with self reverse relationships
      if (field.relatedField && field.type === field.relatedField.type) {
        selfReferencingFieldsToAdd.push(field);
      }

      jsonMap.idMap[fieldId] = field;
    });

    const result = DataFieldArray._fromJSON(jsonMap) as DataFieldArray<T>;

    // TODO: @darraghmckay - remove this when reverse related fields have their own ID
    selfReferencingFieldsToAdd.forEach((field) => {
      if (result.idMap) {
        result.push(field.relatedField as T);
        result.idMap[field.id] = field;
      }
    });

    return result;
  }

  static formatField<F extends BaseDataField>(dataField: F) {
    if (isOptionType(dataField.type) && (dataField as DataField).options) {
      return {
        ...dataField,
        options: new DataFieldOptions((dataField as DataField).options),
      };
    }

    return dataField;
  }

  private generateReverseField = (type: DataTypeName, relatedField: T): T => {
    const fieldKey = getFieldReverseName(relatedField, {
      apiName: type,
      id: -1,
      name: type,
    });

    return {
      ...relatedField,
      fieldKey,
      isReverse: true,
    };
  };

  _mapEntry(field: T) {
    let newField = { ...field };
    if (isOptionType(field.type) && (field as DataField).options) {
      (newField as DataField).options = new DataFieldOptions(
        (field as DataField).options,
      );
    }

    super._mapEntry(newField);

    if (!this.dataQueryNameMap) {
      return;
    }

    if (newField.relatedField || newField.relationship) {
      this.linkedFieldNames.push(newField.name);
    }

    if (newField.relatedField) {
      const type = newField.type;
      const fieldKey = getFieldReverseName(newField.relatedField, {
        apiName: type,
        id: -1,
        name: type,
      });

      this.reverseRelatedFieldNames.push(newField.name);
      this.reverseRelatedApiFieldNames.push(newField.apiName);

      if (fieldKey) {
        this.dataQueryNameMap[fieldKey] = newField.name;
      }

      const mockRelatedDataType = {
        name: newField.type,
        apiName: newField.type,
      } as DataTypeIdentifier;

      const whereFieldKey = getBaseFieldReverseName(
        newField.relatedField as DataField,
        mockRelatedDataType,
      );
      const idKey = getFieldKey(newField as DataField);
      this.whereFilterNameMap[whereFieldKey] = newField.name;
      this.whereFilterNameMap[idKey] = newField.name;

      const reverseFieldMutationInputName = getFieldReverseMutationInputName(
        newField.relatedField,
        mockRelatedDataType,
      );

      this.mutationNameMap[reverseFieldMutationInputName] = newField.name;
    } else {
      const dataQueryName = `${newField.apiName}${
        newField.relationship ? 'Id' : ''
      }`;

      this.mutationNameMap[dataQueryName] = newField.name;
      this.dataQueryNameMap[dataQueryName] = newField.name;
      this.whereFilterNameMap[dataQueryName] = newField.name;
      if (newField.relationship) {
        this.dataQueryNameMap[newField.apiName] = newField.name;
        this.whereFilterNameMap[newField.apiName] = newField.name;

        if (newField.type === FILE) {
          this.mutationNameMap[newField.apiName] = newField.name;
        }
      } else if (isFormulaField(newField)) {
        this.formulaFields.push(newField.id);
      } else if (isOptionType(newField.type)) {
        this.optionFields.push(newField.id);
      }
    }

    if (newField.relationship && newField.type !== FILE) {
      const relatedDataTypesMapUninitialized =
        this.dataTypeNameToRelatedDataTypesMap[newField.type] === undefined;

      if (relatedDataTypesMapUninitialized) {
        this.dataTypeNameToRelatedDataTypesMap[newField.type] = [];
      }

      if (
        !this.dataTypeNameToRelatedDataTypesMap[newField.type].includes(
          newField.name,
        )
      ) {
        this.dataTypeNameToRelatedDataTypesMap[newField.type].push(
          newField.name,
        );
      }
    }
  }

  // Need to check if it's a reverse related name and check if we get the right field
  getByName(name: string): T | undefined {
    if (!this.idMap) {
      this._mapEntries();
    }

    const field = this.getByIdOrUndefined(this.nameMap[name]);

    if (
      field &&
      this.reverseRelatedFieldNames.includes(name) &&
      !field.relatedField
    ) {
      // search for the field;
      // this undoes some of the optimization work, but it's the only thing I can think of
      return this.find((f) => f.name === name && f.relatedField);
    } else if (
      field &&
      field.relatedField &&
      !this.reverseRelatedFieldNames.includes(name)
    ) {
      // search for the field;
      // this undoes some of the optimization work, but it's the only thing I can think of
      return this.find((f) => f.name === name && !f.relatedField);
    }

    return field;
  }

  // Need to check if it's a reverse related apiName and check if we get the right field
  getByApiName(apiName: string): T | undefined {
    if (!this.idMap) {
      this._mapEntries();
    }

    const field = this.getByIdOrUndefined(this.apiNameMap[apiName]);

    if (
      field &&
      this.reverseRelatedApiFieldNames.includes(apiName) &&
      !field.relatedField
    ) {
      // search for the field;
      // this undoes some of the optimization work, but it's the only thing I can think of
      return this.find((f) => f.apiName === apiName && f.relatedField);
    } else if (
      field &&
      field.relatedField &&
      !this.reverseRelatedApiFieldNames.includes(apiName)
    ) {
      // search for the field;
      // this undoes some of the optimization work, but it's the only thing I can think of
      return this.find((f) => f.apiName === apiName && !f.relatedField);
    }

    return field;
  }

  getByNames(names: string[]): T[] {
    return names.map((name) => this.getByName(name)).filter(Boolean) as T[];
  }

  getByMutationName(mutationArgument: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByName(this.mutationNameMap[mutationArgument]);
  }

  getByDataQueryName(fieldName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    const field = this.getByName(this.dataQueryNameMap[fieldName]);

    if (!field) {
      return undefined;
    }

    if (field.relatedField) {
      return this.generateReverseField(field.type, field.relatedField);
    }

    return field;
  }

  getByWhereFilterName(fieldName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByName(this.whereFilterNameMap[fieldName]);
  }

  getFieldsByRelatedDataTypeName(relatedDTName: string): T[] {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByNames(
      this.dataTypeNameToRelatedDataTypesMap[relatedDTName] || [],
    );
  }

  getFieldsWithRelations() {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByNames(this.linkedFieldNames);
  }

  getFormulaFields() {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIds(this.formulaFields);
  }

  getOptionFields() {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIds(this.optionFields);
  }
}

class DataTypeFields extends DataFieldArray<DataField> {}

export default DataTypeFields;

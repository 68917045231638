import React, { useCallback, useMemo, useState } from 'react';
import { Box, getTailwindClassNames } from '@darraghmckay/tailwind-react-ui';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import useInfiniteScroll from 'react-infinite-scroll-hook';
// eslint-disable-next-line no-restricted-imports
import { getText } from '@noloco/core/src/utils/lang';
import { DARK, LIGHT } from '../../constants/surface';
import TextInput from '../input/TextInput';
import { Loader } from '../loading';
import BasePopover from '../popover/BasePopover';
import Option from './Option';
import OptionGroup from './OptionGroup';
import styleForSizes from './optionStyles';

const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) =>
  event.stopPropagation();

const ListOption = ({
  children,
  disabled,
  onChange,
  multiple,
  currentValue,
  value,
  onOptionClick,
  ...rest
}: any) => (
  <li
    onClick={(e) => {
      e.stopPropagation();
      if (onChange && !disabled) {
        onChange(value);
      }
      if (onOptionClick && !disabled) {
        onOptionClick(value);
      }
    }}
    {...rest}
  >
    {children({
      active: multiple
        ? currentValue && currentValue.includes(value)
        : value === currentValue,
      selected: multiple
        ? currentValue && currentValue.includes(value)
        : value === currentValue,
    })}
  </li>
);

const FlatOptionListItem = ({
  coloredOptionType,
  currentValue,
  depth,
  direction,
  filterOptions,
  filterValue,
  index,
  isOpen,
  minW,
  multiple,
  onChange,
  option,
  rootEl,
  setOpenOption,
  size,
  styles,
  surface,
}: any) => {
  const hasChildOptions = useMemo(
    () => option.options && Array.isArray(option.options),
    [option.options],
  );
  const hasChildOptionGenerator = useMemo(() => option.getOptions, [
    option.getOptions,
  ]);

  const ListOptionComponent = useMemo(
    () =>
      (hasChildOptions || hasChildOptionGenerator) && !option.heading
        ? OptionGroup
        : ListOption,
    [hasChildOptions, hasChildOptionGenerator, option.heading],
  );

  const childOptions = useMemo(() => {
    let unfilteredChildOptions = [];

    if (hasChildOptions) {
      unfilteredChildOptions = option.options;
    }

    if (hasChildOptionGenerator && isOpen) {
      unfilteredChildOptions = option.getOptions();
    }

    return unfilteredChildOptions.filter(filterOptions(filterValue));
  }, [
    filterOptions,
    filterValue,
    hasChildOptionGenerator,
    hasChildOptions,
    isOpen,
    option,
  ]);
  const hasValidChildOptions = useMemo(() => childOptions.length > 0, [
    childOptions.length,
  ]);

  const optionClassName = useMemo(
    () => getTailwindClassNames(styleForSizes(size)),
    [size],
  );

  const onOptionClick = useMemo(() => {
    if (hasChildOptions && childOptions.length === 0) {
      return undefined;
    }

    return () => setOpenOption(isOpen ? null : index);
  }, [childOptions.length, hasChildOptions, index, isOpen, setOpenOption]);

  return (
    <>
      <ListOptionComponent
        className={classNames({
          'hover:bg-blue-300 hover:bg-opacity-75 rounded-lg': !option.heading,
          'hover:text-blue-800': !option.heading && !coloredOptionType,
        })}
        value={option.value}
        multiple={multiple}
        onOptionClick={onOptionClick}
        disabled={option.disabled || option.heading}
        size={size}
        currentValue={!option.heading ? currentValue : null}
        onChange={onChange}
      >
        {({ selected, active }: any) => {
          const listOption = (
            <Option
              // @ts-expect-error TS(2322): Type '{ active: any; className: any; hasChildOptio... Remove this comment to see the full error message
              active={active}
              className={optionClassName}
              data-testid="option-entry"
              hasChildOptions={hasValidChildOptions || hasChildOptionGenerator}
              size={size}
              selected={selected}
              option={option}
              minW={minW}
              coloredOptionType={coloredOptionType}
            />
          );

          if (hasValidChildOptions && !option.heading) {
            return (
              <OptionsList
                currentValue={currentValue}
                depth={depth + 1}
                direction={direction}
                filterValue={filterValue}
                options={childOptions}
                open={isOpen}
                onChange={onChange}
                rootEl={rootEl}
                size={size}
                styles={styles}
                surface={surface}
                minW={minW}
              >
                {listOption}
              </OptionsList>
            );
          }

          return listOption;
        }}
      </ListOptionComponent>
      {hasValidChildOptions && option.heading && (
        <FlatOptionList
          currentValue={currentValue}
          depth={depth + 1}
          direction={direction}
          filterValue={filterValue}
          options={childOptions}
          onChange={onChange}
          rootEl={rootEl}
          size={size}
          styles={styles}
          surface={surface}
          minW={minW}
        />
      )}
    </>
  );
};

const FlatOptionList = ({
  currentValue,
  depth = 0,
  direction,
  filterValue,
  styles,
  options,
  multiple,
  onChange,
  rootEl,
  size,
  minW,
  coloredOptionType = false,
  surface,
}: any) => {
  const [openOption, setOpenOption] = useState(null);

  return options.map((option: any, index: any) => (
    <FlatOptionListItem
      coloredOptionType={coloredOptionType}
      currentValue={currentValue}
      depth={depth}
      direction={direction}
      filterOptions={filterOptions}
      filterValue={filterValue}
      index={index}
      isOpen={openOption === index}
      key={`${index}-${option.plainLabel ?? option.label ?? option.value}`}
      minW={minW}
      multiple={multiple}
      onChange={onChange}
      option={option}
      rootEl={rootEl}
      setOpenOption={setOpenOption}
      size={size}
      styles={styles}
      surface={surface}
    />
  ));
};

const matchesFilterValue = (formattedFilterValue: any, value: any) =>
  String(value).toLowerCase().includes(formattedFilterValue);

const searchableTypes = ['string', 'number'];
const isSearchable = (value: any) => searchableTypes.includes(typeof value);

export const filterOptions = (filterValue: any) => (option: any) => {
  if (!filterValue || !filterValue.trim()) {
    return true;
  }

  if (option.options) {
    return option.options.some((subOption: any) =>
      filterOptions(filterValue)(subOption),
    );
  }

  const formattedFilterValue = filterValue.trim().toLowerCase();

  if (option.label && isSearchable(option.label)) {
    if (matchesFilterValue(formattedFilterValue, option.label)) {
      return true;
    }
  }

  if (option.plainLabel && isSearchable(option.plainLabel)) {
    if (matchesFilterValue(formattedFilterValue, option.plainLabel)) {
      return true;
    }
  }

  if (option.value && isSearchable(option.value)) {
    if (matchesFilterValue(formattedFilterValue, option.value)) {
      return true;
    }
  }

  if (option.objectValue && typeof option.objectValue === 'object') {
    const isObjectMatch = Object.values(option.objectValue)
      .filter((objectKeyVal: any) => isSearchable(objectKeyVal))
      .some((objectKeyVal: any) =>
        matchesFilterValue(formattedFilterValue, objectKeyVal),
      );

    if (isObjectMatch) {
      return true;
    }
  }

  return false;
};

const OptionsList = ({
  children,
  currentValue,
  'data-testid': dataTestId,
  offset = [0, 2],
  depth = 0,
  footer,
  styles,
  listIs = 'ul',
  hasMore,
  loading,
  direction,
  filterValue,
  onFetchMore,
  onSearchChange,
  listStyle,
  placement,
  searchable,
  multiple,
  onChange,
  open,
  rootEl,
  hideOptions = false,
  trigger = 'none',
  options,
  size,
  minW,
  style,
  surface,
  showEmptyState = true,
  usePortal,
  coloredOptionType,
  shiftRight = false,
}: any) => {
  const [localFilterValue, setLocalFilterValue] = useState('');

  const infiniteScrollEnabled =
    searchable && hasMore && depth === 0 && onFetchMore;
  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: () => {
      if (onFetchMore) {
        onFetchMore();
      }
    },
    disabled: !infiniteScrollEnabled,
    rootMargin: '0px 0px 100px 0px',
  });

  const handleOnChange = useCallback(
    (nextValue: any) => {
      setLocalFilterValue('');

      if (onChange) {
        onChange(nextValue);
      }
    },
    [onChange],
  );

  const filteredOptions = useMemo(
    () =>
      options &&
      options
        .filter(filterOptions(filterValue || localFilterValue))
        .filter(
          (option: any) =>
            !multiple ||
            isNil(currentValue) ||
            !currentValue.includes(option.value),
        ),
    [currentValue, filterValue, localFilterValue, multiple, options],
  );

  const onFilterKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        const firstOption = filteredOptions.find(
          (option: any) => option.value !== undefined,
        );
        if (firstOption) {
          onChange(firstOption.value);
          setLocalFilterValue('');
        }
      }
    },
    [filteredOptions, onChange],
  );

  return (
    // @ts-expect-error TS(2322): Type '{ children: any; delayHide: number; showArro... Remove this comment to see the full error message
    <BasePopover
      delayHide={400}
      showArrow={false}
      placement={depth === 0 ? placement : direction || 'right-start'}
      isOpen={open}
      closeOnOutsideClick={true}
      rootEl={rootEl}
      usePortal={usePortal}
      offset={offset}
      trigger={trigger}
      style={style}
      content={
        <Transition
          show={filteredOptions.length > 0 || loading || showEmptyState}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={classNames('mt-1 shadow-lg max-w-xl sm:max-w-full', {
            '-mr-12': searchable && !shiftRight,
          })}
          onClick={stopPropagation}
        >
          <Box
            is="div"
            static
            className={classNames(
              'justify-start text-sm leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5',
              { 'z-20': depth === 0 },
            )}
            data-testid={dataTestId ?? `select-options-depth-${depth}`}
            {...styles}
            block={true}
            p={0}
            minW={minW}
          >
            {searchable && (
              <div className="flex items-center border-b">
                <TextInput
                  autoFocus={true}
                  placeholder="Search..."
                  onKeyDown={onFilterKeyDown}
                  onChange={({ target: { value } }: any) => {
                    setLocalFilterValue(value);
                    if (onSearchChange) {
                      onSearchChange(value);
                    }
                  }}
                  m={{ x: 2, b: 2, t: 2 }}
                  p={{ l: 2, r: 4, y: 1.5 }}
                  style={listStyle}
                  border={['t-none', 'r-none', 'l-none']}
                  surface={surface}
                  clearable={searchable}
                  value={localFilterValue}
                />
                {loading && <Loader size="sm" className="ml-2 mr-4" />}
              </div>
            )}
            {!hideOptions && (
              <Box
                is={listIs}
                className={classNames(
                  'max-h-64 p-1 space-y-0.5 overflow-y-auto overflow-x-hidden focus:outline-none',
                  {
                    'pt-4': searchable,
                    'pb-4': footer,
                    'text-gray-600': surface === LIGHT,
                    'text-gray-200': surface === DARK,
                  },
                )}
              >
                {filteredOptions.length === 0 && !loading && (
                  // @ts-expect-error TS(2322): Type '{ children: Element; className: string; size... Remove this comment to see the full error message
                  <li className="px-4 py-2" size={size}>
                    <span>{getText('contentEditor.options.noOptions')}</span>
                  </li>
                )}
                <FlatOptionList
                  rootEl={rootEl}
                  currentValue={currentValue}
                  filterValue={filterValue || localFilterValue}
                  options={filteredOptions}
                  size={size}
                  styles={styles}
                  multiple={multiple}
                  onChange={handleOnChange}
                  direction={direction}
                  depth={depth}
                  minW={minW}
                  coloredOptionType={coloredOptionType}
                  surface={surface}
                />
                {infiniteScrollEnabled && hasMore && !loading && (
                  <div
                    className="w-full py-4 flex justify-center"
                    ref={loaderRef}
                  >
                    <Loader size="sm" />
                  </div>
                )}
              </Box>
            )}
            {footer && (
              <div
                className={classNames('px-6 py-3 group', {
                  'border-t': !hideOptions,
                })}
                // @ts-expect-error TS(2322): Type '{ children: any; className: string; size: an... Remove this comment to see the full error message
                size={size}
              >
                {footer}
              </div>
            )}
          </Box>
        </Transition>
      }
    >
      {children}
    </BasePopover>
  );
};

OptionsList.defaultProps = {
  usePortal: true,
};

export default OptionsList;

import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Loader } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import { DETAILS } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import { Action } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getPageQueryString } from '../../../queries/project';
import { BLANK_QUERY_STRING } from '../../../queries/project';
import { userSelector } from '../../../selectors/userSelectors';
import {
  findDependentValues,
  transformDepsToQueryObject,
} from '../../../utils/data';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { RECORD_SCOPE } from '../../../utils/scope';
import { getDepsFromSection } from '../../../utils/sectionDependencies';
import RecordDetails from './RecordDetails';

type ViewActionRecordProps = {
  action: Action;
  dataType: DataType;
  project: Project;
  record: Record<string, any>;
  recordScope: Record<string, any>;
  size: string;
};

const ViewActionRecord = ({
  action,
  dataType,
  project,
  record,
  recordScope,
  size,
}: ViewActionRecordProps) => {
  const user = useSelector(userSelector);
  const [isDarkModeEnabled] = useDarkMode();

  const recordUuid = useMemo(() => {
    if (action && action.field) {
      return get(record, [action.field, 'uuid'], null);
    }
    return get(record, 'uuid', null);
  }, [action, record]);

  const dependencies = useMemo(() => {
    const section = {
      id: action.id,
      type: DETAILS,
      props: {
        fields: action.detailsFields,
        rootField: dataType.apiName,
      },
    };

    return [
      ...findDependentValues(RECORD_SCOPE, section, project.dataTypes),
      ...getDepsFromSection(
        section,
        dataType,
        project.dataTypes,
        user,
        action.id,
        true,
      ),
    ];
  }, [dataType, action, project.dataTypes, user]);

  const gqlQueryString = useMemo(
    () =>
      getPageQueryString(
        dataType.apiName,
        { uuid: recordUuid },
        transformDepsToQueryObject(dataType, project.dataTypes, dependencies),
      ),
    [dataType, dependencies, project.dataTypes, recordUuid],
  );

  const variables = useMemo(
    () => ({
      uuid: recordUuid,
    }),
    [recordUuid],
  );

  const { data, loading: queryLoading, error } = useQuery(
    gql`
      ${gqlQueryString || BLANK_QUERY_STRING}
    `,
    {
      variables,
      errorPolicy: 'all',
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );

  if (error) {
    console.error('Error loading record for form:', error);
  }

  return (
    <>
      {queryLoading && (
        <div className="w-full h-96 sm:min-h-screen flex items-center justify-center">
          <Loader
            className={
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-800'
            }
            size={size}
          />
        </div>
      )}
      {!queryLoading && (
        <RecordDetails
          dataType={dataType}
          project={project}
          record={get(data, dataType.apiName, {})}
          recordScope={recordScope}
          fields={action.detailsFields}
          isModal={true}
        />
      )}
    </>
  );
};

export default ViewActionRecord;

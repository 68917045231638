import React from 'react';

const OnboardingProgressBar = ({
  value,
  max,
}: {
  value: number;
  max: number;
}) => (
  <div className="w-full fixed top-0 left-0 bg-pink-200">
    <div
      className="w-full h-1.5 bg-pink-500 rounded-tr-lg rounded-br-lg duration-200 transition-all"
      style={{
        width: `${(value / max) * 100}%`,
      }}
    />
  </div>
);

export default OnboardingProgressBar;

import React, { useState } from 'react';
import {
  Template,
  TemplateProject as TemplateProjectModel,
} from '@noloco/core/src/models/Template';
import { getText } from '@noloco/core/src/utils/lang';
import CloneTemplateModal from './CloneTemplateModal';
import TemplateProject from './TemplateProject';

const TemplateList = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [
    selectedTemplateProject,
    setSelectedTemplateProject,
  ] = useState<TemplateProjectModel | null>(null);

  return (
    <div className="flex flex-col mt-8">
      <h1 className="text-2xl font-medium mb-1">
        {getText('templateList.title')}
      </h1>
      <p className="text-sm  mb-4 tex-gray-700">
        {getText('templateList.subtitle')}
      </p>
      <TemplateProject
        selectTemplate={(template, templateProject) => {
          setSelectedTemplate(template);
          setSelectedTemplateProject(templateProject);
        }}
      />
      {selectedTemplate && selectedTemplateProject && (
        <CloneTemplateModal
          onClose={() => {
            setSelectedTemplate(null);
            setSelectedTemplateProject(null);
          }}
          setSelectedTemplate={setSelectedTemplate}
          setSelectedTemplateProject={setSelectedTemplateProject}
          template={selectedTemplate}
          templateProject={selectedTemplateProject}
        />
      )}
    </div>
  );
};

export default TemplateList;

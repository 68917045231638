import React, { memo } from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components';
import {
  PlanInterval,
  YEAR,
} from '@noloco/core/src/constants/accountPlanIntervals';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  children?: React.ReactNode;
  cost: number;
  costTooltip?: string;
  currencyFormatter: Intl.NumberFormat;
  discount?: number;
  interval?: PlanInterval;
  intervalTooltip?: string;
  title: string;
  unitCost?: number;
  unitLabel?: string;
  unitLink?: string;
};

const CostLineItem = memo(
  ({
    children,
    cost,
    costTooltip,
    currencyFormatter,
    discount,
    interval,
    intervalTooltip,
    title,
    unitCost,
    unitLabel,
    unitLink,
  }: Props) => (
    <div className="p-4 w-full">
      <div className="flex flex-row mb-2">
        <h2>{title}</h2>
        <div className="flex flex-row ml-auto space-x-2">
          <Tooltip
            content={costTooltip}
            disabled={!costTooltip || discount !== undefined}
          >
            <span
              className={classNames({
                'line-through text-gray-400': discount !== undefined,
                'border-b-2 border-gray-400 border-dotted':
                  discount === undefined && costTooltip,
              })}
            >
              {currencyFormatter.format(cost)}
            </span>
          </Tooltip>
          {discount !== undefined && (
            <Tooltip content={costTooltip} disabled={!costTooltip}>
              <span
                className={classNames({
                  'border-b-2 border-gray-400 border-dotted': costTooltip,
                })}
              >
                {currencyFormatter.format(cost * discount)}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {interval && unitCost && (
        <span className="flex items-center text-gray-800 text-sm my-1">
          {children && (
            <span className="flex mr-1 space-x-1">
              <span>{children}</span>
              <span>-</span>
            </span>
          )}
          <span>
            {currencyFormatter.format(unitCost * (interval === YEAR ? 12 : 1))}
          </span>
          {unitLabel && (
            <>
              <IconX size={14} className="mx-1 opacity-75" />
              {unitLink ? (
                <a
                  className="my-auto text-blue-500 text-sm hover:text-blue-800 hover:underline"
                  href={unitLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {unitLabel}
                </a>
              ) : (
                <span>{unitLabel}</span>
              )}
            </>
          )}
          <IconX size={14} className="mx-1 opacity-75" />
          <span>{getText('billing.interval', interval)}</span>
        </span>
      )}
      {interval && (
        <Tooltip content={intervalTooltip} disabled={!intervalTooltip}>
          <span
            className={classNames('text-gray-600 text-sm', {
              'border-b-2 border-gray-400 border-dotted': intervalTooltip,
            })}
          >
            {getText('billing.plan.interval', interval, 'billed')}
          </span>
        </Tooltip>
      )}
    </div>
  ),
);
export default CostLineItem;

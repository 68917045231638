import { IconCornerDownRight, IconLink } from '@tabler/icons-react';
import { DataItemOption } from '@noloco/core/src/utils/state';

type DynamicValueSelectedOptionProps = {
  option: DataItemOption;
  onCollapseOption: (option: DataItemOption) => void;
};

const DynamicValueSelectedOption = ({
  option,
  onCollapseOption,
}: DynamicValueSelectedOptionProps) => (
  <div
    className="flex items-center rounded-lg py-1.5 px-3 cursor-pointer bg-slate-900"
    onClick={() => onCollapseOption(option)}
  >
    <IconCornerDownRight size={14} className="mr-2 flex-shrink-0 opacity-50" />
    <span className="text-xs text-gray-200 truncate">{option.label}</span>
    <IconLink size={14} className="ml-auto flex-shrink-0" />
  </div>
);

export default DynamicValueSelectedOption;

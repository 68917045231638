import React from 'react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { Button } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';
import { MIN_DATA_TYPES_TO_SHOW_SELECT } from '../../../../../core/src/constants/dataSources';
import DataSourceImportBuildLayouts from './DataSourceImportBuildLayouts';
import DataSourceImportPreBuiltLayouts from './DataSourceImportPreBuiltLayouts';
import DataSourceImportSelectDataTypes from './DataSourceImportSelectDataTypes';
import DataSourceImportSetupUserLists from './DataSourceImportSetupUserLists';
import DataSourceImportStatusSection from './DataSourceImportStatusSection';

const SHARED_LANG_KEY = 'data.dataSources.connect';

const DataSourceImportStatus = ({
  builtPages,
  createdDataSource,
  error,
  formSteps,
  hasSyncedData,
  inProgressPages,
  isConnecting,
  onDataTypesSelected,
  onFinish,
  project,
  skippedPages,
  skipSelectDataTypes,
  sourceType,
  step,
  inOnboarding = false,
  usePreBuiltLayout = false,
}: any) => {
  const importStep = step - formSteps;
  const langKey = `data.${camelCase(sourceType)}`;

  return (
    <div className="flex flex-col w-full max-w-2xl px-4 mb-auto space-y-4 py-16 overflow-y-auto">
      {error && (
        <DataSourceImportStatusSection
          error={true}
          loading={isConnecting}
          title={getText(langKey, 'error')}
          subtitle={error.message}
          surface={inOnboarding ? LIGHT : DARK}
        />
      )}
      {!error && (
        <DataSourceImportStatusSection
          loading={isConnecting}
          title={getText(langKey, 'connecting.title')}
          subtitle={getText(langKey, 'connecting.subtitle')}
          surface={inOnboarding ? LIGHT : DARK}
        />
      )}
      {createdDataSource && (
        <DataSourceImportStatusSection
          loading={false}
          title={getText(SHARED_LANG_KEY, 'connected.title')}
          subtitle={getText(
            {
              context: get(createdDataSource, 'types.length'),
            },
            SHARED_LANG_KEY,
            'connected.subtitle',
          )}
          surface={inOnboarding ? LIGHT : DARK}
        />
      )}
      {importStep >= 1 &&
        !skipSelectDataTypes &&
        get(createdDataSource, 'types', []).length >
          MIN_DATA_TYPES_TO_SHOW_SELECT && (
          <DataSourceImportSelectDataTypes
            dataSourceId={get(createdDataSource, 'id')}
            dataTypes={get(createdDataSource, 'types', [])}
            onFinish={onDataTypesSelected}
            project={project}
            inOnboarding={inOnboarding}
            sourceType={sourceType}
          />
        )}
      {importStep >= 2 && (
        <DataSourceImportStatusSection
          loading={!hasSyncedData && importStep === 2}
          title={getText(
            { name: get(createdDataSource, 'display') },
            SHARED_LANG_KEY,
            'importing.title',
          )}
          subtitle={getText(SHARED_LANG_KEY, 'importing.subtitle')}
          surface={inOnboarding ? LIGHT : DARK}
        />
      )}
      {importStep >= 3 && (
        <>
          <DataSourceImportStatusSection
            loading={false}
            pending={!hasSyncedData}
            title={getText(
              SHARED_LANG_KEY,
              hasSyncedData ? 'imported' : 'importPending',
              'title',
            )}
            subtitle={getText(
              SHARED_LANG_KEY,
              hasSyncedData ? 'imported' : 'importPending',
              'subtitle',
            )}
            surface={inOnboarding ? LIGHT : DARK}
          />
          {!usePreBuiltLayout && (
            <DataSourceImportBuildLayouts
              builtPages={builtPages}
              inProgressPages={inProgressPages}
              loading={importStep < 4}
              skippedPages={skippedPages}
              surface={inOnboarding ? LIGHT : DARK}
            />
          )}
          {usePreBuiltLayout && (
            <DataSourceImportPreBuiltLayouts
              loading={importStep < 4}
              surface={inOnboarding ? LIGHT : DARK}
            />
          )}
        </>
      )}
      {importStep >= 4 && (
        <>
          <DataSourceImportSetupUserLists
            project={project}
            createdDataSource={createdDataSource}
            surface={inOnboarding ? LIGHT : DARK}
          />
          <Button onClick={onFinish} size="lg" className="w-full">
            {inOnboarding
              ? getText('newProject.next')
              : getText(SHARED_LANG_KEY, 'finish')}
          </Button>
        </>
      )}
    </div>
  );
};

export default DataSourceImportStatus;

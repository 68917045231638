import { useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { Loader, Theme, getColorShade } from '@noloco/components';
import { formatBg } from '../utils/styles';

type AvatarProps = {
  className?: string;
  initialsSize?: string;
  size?: number;
  theme: Theme;
  user: any;
};

const Avatar = ({
  className,
  initialsSize = 'base',
  size = 16,
  theme,
  user,
}: AvatarProps) => {
  const primaryColor = theme.brandColorGroups.primary;

  const initials = useMemo(() => {
    const name = (user.firstName || user.lastName
      ? [user.firstName, user.lastName]
      : [user.email]
    )
      .join(' ')
      .split(' ')
      .filter(Boolean);

    let slice = 1;

    if (size <= 10 && name.length <= 2) {
      slice = 2;
    } else if (size > 10 && name.length >= 2) {
      slice = 3;
    }

    return name
      .map((p) => p[0])
      .slice(0, slice)
      .join('');
  }, [user.firstName, user.lastName, user.email, size]);

  return (
    <Box
      h={size}
      w={size}
      bg={getColorShade(primaryColor, 400)}
      className={classNames(
        className,
        'rounded-full text-white flex items-center justify-center font-medium bg-center bg-cover select-none',
      )}
      style={
        user.profilePicture
          ? formatBg(user.profilePicture.url ?? user.profilePicture.uri)
          : undefined
      }
      text={['text-capitalize', 'text-center', initialsSize]}
    >
      <Box
        h={size}
        w={size}
        className="flex items-center justify-center"
        data-testid="avatar-initials"
      >
        {!user.loading && !user.profilePicture && (
          <span
            className={classNames('text-center uppercase', {
              'text-xs': size <= 10,
            })}
          >
            {initials}
          </span>
        )}
        {user.loading && <Loader size="md" />}
      </Box>
    </Box>
  );
};

export default withTheme(Avatar);

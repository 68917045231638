import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconMail } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormField,
  Modal,
  Notice,
  isEmailValid,
} from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { UserTeam } from '@noloco/core/src/models/User';
import { addUserToTeam } from '@noloco/core/src/reducers/user';
import { getTextFromError } from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { INVITE_USER } from '../../queries/referrals';

const LANG_KEY = 'workspaces.settings.invite';

type InviteUserToWorkspaceModalProps = {
  workspace: UserTeam;
};

const InviteUserToWorkspaceModal = ({
  workspace,
}: InviteUserToWorkspaceModalProps) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [inviteUser, { loading: inviteLoading }] = useMutation(INVITE_USER);

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const close = useCallback(() => {
    push(`/workspaces/${workspace.id}`);
  }, [push, workspace.id]);

  const isValidCollaborator = useMemo(() => {
    if (!isEmailValid(email)) {
      return false;
    }

    return workspace.users.every((user) => user.email !== email);
  }, [email, workspace.users]);

  const sendInvite = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!email) {
        return;
      }

      if (!isEmailValid(email)) {
        setErrorMessage(getText({ email }, LANG_KEY, 'errors.invalidEmail'));
        return;
      }

      const teamId = parseInt(workspace.id as string, 10);

      inviteUser({
        variables: { email, teamId },
      })
        .then((response) => {
          setErrorMessage(null);
          setSuccessMessage(getText({ email }, LANG_KEY, 'success'));
          setEmail('');
          const newUser = response.data.inviteUser.user;
          console.log('newUSer', newUser);
          dispatch(addUserToTeam({ user: newUser, teamId }));
        })
        .catch((e) => {
          console.log('Error inviting user', e);
          setSuccessMessage(null);
          const { message: errorText } = getTextFromError(e);
          if (errorText) {
            setErrorMessage(errorText);
          } else {
            setErrorMessage(getText({ email }, LANG_KEY, 'errors.generic'));
          }
        });
    },
    [dispatch, email, inviteUser, workspace.id],
  );

  return (
    <Modal
      loading={inviteLoading}
      canCancel={false}
      onConfirm={close}
      title={getText({ workspace: workspace.name }, LANG_KEY, 'title')}
      confirmText={getText(LANG_KEY, 'confirm')}
    >
      <p className="mb-2">{getText(LANG_KEY, 'subtitle')}</p>
      {successMessage && <Notice type="success" subtitle={successMessage} />}
      {errorMessage && <Notice type="danger" subtitle={errorMessage} />}
      <form
        className="flex w-full items-end mt-4 mb-4 space-x-2 sm:space-y-2 sm:space-x-0 sm:flex-col"
        onSubmit={sendInvite}
      >
        <FormField
          className="w-full"
          label={getText(LANG_KEY, 'email.label')}
          placeholder={getText(LANG_KEY, 'email.placeholder')}
          value={email}
          onChange={({ target: { value } }: any) => {
            setEmail(value);
          }}
          surface={LIGHT}
          p={{ x: 4, y: 2 }}
        />
        <Button
          className="flex items-center"
          variant={SECONDARY}
          disabled={!isValidCollaborator}
          submitFormOnClick={true}
        >
          <IconMail size={16} className="mr-2 opacity-75" />
          <span>{getText(LANG_KEY, 'invite')}</span>
        </Button>
      </form>
    </Modal>
  );
};

export default InviteUserToWorkspaceModal;

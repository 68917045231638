import type { PropertyPath } from 'lodash';
import get from 'lodash/get';

/**
 * Reduces an array to a map-like object with a supplied transformer to enable fast lookups.
 */
export const transformedLookup = <TEntry, TValue, TKey extends keyof any>(
  array: TEntry[],
  entryTransformer: (entry: TEntry) => Record<TKey, TValue>,
): Record<TKey, TValue> =>
  array.reduce(
    (acc, entry) => ({
      ...acc,
      ...entryTransformer(entry),
    }),
    {} as Record<TKey, TValue>,
  );

/**
 * Reduces an array to a map-like object to enable fast lookups by an arbitrary key derived from each entry.
 */
export const formattedLookup = <TEntry, TValue, TKey extends keyof any>(
  array: TEntry[],
  pathKey: PropertyPath,
  formatValue: (entry: TEntry) => TValue,
): Record<TKey, TValue> =>
  transformedLookup<TEntry, TValue, TKey>(
    array,
    (entry) =>
      ({
        [get(entry, pathKey)]: formatValue(entry),
      } as Record<TKey, TValue>),
  );

/**
 * Reduces an array to a map-like object to enable fast lookups by a key on its entries.
 */
export const lookupOfArray = <T, TKey extends keyof any>(
  array: T[],
  pathKey: PropertyPath,
): Record<TKey, T> => formattedLookup(array, pathKey, (entry) => entry);

export const ensureArray = <T>(value: T | T[] | null | undefined): T[] => {
  if (Array.isArray(value)) {
    return value;
  }

  if (value === null || value === undefined) {
    return [];
  }

  return [value];
};

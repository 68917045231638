import BaseArrayTypeMap from './BaseArrayTypeMap';

interface NameIdentifier {
  id: number;
  name: string;
}

interface DataIdentifier extends NameIdentifier {
  apiName: string;
}

export type DataTypeName = string;
export type DataFieldName = string;
export type DataFieldOptionName = string;

export interface DataTypeIdentifier extends DataIdentifier {
  name: DataTypeName;
}
export interface DataFieldIdentifier extends DataIdentifier {
  name: DataFieldName;
}

export interface DataFieldOptionIdentifier extends NameIdentifier {
  name: DataFieldOptionName;
}

export class NameArrayTypeMap<
  T extends NameIdentifier
> extends BaseArrayTypeMap<T> {
  nameMap: Record<string, number>;

  constructor(arrayOrLength?: Number | T[] | BaseArrayTypeMap<T>) {
    super(arrayOrLength);

    if (arrayOrLength instanceof NameArrayTypeMap) {
      //Avoid re-calculating these maps if already populated
      this.nameMap = arrayOrLength.nameMap;
    } else {
      this.nameMap = {};
    }

    this.maps.nameMap = this.nameMap;
  }

  _mapEntry(entry: T) {
    super._mapEntry(entry);
    if (this.idMap) {
      this.idMap[entry.id] = entry;
      this.nameMap[entry.name] = entry.id;
    }
  }

  getByName(name: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIdOrUndefined(this.nameMap[name]);
  }
}

class ProjectArrayTypeMap<T extends DataIdentifier> extends NameArrayTypeMap<
  T
> {
  apiNameMap: Record<string, number>;

  constructor(arrayOrLength?: Number | T[] | ProjectArrayTypeMap<T>) {
    super(arrayOrLength);

    if (arrayOrLength instanceof ProjectArrayTypeMap) {
      //Avoid re-calculating these maps if already populated
      this.apiNameMap = arrayOrLength.apiNameMap;
    } else {
      this.apiNameMap = {};
    }

    this.maps.apiNameMap = this.apiNameMap;
  }

  _mapEntry(entry: T) {
    super._mapEntry(entry);
    if (this.idMap) {
      this.idMap[entry.id] = entry;
      this.apiNameMap[entry.apiName] = entry.id;
    }
  }
  getByApiName(apiName: string) {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIdOrUndefined(this.apiNameMap[apiName]);
  }

  getByNameOrThrow(name: string) {
    if (!this.idMap) {
      this._mapEntries();
    }
    if (!this.nameMap || !this.nameMap[name]) {
      throw new Error(`No entry found for name ${name}`);
    }

    const value = this.getByIdOrUndefined(this.nameMap[name]);

    if (!value) {
      throw new Error(`No entry found for name ${name}`);
    }

    return value;
  }
}

export default ProjectArrayTypeMap;

import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { IFRAME } from '../../../constants/elements';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import { fieldPermissions } from '../../../utils/permissions';
import Iframe from '../../Iframe';

const getIframeValue = (
  type: any,
  fieldName: any,
  url: any,
  dataType: any,
  user: any,
  record: any,
  fieldPermissionsEnabled: any,
) => {
  if (type === 'url') {
    return url;
  }

  const field = dataType.fields.getByName(fieldName);
  if (!field) {
    return null;
  }

  const permissions = fieldPermissions(
    field,
    fieldPermissionsEnabled && dataType.permissionsEnabled,
    dataType.permissions,
    user,
  );

  if (!permissions.read) {
    return null;
  }

  return get(record, [field.apiName]);
};

const RecordIframe = ({
  dataType,
  elementPath,
  editorMode,
  isSelected,
  field,
  type,
  record,
  project,
  recordScope,
  size,
  fullScreen = false,
  showBorder = true,
  ...rest
}: any) => {
  const { user } = useAuthWrapper();
  const [isDarkModeEnabled] = useDarkMode();

  const { source } = useSectionScopeVariables(
    IFRAME,
    rest,
    project,
    elementPath,
    recordScope,
  );
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const iframeValue = useMemo(
    () =>
      getIframeValue(
        type,
        field,
        source,
        dataType,
        user,
        record,
        fieldPermissionsEnabled,
      ),
    [type, field, fieldPermissionsEnabled, source, dataType, user, record],
  );

  if (!iframeValue && !editorMode) {
    return null;
  }

  if (!iframeValue && editorMode) {
    return (
      <div
        className={classNames(
          `flex items-center justify-center p-8 ${
            isDarkModeEnabled
              ? 'bg-slate-800 border-slate-600'
              : 'bg-white border-gray-200'
          }`,
          { 'border rounded-lg shadow-md': !fullScreen },
        )}
      >
        <div className="text-center w-full text-gray-600 py-20 text-sm">
          {getText('elements.IFRAME.empty')}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'bg-white flex items-center justify-center relative',
        {
          'rounded-lg border border-gray-200 shadow-md': showBorder,
        },
      )}
    >
      <Iframe
        className={classNames('h-screen w-full overflow-hidden', {
          'rounded-lg': !fullScreen,
          'max-h-screen-25': size === SM,
          'max-h-screen-50': size === MD,
          'max-h-screen-75': size === LG,
          'max-h-screen': size === XL,
        })}
        // @ts-expect-error TS(2322): Type '{ className: string; isSelected: any; editor... Remove this comment to see the full error message
        isSelected={isSelected}
        editorMode={editorMode}
        source={iframeValue}
        fullScreen={fullScreen}
      />
    </div>
  );
};

RecordIframe.defaultProps = {
  size: MD,
};

export default RecordIframe;

import React from 'react';
import { IconHelp } from '@tabler/icons-react';
import classNames from 'classnames';
import { LIGHT } from '../../constants/surface';
import { Surface } from '../../models';
import Popover from './Popover';

type Props = {
  children: React.ReactNode;
  className?: string;
  placement?: string;
  size?: number;
  surface?: Surface;
};

const HelpTooltip = ({
  children,
  className = 'text-white hover:text-gray-500',
  placement = 'top',
  size = 16,
  surface = LIGHT,
}: Props) => (
  <Popover
    content={<div className="max-w-xs text-sm text-black p-1">{children}</div>}
    placement={placement}
    surface={surface}
    trigger="hover"
  >
    <div className={classNames(className)}>
      <IconHelp size={size} />
    </div>
  </Popover>
);

export default HelpTooltip;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Login as LoginLayout } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import SocialLogin from '@noloco/core/src/components/SocialLogin';
import { GOOGLE_CLIENT_ID } from '@noloco/core/src/constants/auth';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import logoBlack from '../img/infinity-logo-black.svg';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import SidePanel from './SidePanel';

const Login = ({ history }: any) => {
  const {
    query: { email },
  } = useRouter();
  const [errors, setErrors] = useState([]);
  const { login } = useDashboardAuth();
  useTrackDashboardPage(PageTypes.LOGIN);

  const handleLogin = ({ email, password }: any) => {
    login(email, password)
      .then(() => {
        history.push(`/`);
      })
      .catch((error: any) => {
        if (error.graphQLErrors) {
          setErrors(error.graphQLErrors.map((er: any) => er.message));
        } else {
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
          setErrors(['Your email or password is incorrect, please try again']);
        }
        console.warn('ERROR', error);
      });
  };

  return (
    <LoginLayout
      buttonText={getText('auth.login.button')}
      errors={errors}
      forgotText={getText('auth.login.forgot')}
      logoUrl={logoBlack}
      initialEmail={email}
      onSubmit={handleLogin}
      registerText={
        <span>
          <span>{getText('auth.login.newUser')}</span>
          <Link
            to="/register"
            className="ml-1 font-medium text-pink-600 hover:text-pink-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            {getText('auth.login.registerLink')}
          </Link>
        </span>
      }
      titleText={getText('auth.login.title')}
      emailLabel={getText('auth.fields.workEmail')}
      passwordLabel={getText('auth.fields.password')}
      rememberLabel={getText('auth.fields.rememberMe')}
      type="SPLIT"
      surface={LIGHT}
      splitChildren={<SidePanel />}
    >
      <SocialLogin
        clientId={GOOGLE_CLIENT_ID}
        defaultButtonWidth={416}
        setErrors={setErrors}
        preventNonWorkspaceAccounts={true}
      />
    </LoginLayout>
  );
};

export default Login;

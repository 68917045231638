import { Combobox } from '@headlessui/react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataItemOption } from '@noloco/core/src/utils/state';
import DataFieldIcon from '../../DataFieldIcon';

type DynamicValueOptionProps = {
  option: DataItemOption;
};

const DynamicValueOption = ({ option }: DynamicValueOptionProps) => (
  <Combobox.Option
    value={option}
    className={({ active }) =>
      classNames('flex items-center rounded-lg py-1.5 px-3 cursor-pointer', {
        'bg-slate-800 hover:bg-slate-900': !active,
        'bg-slate-800 hover:bg-slate-900 ring-2 ring-teal-500 ring-inset': active,
      })
    }
  >
    <span className="text-xs text-gray-200 truncate">{option.label}</span>
    {option.value && option.value.dataType && (
      <DataFieldIcon
        className="ml-auto flex-shrink-0"
        size={14}
        field={option.field ?? ({ type: option.value.dataType } as DataField)}
      />
    )}
    {((!option.value && (option.options || option.getOptions)) ||
      option.value?.path?.startsWith('DATE.custom')) && (
      <IconArrowNarrowRight
        size={14}
        className="ml-auto flex-shrink-0 opacity-50"
      />
    )}
  </Combobox.Option>
);

export default DynamicValueOption;

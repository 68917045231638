import React from 'react';
import { HelpTooltip, Switch } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'dynamicValueInput.dynamicSwitch';

type DynamicSwitchProps = {
  onChange: (value: boolean) => void;
  value: boolean;
};

const DynamicSwitch = ({ onChange, value }: DynamicSwitchProps) => (
  <div className="flex items-center text-sm w-full max-w-full text-gray-100">
    <span className="text-xs whitespace-nowrap mr-1">
      {getText(LANG_KEY, 'label')}
    </span>
    <HelpTooltip className="text-white hover:text-gray-500 mr-4">
      <span>{getText(LANG_KEY, 'tooltip')}</span>
    </HelpTooltip>
    <Switch size="sm" className="ml-auto" value={value} onChange={onChange} />
  </div>
);

export default DynamicSwitch;

import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { PropertyPath } from 'lodash';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { Surface } from '@noloco/components';
import DatePicker from '../../../../components/DatePicker';
import { OBJECT } from '../../../../constants/dataTypes';
import { DATE_TIME, DUE_DATE } from '../../../../constants/fieldFormats';
import { DataField } from '../../../../models/DataTypeFields';
import { getText } from '../../../../utils/lang';
import { formatDateValue } from '../DataFieldInput';

type Props = {
  disabled?: boolean;
  field: DataField;
  onBlur: () => void;
  onUpdateDraftValue: (path: PropertyPath) => (value: any) => void;
  onUpdateValue: (path: PropertyPath) => (value: any) => void;
  surface: Surface;
  validationError?: string;
  value: any;
};

const DueDateInput = ({
  disabled,
  field,
  onUpdateValue,
  surface,
  value,
}: Props) => {
  return (
    <div
      className="flex space-x-4 transparent w-full"
      data-testid="due-date-input"
    >
      <DatePicker
        // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
        disabled={disabled}
        selectTime={field.typeOptions?.time}
        value={value?.from ? DateTime.fromISO(value?.from) : null}
        onChange={(val: any) =>
          onUpdateValue(['from'])(val ? formatDateValue(val, DATE_TIME) : null)
        }
        surface={surface}
        placeholder={getText('data.types', OBJECT, DUE_DATE, 'from.label')}
        timeZone={get(field, 'typeOptions.timeZone')}
        w="full"
      />
      <DatePicker
        // @ts-expect-error TS(2322): Type '{ inline: any; validationError: any; value: ... Remove this comment to see the full error message
        disabled={disabled}
        selectTime={field.typeOptions?.time}
        value={value?.to ? DateTime.fromISO(value?.to) : null}
        onChange={(val: any) =>
          onUpdateValue(['to'])(val ? formatDateValue(val, DATE_TIME) : null)
        }
        surface={surface}
        placeholder={getText('data.types', OBJECT, DUE_DATE, 'to.label')}
        timeZone={get(field, 'typeOptions.timeZone')}
        w="full"
      />
    </div>
  );
};

export default withTheme(DueDateInput);

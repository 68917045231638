import React from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import { Badge, RatingInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { RATING } from '@noloco/core/src/constants/fieldFormats';
import { IMAGE } from '@noloco/core/src/constants/fileTypes';
import ArrayValueBadge from '@noloco/core/src/elements/sections/collections/ArrayValueBadge';
import { formatValue } from '@noloco/core/src/elements/sections/collections/FieldCell';
import OptionBadge from '@noloco/core/src/elements/sections/collections/OptionBadge';
import RelatedCellItem from '@noloco/core/src/elements/sections/collections/RelatedCellItem';
import FilePreview from '@noloco/core/src/elements/sections/forms/FilePreview';
import { RecordValue } from '@noloco/core/src/models/Record';
import { ensureArray } from '@noloco/core/src/utils/arrays';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import {
  isMultiRelationship,
  isReverseMultiRelationship,
} from '@noloco/core/src/utils/relationships';

const DataCell = ({ dataTypes, field, project, value }: any) => {
  const locale = useLocale();
  const { relatedField, relationship, type } = field;
  if (type === FILE && value) {
    const isMultiFiles = isMultiField(field);
    const filesValue = isMultiFiles
      ? value.edges.map((edge: any) => edge.node)
      : value
      ? [value]
      : [];
    const { fileType, name, url } = value;

    if (fileType === IMAGE) {
      return (
        <img
          alt={name}
          src={url}
          className="mx-auto overflow-hidden rounded w-14"
        />
      );
    } else {
      return (
        <FilePreview
          files={filesValue}
          id={field.name}
          isMultiple={isMultiFiles}
          readOnly={true}
          surface={DARK}
        />
      );
    }
  }

  if (type === 'membership' && value && value.plan) {
    const { stripe } = project.integrations;
    const plan =
      stripe && stripe.plans.find(({ id }: any) => id === value.plan.id);
    if (plan) {
      return (
        <a
          className="flex items-center px-2 py-1 bg-pink-700 rounded-md"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://dashboard.stripe.com/${
            value.subscriptionId ? 'subscriptions' : 'prices'
          }/${value.subscriptionId || plan.priceId}`}
        >
          {plan.name}
        </a>
      );
    }
  }

  if (
    (relatedField && isReverseMultiRelationship(relatedField.relationship)) ||
    isMultiRelationship(relationship)
  ) {
    const total = get(value, 'edges.length', 0);
    return (
      <div className="flex flex-wrap -mt-1.5 -ml-1.5">
        <div className="inline-block">
          <Badge className="inline-block m-1" color="pink-900">
            <div className="py-1">{total}</div>
          </Badge>
        </div>
        {get(value, 'edges', []).map((edge: any) => (
          <div className="inline-block m-1" key={edge.node.id}>
            <Link
              onClick={(e) => e.stopPropagation()}
              className="flex items-center p-1 bg-pink-700 rounded-md"
              to={`/_/data/internal/${field.type}/view/${edge.node.id}`}
            >
              <RelatedCellItem
                field={field}
                value={edge.node}
                dataTypes={dataTypes}
                single={true}
              />
            </Link>
          </div>
        ))}
      </div>
    );
  }

  if (
    relationship ||
    (relatedField && !isReverseMultiRelationship(relatedField.relationship))
  ) {
    if (!value || !value.uuid) {
      return null;
    }
    return (
      <div className="inline-block -mt-0.5">
        <Link
          onClick={(e) => e.stopPropagation()}
          className="flex items-center p-1 bg-pink-700 rounded-md"
          to={`/_/data/internal/${field.type}/view/${value.id}`}
        >
          <RelatedCellItem field={field} value={value} dataTypes={dataTypes} />
        </Link>
      </div>
    );
  }

  if (field.type === SINGLE_OPTION) {
    const option = field.options.find(({ name }: any) => name === value);
    if (option) {
      return <OptionBadge className="-mt-0.5 mr-auto" option={option} />;
    }
  }

  if (field.type === MULTIPLE_OPTION) {
    const options = value
      .map((val: any) => field.options.find(({ name }: any) => name === val))
      .filter(Boolean);

    return (
      <div className="flex flex-wrap -mt-1.5 -ml-1.5 gap-1">
        {options.map((option: any) => (
          <OptionBadge key={option.name} option={option} surface={DARK} />
        ))}
      </div>
    );
  }

  if (field.name === 'id') {
    return value;
  }

  if (field.typeOptions?.format === RATING) {
    return (
      <RatingInput
        disabled={true}
        maxRating={get(field.typeOptions, 'max')}
        value={value}
      />
    );
  }

  if (isNil(value)) {
    return null;
  }

  if (field.multiple) {
    return (
      <div className="flex flex-wrap gap-1">
        {ensureArray(value).map((entry: string | number, ix) => {
          if (field.type === BOOLEAN) {
            return (
              <ArrayValueBadge
                key={`${field.name}-${entry}`}
                value={entry ? <IconCheck size={20} /> : <IconX size={20} />}
                surface={DARK}
              />
            );
          }

          return (
            <ArrayValueBadge
              key={`${entry}-${ix}`}
              value={formatValue(entry, field, {}, locale) as RecordValue}
              surface={DARK}
            />
          );
        })}
      </div>
    );
  }

  if (type === BOOLEAN) {
    return value ? <IconCheck size={18} /> : '-';
  }

  return formatValue(value, field, {}, locale);
};

export default DataCell;

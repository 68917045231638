import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { Listbox } from '@headlessui/react';
import first from 'lodash/first';
import { DARK } from '../../constants/surface';
import { MD, XS } from '../../constants/tShirtSizes';
import useBreakpoints from '../../utils/hooks/useBreakpoints';
import { ROUNDED_LARGE } from '../input/inputStyles';
import themeStyles from '../input/inputTheme';
import OptionsList from './OptionList';
import styleForSizes from './optionStyles';
import { getSelectStyles, listBoxStyles } from './selectStyles';

const getOptionStyles = (
  buttonRef: React.MutableRefObject<HTMLBaseElement | undefined>,
  isSmScreen: boolean,
) => {
  const minWidth =
    buttonRef && buttonRef.current
      ? (buttonRef.current as any).clientWidth
      : undefined;

  const style = {} as { minWidth?: number; maxWidth?: number };

  if (minWidth) {
    style.minWidth = minWidth;

    if (isSmScreen) {
      style.maxWidth = minWidth;
    }
  }

  return style;
};

export type SelectOption = {
  value?: string | number | boolean;
  label?: string | number | React.ReactNode;
  help?: string | number | React.ReactNode;
  heading?: boolean;
  icon?: string | number | React.ReactNode;
  onClick?: (...args: any[]) => any;
  disabled?: boolean;
  options?: SelectOption[];
};

export type SelectBaseProps = {
  bg?: string;
  listBg?: string;
  className?: string;
  contained?: boolean;
  'data-testid'?: string;
  options?: SelectOption[];
  disabled?: boolean;
  direction?: string;
  fillBackground?: boolean;
  open?: boolean;
  placement?: string;
  multiple?: boolean;
  rootEl?: React.ReactNode;
  surface?: string;
  size?: any; // TODO: PropTypes.oneOf([SM, MD, LG])
  showEmptyState?: boolean;
  stopPropagation?: boolean;
  hideDropdownIndicator?: boolean;
  small?: boolean;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  usePortal?: boolean;
  coloredOptionType?: boolean;
  shiftRight?: boolean;
};

export const SelectBase = forwardRef<any, SelectBaseProps>(
  (
    {
      bg,
      // @ts-expect-error TS(2339): Property 'border' does not exist on type 'Props'.
      border,
      // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'Pro... Remove this comment to see the full error message
      borderColor,
      children,
      'data-testid': dataTestid,
      listBg,
      className,
      direction,
      disabled,
      // @ts-expect-error TS(2339): Property 'hasMore' does not exist on type 'Props'.
      hasMore,
      hideDropdownIndicator,
      fillBackground = true,
      // @ts-expect-error TS(2339): Property 'footer' does not exist on type 'Props'.
      footer,
      // @ts-expect-error TS(2339): Property 'label' does not exist on type 'Props'.
      label,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
      loading,
      multiple,
      open,
      options,
      placement = 'bottom-start',
      size,
      style,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Props'.
      value,
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Props'... Remove this comment to see the full error message
      onChange,
      // @ts-expect-error TS(2339): Property 'onFetchMore' does not exist on type 'Pro... Remove this comment to see the full error message
      onFetchMore,
      // @ts-expect-error TS(2339): Property 'onOpenChange' does not exist on type 'Pr... Remove this comment to see the full error message
      onOpenChange,
      // @ts-expect-error TS(2339): Property 'onSearchChange' does not exist on type '... Remove this comment to see the full error message
      onSearchChange,
      surface,
      // @ts-expect-error TS(2339): Property 'searchable' does not exist on type 'Prop... Remove this comment to see the full error message
      searchable,
      showEmptyState = true,
      // @ts-expect-error TS(2339): Property 'hideOptions' does not exist on type 'Pro... Remove this comment to see the full error message
      hideOptions,
      stopPropagation,
      // @ts-expect-error TS(2339): Property 'text' does not exist on type 'Props'.
      text,
      // @ts-expect-error TS(2339): Property 'minW' does not exist on type 'Props'.
      minW,
      usePortal,
      coloredOptionType,
      shiftRight = false,
      ...rest
    },
    ref,
  ) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const buttonRef = useRef<HTMLBaseElement | undefined>();
    const [localOpen, setLocalOpen] = useState(open);
    const [, setButtonRefState] = useState<HTMLBaseElement | undefined>(
      undefined,
    );

    useEffect(() => {
      if (onOpenChange && open !== localOpen) {
        onOpenChange(localOpen);
      }
    }, [localOpen, onOpenChange, open]);

    const formattedValue = useMemo(() => {
      if (multiple) {
        if (!value) {
          return [];
        }
        if (Array.isArray(value)) {
          return value;
        }

        return [value];
      }

      if (!value) {
        return value;
      }

      if (Array.isArray(value)) {
        return first(value);
      }

      return value;
    }, [value, multiple]);

    const handleOnChange = (newValue: any) => {
      setLocalOpen(false);
      let nextValue = newValue;
      if (multiple) {
        if (selectedValue && selectedValue.includes(newValue)) {
          nextValue = selectedValue.filter((val: any) => val !== newValue);
        } else {
          nextValue = [...(selectedValue || []), newValue];
        }
      }
      setSelectedValue(nextValue);
      if (onChange) {
        onChange(nextValue);
      }
    };

    useEffect(() => {
      setSelectedValue(formattedValue);
    }, [formattedValue]);

    useLayoutEffect(() => {
      if (buttonRef && buttonRef.current) {
        setButtonRefState(buttonRef.current);
      }
    }, [buttonRef]);

    const { textColor = text, surface: themeSurface = 'default' } =
      theme.selectInput || {};
    const themeBg = theme.surfaceColors[surface || themeSurface];
    const bgValue = bg || themeBg;
    const borderColorValue =
      borderColor || theme.borderColors[surface || themeSurface];

    const listStyles = {
      ...getSelectStyles(borderColorValue),
      ...styleForSizes(size, !hideDropdownIndicator, multiple),
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...themeStyles(borderColorValue)[style],
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...listBoxStyles[style],
      bg: listBg || bgValue === 'transparent' ? themeBg : bgValue,
      text: text || textColor,
    };

    const disabledBg = surface === DARK ? listStyles.bg : 'gray-100';
    const buttonStyles = {
      ...listStyles,
      bg: disabled ? disabledBg : bgValue || listStyles.bg,
      border: border !== undefined ? border : listStyles.border,
    };

    const { sm: isSmScreen } = useBreakpoints();

    const optionsStyle = getOptionStyles(buttonRef, isSmScreen);

    return (
      <Box
        is={Listbox}
        as="div"
        className={className}
        data-testid={dataTestid}
        value={selectedValue}
        disabled={disabled}
        onChange={handleOnChange}
        ref={ref}
        {...rest}
      >
        {() => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium leading-5">
                {label}
              </Listbox.Label>
            )}
            {(open || localOpen) && !disabled && fillBackground && (
              <div
                className="fixed top-0 bottom-0 left-0 right-0 z-30"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setLocalOpen(false);
                }}
              />
            )}
            <OptionsList
              depth={0}
              data-testid={dataTestid ? `${dataTestid}-options` : undefined}
              placement={placement}
              direction={direction}
              footer={footer}
              open={(open || localOpen) && !disabled}
              hasMore={hasMore}
              listIs={Listbox.Options}
              loading={loading}
              options={options}
              onChange={handleOnChange}
              onFetchMore={onFetchMore}
              onSearchChange={onSearchChange}
              multiple={multiple}
              currentValue={selectedValue}
              searchable={searchable}
              showEmptyState={showEmptyState}
              hideOptions={hideOptions}
              listStyle={style}
              styles={listStyles}
              size={size === XS ? MD : size}
              trigger="none"
              minW={minW}
              style={optionsStyle}
              usePortal={usePortal}
              surface={theme?.optionList?.surface || surface}
              coloredOptionType={coloredOptionType}
              shiftRight={shiftRight}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (stopPropagation) {
                    e.stopPropagation();
                  }
                  setLocalOpen(!localOpen);
                }}
                disabled={disabled}
                className="w-full focus:ring-2 rounded-lg"
              >
                {/* @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
                <div ref={buttonRef}>
                  {/* @ts-expect-error TS2349: This expression is not callable. */}
                  {children({
                    selectedValue,
                    formattedValue,
                    styles: buttonStyles,
                  })}
                </div>
              </button>
            </OptionsList>
          </>
        )}
      </Box>
    );
  },
);

SelectBase.defaultProps = {
  style: ROUNDED_LARGE,
  className: '',
  contained: false,
  direction: 'right-start',
  hideDropdownIndicator: false,
  multiple: false,
  open: false,
  size: MD,
  // @ts-expect-error TS(2322): Type '{ style: string; className: string; containe... Remove this comment to see the full error message
  placeholder: '',
  rootEl: null,
  stopPropagation: false,
  textCenter: false,
  theme: 'default',
  usePortal: true,
  coloredOptionType: false,
};

export default withTheme(SelectBase);

import React from 'react';
import ProjectList from './ProjectList';
import WorkspaceList from './WorkspaceList';

const WorkspaceProjectList = () => {
  return (
    <div className="flex md:flex-col md:mb-2">
      <WorkspaceList path="/apps" />
      <ProjectList />
    </div>
  );
};

export default WorkspaceProjectList;

import React, { useMemo } from 'react';
import classNames from 'classnames';
import ReactFlow, { Background, Controls, MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { BaseRecord } from '@noloco/core/src/models/Record';
import { getFieldReverseName } from '@noloco/core/src/utils/fields';
import {
  filterDataTypes,
  getGroupedDataTypeOptions,
} from '../../utils/dataTypes';
import TableNode from './TableNode';

const FIELD_NODE_HEIGHT = 32;
const TABLE_WIDTH = 520;
const VERTICAL_GAP = 80;

const TABLES_PER_ROW = 5;

type PositionAcc = {
  minY: Record<number, number>;
  tables: Record<string, { x: number; y: number }>;
};

const nodeTypes = {
  dataType: TableNode,
};

const VisualizeDataTables = ({
  className,
  dataTypes,
  getRecordForDataType,
}: {
  className?: string;
  dataTypes: any[];
  getRecordForDataType: (dataType: DataType) => Promise<BaseRecord | null>;
}) => {
  const dataListGroups = useMemo(() => getGroupedDataTypeOptions(dataTypes), [
    dataTypes,
  ]);

  const filteredDataTypes = useMemo(() => dataTypes.filter(filterDataTypes), [
    dataTypes,
  ]);

  const positions = useMemo(() => {
    return dataListGroups.reduce(
      (positionsAcc, { options }) => {
        const globalMinY = positionsAcc.minY;

        const sourcePositions = options.reduce(
          (acc, { dataType }: { dataType: DataType }, index) => {
            const yPosition = Math.floor(index / TABLES_PER_ROW);
            const xPosition = index % TABLES_PER_ROW;

            const visibleFields = dataType.fields.filter(
              (field) => !field.hidden,
            );
            const height =
              visibleFields.length * FIELD_NODE_HEIGHT + VERTICAL_GAP;

            const x = xPosition * TABLE_WIDTH;
            const yTop = Math.max(
              acc.minY[xPosition] ? acc.minY[xPosition] + VERTICAL_GAP : 0,
              yPosition,
            );
            const yBottom = yTop + height;

            const minY = acc.minY[xPosition] ?? 0;
            acc.minY[xPosition] = Math.max(minY, yBottom);
            acc.tables[dataType.name] = {
              x,
              y: yTop + globalMinY,
            };
            return acc;
          },
          { minY: {}, tables: {} } as PositionAcc,
        );

        const d = {
          minY:
            Math.max(...Object.values(sourcePositions.minY)) +
            globalMinY +
            VERTICAL_GAP,
          tables: {
            ...positionsAcc.tables,
            ...sourcePositions.tables,
          },
        };

        return d;
      },
      { minY: 0, tables: {} } as {
        minY: number;
        tables: Record<string, { x: number; y: number }>;
      },
    );
  }, [dataListGroups]);

  const nodes = useMemo(
    () =>
      filteredDataTypes.map((dataType, index) => ({
        id: dataType.name,
        type: 'dataType',
        data: { dataType, dataTypes, record: getRecordForDataType(dataType) },
        connectable: false,
        deltable: false,
        draggable: false,
        position: positions.tables[dataType.name] || { x: index * 520, y: 0 },
      })),
    [dataTypes, filteredDataTypes, getRecordForDataType, positions.tables],
  );

  const edges = useMemo(
    () =>
      filteredDataTypes.reduce(
        (edgeAcc, dataType: DataType) => [
          ...edgeAcc,
          ...dataType.fields
            .filter(
              (field) =>
                field.relationship && field.type !== FILE && !field.hidden,
            )
            .map((field) => {
              const relatedDataType = dataTypes.find(
                ({ name }) => name === field.type,
              );

              if (!relatedDataType) {
                return null;
              }

              const reverseName = getFieldReverseName(field, relatedDataType);

              return {
                id: `${dataType.name}-${field.name}`,
                source: dataType.name,
                target: field.type,
                type: 'smoothstep',
                sourceHandle: `${dataType.name}-${field.name}-right`,
                targetHandle: `${relatedDataType.name}-${reverseName}-left`,
                animated: false,
                data: {
                  field,
                  dataType,
                },
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
              };
            })
            .filter(Boolean),
        ],
        [] as any[],
      ),
    [dataTypes, filteredDataTypes],
  );

  return (
    <div className={classNames('w-full h-full bg-gray-50', className)}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        attributionPosition="top-right"
        nodeTypes={nodeTypes}
      >
        <Controls showInteractive={false} />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default VisualizeDataTables;

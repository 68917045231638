import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { DARK, LIGHT } from '../../constants/surface';
import { AutocompleteTextInput } from '../input';
import TextArea from '../input/TextArea';
import ThemedTextInput, { TextInput, getInputStyles } from '../input/TextInput';
import HelpTooltip from '../popover/HelpTooltip';
import { SelectOption } from '../select/SelectBase';
import ErrorText from './ErrorText';
import Label from './Label';
import { CONNECTED, ErrorPosition, INLINE_TOOLTIP } from './errorPositions';

const formFieldClasses = {
  [INLINE_TOOLTIP]: 'relative',
};

const inputClasses = {
  [CONNECTED]: 'rounded-b-none',
};

/*
(ts-migrate) TODO: Migrate the remaining prop types
...TextInput.propTypes
*/
type Props = {
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  errorMessage?: string | React.ReactNode | number;
  errorType?: ErrorPosition;
  label?: string | React.ReactNode | number;
  inputType?: 'text' | 'text-area' | 'select' | 'autocomplete';
  onChange?: (...args: any[]) => any;
  options?: SelectOption[];
  tooltip?: React.ReactNode | string;
  value?: string | number;
};

const FormField = forwardRef<any, Props>(
  (
    {
      className,
      'data-testid': dataTestid,
      disabled,
      errorMessage,
      errorType = '',
      inputType,
      // @ts-expect-error TS(2339): Property 'help' does not exist on type 'Props'.
      help,
      label,
      onChange,
      // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type 'Pro... Remove this comment to see the full error message
      placeholder,
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'Props'.
      type,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Props'.
      name,
      options,
      value,
      tooltip,
      // @ts-expect-error TS(2339): Property 'suffix' does not exist on type 'Props'.
      suffix,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      ...rest
    },
    ref,
  ) => {
    const surfaceValue =
      surface || get(rest, 'theme.textInput.surface', 'default');

    return (
      <div
        className={classNames(formFieldClasses[errorType], className)}
        data-testid={dataTestid}
        ref={ref}
      >
        {label && (
          <div className="flex items-center mb-2 text-sm">
            {/* @ts-expect-error TS(2322): Type '{ children: string | number | true | ReactEl... Remove this comment to see the full error message */}
            <Label surface={surfaceValue} htmlFor={name} m={0}>
              {label}
            </Label>
            {tooltip && (
              <HelpTooltip
                className={classNames('ml-2 hover:text-gray-500', {
                  'text-gray-800': surface === LIGHT,
                  'text-gray-200': surface === DARK,
                })}
                placement="right"
              >
                <p>{tooltip}</p>
              </HelpTooltip>
            )}
          </div>
        )}
        {help && (
          <p
            className={classNames('text-sm mb-2', {
              'text-gray-600':
                surfaceValue === LIGHT || surfaceValue === 'default',
              'text-gray-200': surfaceValue === DARK,
            })}
          >
            {help}
          </p>
        )}
        <div className="flex items-center w-full">
          {inputType === 'text' && (
            <ThemedTextInput
              className={classNames(inputClasses[errorType], {
                'rounded-tr-none rounded-br-none': suffix,
              })}
              disabled={disabled}
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              surface={surfaceValue}
              {...rest}
            />
          )}
          {inputType === 'autocomplete' && options && (
            <AutocompleteTextInput
              className={classNames(inputClasses[errorType], {
                'rounded-tr-none rounded-br-none': suffix,
              })}
              disabled={disabled}
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              options={options}
              placeholder={placeholder}
              surface={surfaceValue}
              {...rest}
            />
          )}
          {inputType === 'text-area' && (
            <TextArea
              className={inputClasses[errorType]}
              disabled={disabled}
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              rows={4}
              surface={surfaceValue}
              {...rest}
            />
          )}
          {suffix && (
            <Box
              {...getInputStyles({ ...rest, surface: surfaceValue })}
              className={classNames(inputClasses[errorType], 'sm:text-base', {
                'rounded-tl-none rounded-bl-none text-sm px-3 py-1.5 font-medium tracking-wider border-l-0 leading-tight': suffix,
                'bg-gray-200':
                  surfaceValue === LIGHT || surfaceValue === 'default',
                'bg-brand-dark': surfaceValue === DARK,
              })}
            >
              <span className="pb-px">{suffix}</span>
            </Box>
          )}
        </div>
        {errorMessage && <ErrorText type={errorType}>{errorMessage}</ErrorText>}
      </div>
    );
  },
);

FormField.defaultProps = {
  ...TextInput.defaultProps,
  className: '',
  // @ts-expect-error TS(2322): Type '{ className: string; errorText: undefined; e... Remove this comment to see the full error message
  errorText: undefined,
  errorType: undefined,
  inputType: 'text',
  label: undefined,
  theme: 'default',
  onChange: () => null,
  value: '',
};

export default withTheme(FormField);

import React, { forwardRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { MAX_FILE_SIZE_BYTES } from '../../constants/files';
import useReadFileInputAttachments from '../../utils/hooks/useReadFileInputAttachments';

type ChildrenProps = {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

type Props = {
  acceptedMimetypes?: string[];
  children: ({
    isDragActive,
    isDragAccept,
    isDragReject,
  }: ChildrenProps) => any;
  className?: string;
  id?: any;
  maxFiles?: number;
  maxSize?: number;
  onChange: (fileorFiles: any) => any;
  noClick?: boolean;
  noDrag?: boolean;
  disabled?: boolean;
};

const BaseDropzone = forwardRef<any, Props>(
  (
    {
      acceptedMimetypes,
      children,
      className = '',
      disabled = false,
      id,
      onChange,
      maxFiles = 1,
      maxSize = MAX_FILE_SIZE_BYTES,
      noClick = false,
      noDrag = false,
    },
    ref,
  ) => {
    const readFileInputAttachments = useReadFileInputAttachments();
    const onDrop = useCallback(
      (acceptedFiles: any) => {
        readFileInputAttachments(acceptedFiles).then((files) => {
          if (maxFiles === 1 && files.length > 0) {
            if (onChange) {
              onChange(files[0]);
            }
          } else {
            if (onChange && files.length > 0) {
              onChange([...files]);
            }
          }
        });
      },
      [maxFiles, onChange, readFileInputAttachments],
    );
    const {
      isDragActive,
      isDragAccept,
      isDragReject,
      getRootProps,
      getInputProps,
    } = useDropzone({
      accept: acceptedMimetypes,
      disabled,
      onDrop,
      noClick,
      noDrag,
      maxFiles,
      maxSize,
      multiple: maxFiles > 1,
    });

    const inputProps = useMemo(() => getInputProps(), [getInputProps]);
    const rootProps = useMemo(() => getRootProps(), [getRootProps]);

    const onClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (rootProps.onClick) {
          rootProps.onClick(event);
        }
      },
      [rootProps],
    );

    return (
      <div
        {...rootProps}
        onClick={onClick}
        id={id}
        ref={ref}
        className={classNames(
          'dropzone cursor-pointer focus-within:ring-2 rounded-lg',
          className,
          { 'cursor-not-allowed': disabled },
        )}
      >
        <input {...inputProps} />
        {children({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      </div>
    );
  },
);

export default BaseDropzone;

import snakeCase from 'lodash/snakeCase';
import {
  DataFieldType,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '../constants/dataTypes';
import DataFieldOptions from '../models/DataFieldOptions';
import { DataField, DataFieldOption } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import StateItem from '../models/StateItem';
import { DataItemValueOption } from './state';

export const hasNullOption = (field: DataField): boolean =>
  field.type === SINGLE_OPTION && !field.required;

export const isOptionType = (fieldType: DataFieldType | string): boolean =>
  fieldType === SINGLE_OPTION || fieldType === MULTIPLE_OPTION;

export const isOptionValid = (option: any) => option && option.display;

export const getOptionByName = (
  name: string,
  field: DataField,
): DataFieldOption | undefined => {
  if (!name) {
    return;
  }

  let option;
  if (field.options instanceof DataFieldOptions) {
    option = field.options?.getByName(name);
  } else if (field.options) {
    const fieldOptions = new DataFieldOptions(field.options);
    option = fieldOptions.getByName(name);
    field.options = fieldOptions;
  }

  return option;
};

export const formatStateItemAsBaseOption = (
  stateItem: StateItem,
  dataPath: { display: string }[] = [],
  field?: DataField,
  dataType?: DataType,
): Omit<DataItemValueOption, 'icon'> & { value: StateItem } => {
  return {
    label: stateItem.display,
    buttonLabel: [...dataPath, stateItem]
      .map((pathItem) => pathItem.display)
      .filter(Boolean)
      .join(' > '),
    value: stateItem,
    field,
    dataType,
  };
};

export const getByUncleanedDisplay = (options: DataFieldOptions) => (
  display: string,
): string | null => {
  const exactMatch = options.getByDisplay(display);

  if (exactMatch) {
    return exactMatch.name;
  }

  const nameMatch = options.getByName(snakeCase(display.trim()).toUpperCase());

  if (nameMatch) {
    return nameMatch.name;
  }

  return null;
};

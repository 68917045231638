import React from 'react';
import { Icon } from '@tabler/icons-react';
import classNames from 'classnames';

type OnboardingChoiceItemProps = {
  checked: boolean;
  ChoiceIcon: Icon;
  title: string;
  subtitle?: string;
};

const OnboardingChoiceItem = ({
  checked,
  ChoiceIcon,
  title,
  subtitle,
}: OnboardingChoiceItemProps) => {
  return (
    <div
      className={classNames(
        'w-full flex text-sm items-center p-8 leading-5 font-medium rounded-lg border-2 border-transparent focus:outline-none transition duration-150 ease-in-out bg-gray-100 hover:bg-gray-200 cursor-pointer',
        {
          'bg-gray-200 ring ring-pink-300': checked,
        },
      )}
    >
      <ChoiceIcon size={32} className="flex-shrink-0 mr-4 opacity-75" />
      <div className="flex flex-col ">
        <span className="font-medium text-lg">{title}</span>
        {subtitle && (
          <span className="font-medium text-gray-500 text-base">
            {subtitle}
          </span>
        )}
      </div>
    </div>
  );
};

export default OnboardingChoiceItem;

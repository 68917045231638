import React, { useCallback, useState } from 'react';
import { IconPencilPlus } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { AutoSizedTextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType, DataTypeArray } from '@noloco/core/src/models/DataTypes';
import { BaseRecord } from '@noloco/core/src/models/Record';
import {
  RECOMMENDED_DATA_TYPE_ADD_FIELD,
  RECOMMENDED_DATA_TYPE_CHANGE_FIELD,
  RECOMMENDED_DATA_TYPE_RENAME,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType } from '@noloco/core/src/utils/options';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import EditRecommendedFieldModal from './recommendations/EditRecommendedFieldModal';
import FieldRow from './visualize/FieldRow';

const LANG_KEY = 'newProject.recommendations.dataTypes';

export type RecommendedDataTypeModel = DataType & {
  description?: string;
  sample?: BaseRecord;
};

type Props = {
  dataType: RecommendedDataTypeModel;
  dataTypes: DataTypeArray<RecommendedDataTypeModel>;
  updateDataType: (dataType: any) => void;
};

const RecommendedDataType = ({
  dataType,
  dataTypes,
  updateDataType,
}: Props) => {
  const [addField, setAddField] = useState(false);
  const [editField, setEditField] = useState<number | null>(null);

  const onAddField = useCallback(
    (field) => {
      trackEvent(RECOMMENDED_DATA_TYPE_ADD_FIELD, 'dataType', dataType.name, {
        fieldName: field.name,
      });

      updateDataType(set(['fields'], [...dataType.fields, field], dataType));
    },
    [dataType, updateDataType],
  );

  const updateField = useCallback(
    (index: number) => (field: any) => {
      trackEvent(
        RECOMMENDED_DATA_TYPE_CHANGE_FIELD,
        'dataType',
        dataType.name,
        {
          fieldName: field.name,
        },
      );

      updateDataType(set(['fields', index], field, dataType));
    },
    [dataType, updateDataType],
  );

  const getValueForField = useCallback(
    (field: DataField) => {
      if (field.relationship || field.relatedField) {
        const relatedDataType = dataTypes.getByName(field.type);
        if (!relatedDataType) {
          return undefined;
        }

        const relatedRecord = relatedDataType.sample;
        if (!relatedRecord) {
          return undefined;
        }

        if (isMultiField(field)) {
          return {
            edges: [{ node: relatedRecord }],
          };
        }

        return relatedRecord;
      }

      return get(dataType, ['sample', field.name]);
    },
    [dataType, dataTypes],
  );

  const onRename = useCallback(
    ({ target: { value } }: any) => {
      trackEvent(RECOMMENDED_DATA_TYPE_RENAME, 'dataType', dataType.name, {
        newName: value,
      });

      updateDataType(set(['display'], value, dataType));
    },
    [dataType, updateDataType],
  );

  return (
    <div className="flex flex-col p-2 w-1/3">
      <AutoSizedTextInput
        className="font-medium mb-2"
        inline={true}
        minWidth={300}
        onChange={onRename}
        p={2}
        surface={LIGHT}
        value={dataType.display}
      />
      <p className="text-sm text-gray-600">{dataType.description}</p>
      <div className="flex flex-col border rounded-lg bg-white w-full mt-4 p-1">
        {dataType.fields.map((field: any, index: number) => (
          <div
            className="relative flex items-center group px-2 text-xs h-8 hover:bg-gray-50 rounded-lg"
            key={field.name}
            onClick={
              !field.relationship && !isOptionType(field.type)
                ? () => setEditField(index)
                : () => {}
            }
          >
            <FieldRow
              dataTypes={dataTypes}
              field={field}
              value={getValueForField(field)}
            />
          </div>
        ))}
      </div>
      <div
        className="flex text-xs mr-auto text-gray-500 items-center min-h-10 p-2 mt-4 mb-2 font-medium rounded-lg border-2 border-transparent focus:outline-none transition duration-150 ease-in-out bg-white hover:bg-pink-200 cursor-pointer"
        onClick={() => setAddField(true)}
      >
        <IconPencilPlus className="w-5 mr-2" />
        <span>{getText(LANG_KEY, 'fields.add')}</span>
      </div>
      {addField && (
        <EditRecommendedFieldModal
          dataType={dataType}
          onClose={() => setAddField(false)}
          onSaveField={onAddField}
        />
      )}
      {editField !== null && (
        <EditRecommendedFieldModal
          dataType={dataType}
          existingField={dataType.fields[editField]}
          onClose={() => setEditField(null)}
          onSaveField={updateField(editField)}
        />
      )}
    </div>
  );
};

export default RecommendedDataType;

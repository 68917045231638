import React, { forwardRef, useCallback, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { CountryCode, PhoneNumber } from '../../models';
import {
  failedPasswordChecks,
  isEmailValid,
  isPhoneNumberValid,
  validationChecks,
} from '../../utils';
import {
  getAreaCodeFromCountry,
  getCountryFromTimeZone,
} from '../input/PhoneNumberInput';
import CardRegister from './CardRegister';
import SimpleRegister from './SimpleRegister';
import SplitRegister from './SplitRegister';
import { CARD, SIMPLE, SPLIT } from './authLayoutTypes';
import passwordValidationTypes from './passwordValidationTypes';

const registerLayoutTypeMap = {
  [SIMPLE]: SimpleRegister,
  [CARD]: CardRegister,
  [SPLIT]: SplitRegister,
};

const isFormValid = (
  email: string,
  hideEmail: boolean,
  password: string,
  confirmPassword: string,
  phoneNumber: PhoneNumber,
  showPhoneNumber: boolean,
) =>
  (hideEmail || isEmailValid(email)) &&
  failedPasswordChecks(password).length === 0 &&
  password === confirmPassword &&
  (!showPhoneNumber || !phoneNumber.number || isPhoneNumberValid(phoneNumber));

const getDefaultErrorMessage = (errorTexts: any, value: any) => {
  if (!errorTexts) {
    return null;
  }

  if (!value || value.length === 0) {
    return errorTexts.empty;
  }
};

const getSimpleErrorMessage = (errorTexts: any, fieldName: any, value: any) =>
  getDefaultErrorMessage(errorTexts, value) || errorTexts[fieldName].invalid;

const getPasswordErrorMessage = (errorTexts: any, password: any) => {
  const errorType = passwordValidationTypes.find(
    (checkName) => !validationChecks[checkName](password),
  );

  if (errorType && errorTexts.password[errorType]) {
    return errorTexts.password[errorType] || errorTexts.password.invalid;
  }

  return null;
};

type RegisterProps = {
  errors?: (string | React.ReactNode)[];
  onSubmit?: (...args: any[]) => any;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  buttonText: stringOrNode;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  emailLabel: stringOrNode;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  phoneNumberLabel: stringOrNode;
  footer?: React.ReactNode;
  logoUrl: string;
  hideEmail?: boolean;
  showPhoneNumber?: boolean;
  initialEmail?: string;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  passwordLabel: stringOrNode;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  registerText?: stringOrNode;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  rememberLabel: stringOrNode;
  // @ts-expect-error TS(2749): 'stringOrNode' refers to a value, but is being use... Remove this comment to see the full error message
  titleText: stringOrNode;
  type?: any; // TODO: PropTypes.oneOf(authLayoutTypes)
  splitImageUrl: string;
  socialLogins?: React.ReactNode;
};

const Register = forwardRef<any, RegisterProps>(
  (
    {
      children,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Regist... Remove this comment to see the full error message
      disabled,
      errors,
      logoUrl,
      emailLabel,
      phoneNumberLabel,
      footer,
      // @ts-expect-error TS(2339): Property 'confirmPasswordLabel' does not exist on ... Remove this comment to see the full error message
      confirmPasswordLabel,
      hideEmail = false,
      showPhoneNumber = false,
      initialEmail,
      onSubmit,
      buttonText,
      passwordLabel,
      // @ts-expect-error TS(2339): Property 'loginText' does not exist on type 'Regis... Remove this comment to see the full error message
      loginText,
      splitImageUrl,
      titleText,
      type,
      // @ts-expect-error TS(2339): Property 'errorTexts' does not exist on type 'Regi... Remove this comment to see the full error message
      errorTexts,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Registe... Remove this comment to see the full error message
      surface,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Registe... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error TS(2339): Property 'splitChildren' does not exist on type 'R... Remove this comment to see the full error message
      splitChildren,
      socialLogins,
    },
    ref,
  ) => {
    const [showErrors, setShowErrors] = useState(false);
    const [email, setEmail] = useState<string>(initialEmail || '');
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
      country: getCountryFromTimeZone(),
      number: '',
    });
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const emailErrorMessage =
      showErrors &&
      !isEmailValid(email) &&
      getSimpleErrorMessage(errorTexts, 'email', email);
    const phoneNumberErrorMessage =
      showErrors &&
      showPhoneNumber &&
      !isPhoneNumberValid(phoneNumber) &&
      getSimpleErrorMessage(errorTexts, 'phoneNumber', phoneNumber);
    const passwordErrorMessage =
      showErrors && getPasswordErrorMessage(errorTexts, password);
    const confirmPasswordErrorMessage =
      showErrors &&
      password !== confirmPassword &&
      getSimpleErrorMessage(errorTexts, 'confirmPassword', confirmPassword);

    const handleOnSubmit = useCallback(
      (event) => {
        event.preventDefault();
        setShowErrors(false);

        if (
          isFormValid(
            email,
            hideEmail,
            password,
            confirmPassword,
            phoneNumber,
            showPhoneNumber,
          )
        ) {
          // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onSubmit({
            email,
            password,
            confirmPassword,
            phoneNumber: `+${getAreaCodeFromCountry(
              phoneNumber.country as CountryCode,
            )} ${phoneNumber.number}`,
          });
        } else {
          setShowErrors(true);
        }
      },
      [
        onSubmit,
        email,
        hideEmail,
        password,
        confirmPassword,
        phoneNumber,
        showPhoneNumber,
      ],
    );

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const RegisterLayout = registerLayoutTypeMap[type];

    if (!RegisterLayout) {
      return null;
    }

    return (
      <div
        className="flex flex-col items-center justify-center w-full min-h-screen bg-gray-100 overflow-hidden"
        ref={ref}
        onClick={onClick}
      >
        <RegisterLayout
          disabled={disabled}
          email={email}
          phoneNumber={phoneNumber}
          errors={errors}
          footer={footer}
          setEmail={setEmail}
          setPhoneNumber={setPhoneNumber}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          logoUrl={logoUrl}
          hideEmail={hideEmail}
          showPhoneNumber={showPhoneNumber}
          emailLabel={emailLabel}
          phoneNumberLabel={phoneNumberLabel}
          buttonText={buttonText}
          confirmPasswordLabel={confirmPasswordLabel}
          passwordLabel={passwordLabel}
          loginText={loginText}
          splitImageUrl={splitImageUrl}
          titleText={titleText}
          onSubmit={handleOnSubmit}
          emailErrorMessage={emailErrorMessage}
          phoneNumberErrorMessage={phoneNumberErrorMessage}
          passwordErrorMessage={passwordErrorMessage}
          confirmPasswordErrorMessage={confirmPasswordErrorMessage}
          surface={surface}
          socialLogins={socialLogins}
          splitChildren={splitChildren}
        >
          {children}
        </RegisterLayout>
        {type !== SPLIT && footer}
      </div>
    );
  },
);

Register.defaultProps = {
  onSubmit: () => null,
  type: SIMPLE,
  initialEmail: '',
  // @ts-expect-error TS(2322): Type '{ onSubmit: () => null; type: string; initia... Remove this comment to see the full error message
  errorTexts: {
    empty: 'This field can not be blank',
    email: {
      invalid: 'That is not a valid email',
    },
    password: {
      invalid: 'Your password is not strong enough',
      tooShort: 'Your password must be at least 8 characters long',
      tooLong: 'Your password must be no more than 256 characters long',
      numbers: 'Your password must include at least one number',
      case:
        'Your password must include a mix of uppercase and lowercase letters',
      symbol:
        'Your password must include a special character such as ($, %, *, @)',
    },
    confirmPassword: {
      invalid: 'Your passwords do not match',
    },
  },
  hideEmail: false,
  showPhoneNumber: false,
};

export default withTheme(Register);

import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Register as RegisterLayout, isEmailValid } from '@noloco/components';
import SplitLayout from '@noloco/components/src/components/auth/SplitLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import EmailInput from '@noloco/components/src/components/auth/inputs/EmailInput';
import FormField from '@noloco/components/src/components/form/FormField';
import { LIGHT } from '@noloco/components/src/constants/surface';
import SocialLogin from '@noloco/core/src/components/SocialLogin';
import { GOOGLE_CLIENT_ID } from '@noloco/core/src/constants/auth';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { IS_PRODUCTION } from '../constants/env';
import logoBlack from '../img/infinity-logo-black.svg';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import SidePanel from './SidePanel';

const Register = ({ history }: any) => {
  const {
    query: { email: queryEmail },
  } = useRouter();
  useTrackDashboardPage(PageTypes.REGISTER);

  const [emailValue, setEmailValue] = useState<string>(queryEmail ?? '');
  const [emailCaptured, setEmailCaptured] = useState<boolean>(
    emailValue !== '',
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { register } = useDashboardAuth();

  const handleChange = useCallback(
    (email: string) => {
      if (IS_PRODUCTION && email.toLowerCase().includes('@noloco.io')) {
        setErrors([getText('auth.register.validation.email.noloco')]);
      }
      setEmailValue(email);
    },
    [setErrors, setEmailValue],
  );

  const handleRegister = ({
    email,
    password,
    confirmPassword,
    phoneNumber,
  }: {
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
  }) => {
    try {
      register(
        email,
        password,
        confirmPassword,
        { firstName, lastName },
        phoneNumber,
      )
        .then((registeredUser) => {
          window.growsumo.data.name = `${firstName} ${lastName}`;
          window.growsumo.data.email = email;
          window.growsumo.data.customer_key = registeredUser.team.plan.id;

          window.growsumo.createSignup();
        })
        .then(() => {
          history.push(`/onboarding?step=0`);
        })
        .catch((error: any) => {
          if (error.graphQLErrors) {
            setErrors(error.graphQLErrors.map((er: any) => er.message));
          } else {
            setErrors(['Something went wrong, please try again']);
          }

          console.warn('ERROR', error);
        });
    } catch (error) {
      setErrors([(error as any).message]);
    }
  };

  const onCaptureEmail = useCallback(() => {
    if (emailValue && isEmailValid(emailValue)) {
      setEmailCaptured(true);
    } else {
      setErrors([getText('auth.register.validation.email.invalid')]);
    }
  }, [emailValue]);

  if (!emailCaptured) {
    return (
      <SplitLayout
        errors={errors}
        titleText={getText('auth.register.title')}
        subTitleText={
          <span>
            <span>{getText('auth.register.or')}</span>
            <Link
              to="/login"
              className="ml-1 font-medium text-pink-600 hover:text-pink-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              {getText('auth.register.loginLink')}
            </Link>
          </span>
        }
        logoUrl={logoBlack}
        splitChildren={<SidePanel />}
        onSubmit={onCaptureEmail}
      >
        <EmailInput
          onChange={handleChange}
          value={emailValue}
          surface={LIGHT}
          errorMessage={''}
          label={getText('auth.fields.workEmail')}
        />
        <SubmitButton disabled={errors.length > 0}>
          {getText('auth.register.button')}
        </SubmitButton>
        <SocialLogin
          clientId={GOOGLE_CLIENT_ID}
          defaultButtonWidth={416}
          loginPath="/onboarding?step=0"
          setErrors={setErrors}
          preventNonWorkspaceAccounts={true}
        />
      </SplitLayout>
    );
  }

  return (
    <RegisterLayout
      buttonText={getText('auth.register.next')}
      errors={errors}
      hideEmail={true}
      forgotText={getText('auth.register.forgot')}
      logoUrl={logoBlack}
      onSubmit={handleRegister}
      initialEmail={emailValue}
      titleText={getText('auth.register.title')}
      loginText={getText('auth.register.moreDetails')}
      emailLabel={getText('auth.fields.workEmail')}
      phoneNumberLabel={getText('auth.fields.phoneNumber')}
      passwordLabel={getText('auth.fields.password')}
      confirmPasswordLabel={getText('auth.fields.confirmPassword')}
      rememberLabel={getText('auth.fields.rememberMe')}
      type="SPLIT"
      errorTexts={{
        empty: getText('auth.register.validation.empty'),
        email: {
          invalid: getText('auth.register.validation.email.invalid'),
          noloco: getText('auth.register.validation.email.noloco'),
        },
        phoneNumber: {
          invalid: getText('auth.register.validation.phoneNumber.invalid'),
        },
        password: {
          invalid: getText('auth.register.validation.password.invalid'),
          tooShort: getText('auth.register.validation.password.tooShort'),
          tooLong: getText('auth.register.validation.password.tooLong'),
          numbers: getText('auth.register.validation.password.numbers'),
          case: getText('auth.register.validation.password.case'),
          symbol: getText('auth.register.validation.password.symbol'),
        },
        confirmPassword: {
          invalid: getText('auth.register.validation.confirmPassword.invalid'),
        },
      }}
      surface={LIGHT}
      splitChildren={<SidePanel />}
    >
      <>
        <EmailInput
          className="hidden"
          onChange={() => null}
          value={emailValue}
          surface={LIGHT}
          errorMessage=""
          label=""
        />
        <div className="flex mb-4 space-x-2">
          <div className="w-1/2">
            <FormField
              aria-label="given-name"
              autoComplete="given-name"
              name="given-name"
              type="text"
              onChange={({ target: { value } }: any) => setFirstName(value)}
              required
              errorType="below-solid"
              label={getText('auth.fields.firstName')}
              placeholder=""
              value={firstName}
              surface={LIGHT}
            />
          </div>
          <div className="w-1/2">
            <FormField
              aria-label="family-name"
              autoComplete="family-name"
              name="family-name"
              type="text"
              onChange={({ target: { value } }: any) => setLastName(value)}
              required
              errorType="below-solid"
              label={getText('auth.fields.lastName')}
              placeholder=""
              value={lastName}
              surface={LIGHT}
            />
          </div>
        </div>
      </>
    </RegisterLayout>
  );
};

export default Register;

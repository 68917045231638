import React, { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
  IconFlag,
  IconInfoCircle,
  TablerIconsProps,
} from '@tabler/icons-react';
import classNames from 'classnames';
import {
  DANGER,
  INFO,
  PRIMARY,
  SECONDARY,
  Variant,
  WARNING,
} from '../../constants/variants';
import { Theme } from '../../models';
import { getColorShade } from '../../utils';

type VariantWithSuccess = Variant | 'success';

export const variantIcon = (type: VariantWithSuccess) =>
  ({
    [SECONDARY]: IconInfoCircle,
    [DANGER]: IconAlertOctagon,
    [WARNING]: IconAlertTriangle,
    [INFO]: IconInfoCircle,
    [PRIMARY]: IconFlag,
    success: IconCircleCheck,
  }[type] || IconCircleCheck);

type Props = {
  className?: string;
  children?: any;
  icon?: ((props: TablerIconsProps) => JSX.Element) | null;
  subtitle?: string | any;
  title?: string | any;
  type: VariantWithSuccess;
  theme: Theme;
};

const Notice = forwardRef(
  ({ className, children, icon, subtitle, title, type, theme }: Props, ref) => {
    const color = useMemo(
      () =>
        ({
          [SECONDARY]: 'gray',
          [DANGER]: theme.brandColors.danger,
          [WARNING]: theme.brandColors.warning,
          [INFO]: 'blue',
          [PRIMARY]: theme.brandColors.primary,
          success: theme.brandColors.success,
        }[type] || theme.brandColors.primary),
      [type, theme.brandColors],
    );

    const Icon = icon ?? variantIcon(type);

    return (
      <div
        className={classNames(
          className,
          'flex py-2 rounded-lg p-3',
          `bg-${getColorShade(color, 100)}`,
          `dark:bg-${getColorShade(color, 700)}`,
          {
            'items-center': !subtitle || !title,
          },
        )}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
      >
        <div
          className={classNames(
            'flex flex-shrink-0',
            `text-${getColorShade(color, 500)}`,
            `dark:text-${getColorShade(color, 100)}`,
            {
              'mt-1': subtitle && title,
            },
          )}
        >
          <Icon size={22} />
        </div>
        <div className="flex flex-col ml-2 space-y-1">
          {title && (
            <h2
              className={classNames(
                'text-sm font-medium',
                `text-${getColorShade(color, 800)}`,
                `dark:text-${getColorShade(color, 200)}`,
              )}
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <p
              className={classNames(
                'text-xs',
                `text-${getColorShade(color, 700)}`,
                `dark:text-${getColorShade(color, 200)}`,
              )}
            >
              {subtitle}
            </p>
          )}
        </div>
        {children}
      </div>
    );
  },
);

export default withTheme(Notice) as React.FC<Omit<Props, 'theme'>>;

import React, { useMemo } from 'react';
import {
  IconChecks,
  IconExternalLink,
  IconWashDrycleanOff,
} from '@tabler/icons-react';
import { RadioGroup } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import OnboardingChoiceItem from './OnboardingChoiceItem';

const LANG_KEY = 'appAuth.onboarding.questions.connectWithExpert';

type OnboardingExpertQuestionProps = {
  onChange: (appType: boolean) => void;
  value: boolean | undefined;
};

const OnboardingExpertQuestion = ({
  onChange,
  value,
}: OnboardingExpertQuestionProps) => {
  const options = useMemo(
    () => [
      {
        value: true,
        label: ({ checked }: { checked: boolean }) => (
          <OnboardingChoiceItem
            checked={checked}
            title={getText(LANG_KEY, 'options.true')}
            ChoiceIcon={IconChecks}
          />
        ),
      },
      {
        value: false,
        label: ({ checked }: { checked: boolean }) => (
          <OnboardingChoiceItem
            checked={checked}
            title={getText(LANG_KEY, 'options.false')}
            ChoiceIcon={IconWashDrycleanOff}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div className="flex flex-col">
      <a
        className="text-pink-500 text-sm mb-4 hover:text-pink-600 group"
        href="https://noloco.io/expert?ref=onboarding"
        target="_blank"
        rel="noreferrer"
      >
        {getText(LANG_KEY, 'expertsLeanMore')}
        <IconExternalLink
          size={14}
          className="ml-2 inline-block opacity-75 group-hover:opacity-100"
        />
      </a>
      <RadioGroup
        className="grid grid-cols-1 gap-3"
        onChange={onChange}
        value={value}
        options={options}
      />
    </div>
  );
};

export default OnboardingExpertQuestion;

import { useCallback, useMemo } from 'react';
import { AlertType } from 'react-alert';
import { useSelector } from 'react-redux';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import {
  ActionButtonExecutionType,
  MODAL,
  ONE_CLICK,
} from '../../../constants/actionButtons';
import { CONFETTI } from '../../../constants/actionNotification';
import { DataType } from '../../../models/DataTypes';
import { ActionButton } from '../../../models/Element';
import { BaseRecord } from '../../../models/Record';
import { projectDataSelector } from '../../../selectors/projectSelectors';
import useActionSteps from '../../../utils/hooks/useActionSteps';
import useActionsWithoutInputs from '../../../utils/hooks/useActionsWithoutInputs';
import { useAlert } from '../../../utils/hooks/useAlerts';
import ModalActionButton from './ModalActionButton';
import OneClickActionButton from './OneClickActionButton';

export type QueuedAction = {
  executionId: string;
  action: ActionButton;
  executionType: ActionButtonExecutionType;
  dataType: DataType;
  record: BaseRecord | undefined;
  scope: Record<string, any>;
  onDeleteRecord: (
    record: BaseRecord,
    onNext: (actionResult?: any) => void,
  ) => void;
};

const ACTION_BUTTONS = {
  [MODAL]: ModalActionButton,
  [ONE_CLICK]: OneClickActionButton,
};

const ActionButtonExecutionWrapper = ({
  actionItem,
  finishAction,
  startConfetti,
}: {
  actionItem: QueuedAction;
  finishAction: (executionId: string) => void;
  startConfetti: (executionId: string) => void;
}) => {
  const showAlert = useAlert(PRIMARY);
  const project = useSelector(projectDataSelector);
  const {
    action: actionButton,
    executionId,
    executionType,
    dataType,
    record,
    scope: recordScope,
    onDeleteRecord,
  } = actionItem;

  const handleOnFinish = useCallback(
    (cancelled?: boolean) => {
      if (!cancelled && actionButton.notification?.enabled) {
        if (
          actionButton.notification.type !== CONFETTI &&
          actionButton.notification.text
        ) {
          showAlert(actionButton.notification.text, undefined, {
            type: (actionButton.notification.type || PRIMARY) as AlertType,
          });
        }

        if (actionButton.notification.type === CONFETTI) {
          return startConfetti(executionId);
        }
      }
      finishAction(executionId);
    },
    [
      actionButton.notification,
      executionId,
      finishAction,
      showAlert,
      startConfetti,
    ],
  );

  const { action, actionIndex, onNext } = useActionSteps(
    actionButton,
    dataType,
    project,
    handleOnFinish,
  );

  const {
    onCopy,
    onDelete,
    onNavigate,
    onRunWorkflow,
  } = useActionsWithoutInputs(
    recordScope,
    dataType,
    onDeleteRecord,
    onNext,
    handleOnFinish,
    project,
    record,
  );

  const ActionButtonExecution = useMemo(() => ACTION_BUTTONS[executionType], [
    executionType,
  ]);

  if (!action) {
    return null;
  }

  return (
    <ActionButtonExecution
      action={action}
      actionButton={actionButton}
      actionIndex={actionIndex}
      dataType={dataType}
      onCopy={onCopy}
      onDelete={onDelete}
      onFinish={handleOnFinish}
      onNavigate={onNavigate}
      onNext={onNext}
      onRunWorkflow={onRunWorkflow}
      project={project}
      record={record}
      recordScope={recordScope}
      visibilityRulesScope={recordScope}
    />
  );
};

export default ActionButtonExecutionWrapper;

import React, { useMemo } from 'react';
import { IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { FormField, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

type Props = {
  disabled?: boolean;
  domains: string[];
  onRemoveDomain: (index: number) => () => void;
  onUpdateDomain: (index: number, value: string) => void;
  surface: Surface;
};

const ExternalUsersAddonConfiguration = ({
  disabled,
  domains,
  onRemoveDomain,
  onUpdateDomain,
  surface,
}: Props) => {
  const debouncedOnUpdateDomain = useMemo(() => debounce(onUpdateDomain, 800), [
    onUpdateDomain,
  ]);

  return (
    <>
      {domains.map((domain: string | null, index: number) => (
        <div key={index} className="flex mb-4 items-center w-full">
          <FormField
            className="w-full"
            disabled={disabled || index === 0}
            placeholder="example.com"
            value={domain}
            onChange={({ target: { value } }: any) =>
              debouncedOnUpdateDomain(index, value)
            }
            readOnly={disabled || index === 0}
            surface={surface}
          />
          <button
            className={classNames('p-2 ml-2', {
              'text-gray-300 hover:text-white': index > 0 && surface === DARK,
              'text-gray-600': index === 0 && surface === DARK,
              'text-gray-700 hover:text-black': index > 0 && surface === LIGHT,
              'text-gray-400': index === 0 && surface === LIGHT,
            })}
            disabled={disabled || index === 0}
            onClick={onRemoveDomain(index)}
          >
            <IconTrash size={16} />
          </button>
        </div>
      ))}
    </>
  );
};

export default ExternalUsersAddonConfiguration;

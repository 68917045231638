import React from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { DARK, LIGHT } from '../../constants/surface';
import Option from './Option';
import { findOption, safeValue } from './util';

const NON_BREAKING_SPACE = '\u00a0';

const formatPlaceholder = (placeholder: any) =>
  (placeholder && typeof placeholder === 'string'
    ? placeholder.trim()
    : placeholder) || NON_BREAKING_SPACE;

const SelectButton = ({
  placeholder,
  multiple,
  disabled,
  value,
  selectedValue,
  onChange,
  surface,
  options = [],
  coloredOptionType,
}: any) => {
  if (multiple) {
    if (!value || value.length === 0) {
      return (
        <span className="block truncate py-1">
          <span className="text-gray-400">
            {formatPlaceholder(placeholder)}
          </span>
        </span>
      );
    }

    const onRemoveValue = (index: any) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      onChange(
        value.filter((__: any, valueIndex: any) => valueIndex !== index),
      );
    };

    return (
      <span className="flex flex-wrap sm:mt-1">
        {value.map((valueItem: any, index: any) => {
          const bgColor = get(
            findOption(options, value, valueItem),
            'bg',
            null,
          );

          return (
            <div
              key={valueItem}
              className={classNames(
                'inline-flex items-center rounded m-0.5 max-w-full p-0.5',
                {
                  'bg-gray-500': surface === DARK && !coloredOptionType,
                  'bg-gray-200': surface === LIGHT && !coloredOptionType,
                },
              )}
            >
              <SelectButton
                placeholder={placeholder}
                multiple={false}
                value={valueItem}
                options={options}
                surface={surface}
              />
              {!disabled && (
                <button
                  onClick={onRemoveValue(index)}
                  className={classNames('group', {
                    'ml-2': !coloredOptionType,
                    [`bg-${bgColor} rounded h-4 z-10`]: coloredOptionType,
                    '-ml-1.5 pr-3': coloredOptionType && bgColor,
                  })}
                >
                  <IconX
                    className="opacity-50 group-hover:opacity-100"
                    size={14}
                  />
                </button>
              )}
            </div>
          );
        })}
      </span>
    );
  }

  const selectedOption = findOption(options, value, selectedValue);

  return (
    <span
      className={classNames('block truncate max-w-full', {
        'text-gray-600': surface === LIGHT,
        'text-gray-100': surface === DARK,
      })}
    >
      {(selectedOption && (
        // @ts-expect-error TS(2322): Type '{ option: any; useButtonLabel: boolean; }' i... Remove this comment to see the full error message
        <Option option={selectedOption} useButtonLabel={true} />
      )) ||
        (value && safeValue(value)) ||
        (selectedValue && safeValue(selectedValue)) || (
          <span className="text-gray-400 max-w-full">
            {formatPlaceholder(placeholder)}
          </span>
        )}
    </span>
  );
};

export default SelectButton;

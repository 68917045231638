import { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CREATE } from '../../constants/actionTypes';
import { FIELD_LEVEL_PERMISSIONS } from '../../constants/features';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import { User } from '../../models/User';
import { FormFieldConfig } from '../../models/View';
import { filterDefaultFields } from '../defaultFields';
import { getFieldKey } from '../fields';
import { mapFieldsWithPermissionsAndConfig } from '../permissions';
import { getViewById, getViewForDataType } from '../urls';
import useAuthWrapper from './useAuthWrapper';
import useRouter from './useRouter';

export const getOrBuildFormFieldsConfigForType = (
  relatedType: DataType,
  project: Project,
  user: User,
  fieldPermissionsEnabled: boolean,
  newRecordFormId?: string | undefined,
): { fields?: FormFieldConfig[]; title?: string } => {
  if (!relatedType) {
    return {};
  }

  const view =
    (newRecordFormId && getViewById(newRecordFormId, project)) ??
    getViewForDataType(relatedType.name, project);
  const newFormConfig: any[] = get(view, 'props.new.fields', []);
  const newFormTitle = get(view, 'props.new.title');

  if (view && newFormConfig.length > 0) {
    return {
      fields: newFormConfig.map((fieldConfig: any) => ({
        ...fieldConfig,
        field: fieldConfig.name,
      })),
      title: newFormTitle,
    };
  }

  return {
    fields: mapFieldsWithPermissionsAndConfig<FormFieldConfig>(
      relatedType.fields
        ?.filter((f: any) => filterDefaultFields(f) && !f.lookup && !f.rollup)
        .map((f: any) => ({
          name: f.name,
          label: upperFirst(f.display),
        })),
      relatedType,
      user,
      project.dataTypes,
      fieldPermissionsEnabled,
    )
      .filter(({ permissions }) => permissions.create)
      .map(({ config }) => ({ ...config, field: config.name })),
  };
};

const getNewRecordFields = (
  field: DataField,
  project: Project,
  user: User,
  fieldPermissionsEnabled: boolean,
  newRecordFormId: string | undefined,
) => {
  const dataType = project.dataTypes.getByName(field.type);
  if (!dataType) {
    return { fields: [] };
  }

  return getOrBuildFormFieldsConfigForType(
    dataType,
    project,
    user,
    fieldPermissionsEnabled,
    newRecordFormId,
  );
};

const useFormFields = (
  fields: any,
  dataType: any,
  project: any,
  { mutationType = CREATE, useUrlValues = true } = {},
) => {
  const { user } = useAuthWrapper();
  const { query } = useRouter();

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const fieldConfigs = useMemo(
    () =>
      mapFieldsWithPermissionsAndConfig<FormFieldConfig>(
        fields || [],
        dataType,
        user as User,
        project.dataTypes,
        fieldPermissionsEnabled,
      ),
    [dataType, fieldPermissionsEnabled, fields, project.dataTypes, user],
  );

  const formFields = useMemo(() => {
    if (fieldConfigs.length > 0) {
      return fieldConfigs.map(({ config, field, permissions }) => {
        const fieldConfig = config;

        const fieldKey = getFieldKey(field);

        (fieldConfig as any).value = (fieldConfig as any).hidden
          ? (fieldConfig as any).value
          : undefined;

        const queryParamField = query[fieldKey];
        if (queryParamField && useUrlValues) {
          (fieldConfig as any).value = queryParamField;
          (fieldConfig as any).hidden = (config as any).hidden;
          (fieldConfig as any).disabled = true;
          (fieldConfig as any).prefilled = true;
        }

        const {
          fields: newRecordFields,
          title: newRecordTitle,
        }: any = (fieldConfig as any).allowNewRecords
          ? getNewRecordFields(
              field,
              project,
              user as User,
              fieldPermissionsEnabled,
              fieldConfig.newRecordForm,
            )
          : {};

        return {
          field: field.name,
          className: classNames({
            'col-span-12': !config.width || config.width === 12,
            'col-span-8 md:col-span-12': config.width === 8,
            'col-span-6 md:col-span-12': config.width === 6,
            'col-span-4 md:col-span-12': config.width === 4,
          }),
          permissions,
          ...fieldConfig,
          label: (config as any).label,
          newRecordFields,
          newRecordTitle,
          readOnly:
            field.readOnly ||
            (config as any).readOnly ||
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            !permissions[mutationType.toLowerCase()],
        };
      });
    } else {
      return [];
    }
  }, [
    fieldConfigs,
    query,
    useUrlValues,
    project,
    user,
    fieldPermissionsEnabled,
    mutationType,
  ]);

  return formFields;
};

export default useFormFields;

import React, { memo, useCallback } from 'react';
import { CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { User } from '../models/User';
import { useAuth } from '../utils/hooks/useAuth';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import GoogleLoginButton from './GoogleLoginButton';

type SocialLoginProps = {
  clientId: string;
  defaultButtonWidth?: number;
  hideOr?: boolean;
  loginPath?: string;
  onLogin?: (user: User) => void;
  setErrors: (errors: any) => void;
  preventNonWorkspaceAccounts?: boolean;
};

const SocialLogin = memo(
  ({
    clientId,
    defaultButtonWidth,
    hideOr,
    loginPath,
    onLogin,
    setErrors,
    preventNonWorkspaceAccounts = false,
  }: SocialLoginProps) => {
    const { push } = useRouter();
    const { googleLogin } = useAuth();

    const handleGoogleLogin = useCallback(
      (credentialResponse: CredentialResponse) =>
        googleLogin(credentialResponse)
          .then((user: User) => {
            if (user && onLogin) {
              onLogin(user);
            }
            if (loginPath) {
              push(loginPath);
            }
          })
          .catch((error: any) => {
            if (error.graphQLErrors) {
              setErrors(error.graphQLErrors.map((er: any) => er.message));
            } else {
              setErrors([
                'Your email or password is incorrect, please try again',
              ]);
            }
            console.warn('ERROR', error);
          }),
      [googleLogin, onLogin, loginPath, push, setErrors],
    );

    return (
      <div className="mt-6 flex flex-col">
        {!hideOr && (
          <div className="flex items-center mb-6">
            <span className="h-px w-full bg-gray-300" />
            <span className="uppercase mx-4 text-center text-sm text-gray-500">
              {getText('auth.login.or')}
            </span>
            <span className="h-px w-full bg-gray-300" />
          </div>
        )}
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLoginButton
            defaultButtonWidth={defaultButtonWidth}
            onSuccess={handleGoogleLogin}
            preventNonWorkspaceAccounts={preventNonWorkspaceAccounts}
          />
        </GoogleOAuthProvider>
      </div>
    );
  },
);

(SocialLogin as any).defaultProps = {
  loginPath: '/',
};

export default SocialLogin;

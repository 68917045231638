import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { DEFAULT_MAX_RATING } from '../../constants/ratings';
import Star from '../../icons/Star';
import { Theme } from '../../models';
import { getColorShade } from '../../utils';

type RatingInputProps = {
  className?: string;
  disabled?: boolean;
  onChange: (value: number | null) => void;
  maxRating?: number;
  theme: Theme;
  value?: number;
};

const MAX_RATING = 10;

const RatingInput = ({
  className,
  disabled,
  onChange,
  maxRating = DEFAULT_MAX_RATING,
  theme,
  value = 0,
}: RatingInputProps) => {
  const cleanValue = useMemo(
    () => Math.max(Math.min(value, Math.min(maxRating, MAX_RATING)), 0),
    [maxRating, value],
  );

  const clearValue = useCallback(() => onChange(null), [onChange]);

  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined);

  const clearHoverValue = useCallback(() => setHoverValue(undefined), []);

  const primaryColor = theme.brandColors.primary;

  return (
    <RadioGroup
      className={classNames('flex items-center space-x-0.5 h-8', className)}
      disabled={disabled}
      value={cleanValue}
      onChange={onChange}
    >
      {[...Array(maxRating)].map((_, i) => (
        <RadioGroup.Option key={i} value={i + 1}>
          {() => {
            const filled = i + 1 <= (hoverValue ?? cleanValue);

            return (
              <span
                onMouseEnter={
                  !disabled ? () => setHoverValue(i + 1) : undefined
                }
                onMouseLeave={!disabled ? clearHoverValue : undefined}
                onClick={
                  !disabled && i + 1 === cleanValue ? clearValue : undefined
                }
              >
                <Star
                  className={classNames({
                    [`text-${getColorShade(primaryColor, 500)}`]:
                      hoverValue === undefined,
                    [`text-${getColorShade(primaryColor, 400)}`]:
                      hoverValue !== undefined,
                  })}
                  filled={filled}
                  size={18}
                />
              </span>
            );
          }}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default withTheme(RatingInput);

import React from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Tooltip } from '@noloco/components';
import {
  CLOSE_ONBOARDING_DATA_SOURCE,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'newProject';

const ConfirmClose = ({
  project,
  onboardingCompleted,
  step,
  steps,
  confirmClose,
  setConfirmClose,
  push,
}: any) => (
  <Tooltip
    content={
      <div className="flex items-center">
        <span className="mr-4">
          {onboardingCompleted
            ? getText(LANG_KEY, 'exit')
            : step === steps.COLLECTION ||
              (project && step === steps.DATA_SOURCE)
            ? getText(LANG_KEY, 'appAlreadyCreated')
            : getText(LANG_KEY, 'confirmClose')}
        </span>
        <button
          onClick={() => push('/')}
          className="p-1 opacity-50 hover:opacity-100"
        >
          <IconCheck size={28} />
        </button>
        <button
          onClick={() => setConfirmClose(false)}
          className="p-1 opacity-50 hover:opacity-100"
        >
          <IconX size={28} />
        </button>
      </div>
    }
    trigger="none"
    isOpen={confirmClose}
    placement="right"
    bg="white"
  >
    <button
      onClick={(e) => {
        e.preventDefault();
        setConfirmClose(true);
        trackEvent(CLOSE_ONBOARDING_DATA_SOURCE, 'step', step);
      }}
    >
      <IconX
        size={28}
        className="mr-2 cursor-pointer text-gray-400 hover:text-pink-500"
      />
    </button>
  </Tooltip>
);

export default ConfirmClose;

import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Tooltip } from '@noloco/components';
import { OBJECT } from '../../../constants/dataTypes';
import { DUE_DATE } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { DueDate } from '../../../models/Record';
import { formatDateValue } from '../../../utils/dates';
import { getText } from '../../../utils/lang';

type Props = {
  config: any;
  field: DataField;
  value: DueDate;
};

const DueDateCell = memo(({ config, field, value }: Props) => {
  const formattedStart = useMemo(() => {
    if (!value?.from) {
      return null;
    }

    return formatDateValue(value.from, field, config);
  }, [config, field, value]);

  const formattedEnd = useMemo(() => {
    if (!value?.to) {
      return null;
    }

    return formatDateValue(value.to, field, config);
  }, [config, field, value]);

  const formattedRange = useMemo(
    () =>
      formattedStart ? `${formattedStart} - ${formattedEnd}` : formattedEnd,
    [formattedEnd, formattedStart],
  );

  const status = useMemo(() => {
    if (!value) {
      return null;
    }

    if (value.overdue && !value.complete) {
      return 'overdue';
    } else if (value.overdue && value.complete) {
      return 'completeOverdue';
    } else if (!value.overdue && value.complete) {
      return 'complete';
    } else {
      return 'inProgress';
    }
  }, [value]);

  if (isNil(value?.to) || !status) {
    return null;
  }

  return (
    <span className="flex space-x-2">
      <Tooltip
        content={
          <div className="space-y-2">
            <div>
              {getText('data.types', OBJECT, DUE_DATE, 'status', status)}
            </div>
            {value.updatedAt && (
              <div className="flex space-x-1 text-gray-600">
                <span>
                  {getText('data.types', OBJECT, DUE_DATE, 'updatedAt.label')}
                </span>
                <span>{value.updatedAt}</span>
              </div>
            )}
          </div>
        }
      >
        <div
          className={classNames('w-2 h-2 rounded-full my-auto', {
            'bg-red-400': status === 'overdue',
            'bg-yellow-400': status === 'completeOverdue',
            'bg-green-600': status === 'complete',
            'bg-blue-400': status === 'inProgress',
          })}
        />
      </Tooltip>
      <span>{formattedRange}</span>
    </span>
  );
});

export default DueDateCell;

import React from 'react';
import { Button, Loader } from '@noloco/components';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import { getText } from '../../utils/lang';
import Icon from '../Icon';

type Props = {
  button: {
    icon?: {
      name: string;
      text?: string;
    };
    text?: string;
  };
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submitFormOnClick?: boolean;
  placeholder?: string;
};

const SectionButton = ({
  button,
  className,
  disabled,
  loading,
  onClick,
  placeholder,
  ...rest
}: Props) => {
  return (
    <Button
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      {...rest}
      variant={PRIMARY}
    >
      <div className="flex items-center">
        {!loading && button.icon && button.icon.name && (
          <Icon icon={button.icon} className="opacity-75 w-6 h-6 mr-2" />
        )}
        {loading && <Loader size="sm" className="mr-2" />}
        <span className="py-1 whitespace-nowrap">
          {button.text ||
            placeholder ||
            getText('elements.TITLE.buttons.placeholder')}
        </span>
      </div>
    </Button>
  );
};

export default SectionButton;

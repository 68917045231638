import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import { LG, MD, SM, XS } from '../../constants/tShirtSizes';
import { validationBorder } from '../../utils';
import ErrorText from '../form/ErrorText';
import { ROUNDED_LARGE } from '../input/inputStyles';
import SelectBase, { SelectBaseProps } from './SelectBase';
import SelectButton from './SelectButton';
import caretSizes from './caretSizes';

const isSet = (val: any) => val !== undefined;

/*
(ts-migrate) TODO: Migrate the remaining prop types
...RawSelectBase.propTypes
*/
type SelectInputProps = SelectBaseProps & {
  borderColor?: string;
  Button?: any;
  hasMore?: boolean;
  footer?: any;
  label?: string;
  loading?: boolean;
  validationError: string;
  value?: any;
  onChange?: any;
  onSearchChange?: any;
  onFetchMore?: any;
  onOpenChange?: any;
  placeholder?: string;
  searchable?: boolean;
  hideOptions?: boolean;
  showValidationErrorText?: boolean;
  text?: string;
  theme?: any;
  textCenter?: boolean;
};

const SelectInput = forwardRef<any, SelectInputProps>(
  (
    {
      bg,
      borderColor,
      listBg,
      contained,
      Button,
      className,
      direction,
      disabled,
      hasMore,
      hideDropdownIndicator,
      footer,
      label,
      loading,
      options,
      open,
      size,
      style,
      validationError,
      value,
      onChange,
      onSearchChange,
      onFetchMore,
      onOpenChange,
      multiple,
      placeholder,
      searchable,
      hideOptions,
      showValidationErrorText,
      surface,
      text,
      theme,
      usePortal,
      coloredOptionType,
      ...rest
    },
    ref,
  ) => {
    surface = surface ?? theme?.selectInput?.surface;

    return (
      <SelectBase
        bg={bg}
        borderColor={borderColor}
        contained={contained}
        className={className}
        direction={direction}
        disabled={disabled}
        footer={footer}
        hasMore={hasMore}
        hideDropdownIndicator={hideDropdownIndicator}
        listBg={listBg}
        label={label}
        loading={loading}
        open={open}
        onChange={onChange}
        onFetchMore={onFetchMore}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        multiple={multiple}
        options={options}
        ref={ref}
        searchable={searchable}
        hideOptions={hideOptions}
        size={size}
        surface={surface}
        style={style}
        text={text}
        value={value}
        usePortal={usePortal}
        coloredOptionType={coloredOptionType}
        {...rest}
      >
        {({ formattedValue, selectedValue, styles }: any) => (
          <>
            <Box
              as="div"
              className={classNames(
                'cursor-default relative text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 max-w-full min-h-8 pl-2 flex items-center',
                {
                  'text-sm': size === SM || size === MD,
                  'text-xs': size === XS,
                  'sm:min-h-8': size === XS || size === SM,
                  'sm:min-h-10': size === MD,
                  'sm:min-h-12': size === LG,
                  'cursor-not-allowed': disabled,
                },
                validationBorder(validationError, theme),
              )}
              {...styles}
            >
              {isSet(value) || isSet(selectedValue) ? (
                <Button
                  placeholder={placeholder}
                  selectedValue={selectedValue}
                  disabled={disabled}
                  multiple={multiple}
                  onChange={onChange}
                  options={options}
                  surface={surface}
                  value={formattedValue}
                  coloredOptionType={coloredOptionType}
                />
              ) : (
                <SelectButton
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                  surface={surface}
                  coloredOptionType={coloredOptionType}
                />
              )}
              {!hideDropdownIndicator && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <IconChevronDown size={caretSizes[size]} />
                </span>
              )}
            </Box>
            {validationError && showValidationErrorText && (
              <ErrorText className="text-left">{validationError}</ErrorText>
            )}
          </>
        )}
      </SelectBase>
    );
  },
);

SelectInput.defaultProps = {
  Button: SelectButton,
  contained: false,
  style: ROUNDED_LARGE,
  className: '',
  direction: 'right',
  hideDropdownIndicator: false,
  size: MD,
  multiple: false,
  placeholder: '',
  showValidationErrorText: true,
  textCenter: false,
  usePortal: true,
  coloredOptionType: false,
};

export default withTheme(SelectInput);

import classNames from 'classnames';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';

type RecordEmptyStateProps = {
  title?: string;
};

const RecordEmptyState = ({ title }: RecordEmptyStateProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <div
      className={classNames(
        'border rounded-lg shadow-md flex items-center justify-center p-8',
        {
          'bg-slate-800 border-slate-600': isDarkModeEnabled,
          'bg-white border-gray-200': !isDarkModeEnabled,
        },
      )}
    >
      <div className="text-center w-full text-slate-600 py-20 text-sm">
        {title ?? getText('elements.DETAILS.view.empty')}
      </div>
    </div>
  );
};

export default RecordEmptyState;

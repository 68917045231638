import React, { useCallback, useState } from 'react';
import Slider from 'react-input-slider';

type RangeInputProps = {
  activeColor: string;
  min: number;
  max: number;
  onChange: (value: number) => void;
  step: number;
  suffix?: string | undefined;
  value: number;
};

const RangeInput = ({
  activeColor = 'blue',
  min,
  max,
  onChange,
  step,
  suffix,
  value,
}: RangeInputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(
    ({ x }: { x: number }) => {
      setLocalValue(x);
      onChange(x);
    },
    [onChange],
  );

  return (
    <div className="flex items-center justify-center h-8 mx-2">
      {/* @ts-expect-error TS2786: 'Slider' cannot be used as a JSX component. */}
      <Slider
        axis="x"
        onChange={handleChange}
        styles={{
          active: { backgroundColor: activeColor },
          thumb: { width: 16, height: 16 },
        }}
        x={localValue}
        xmax={max}
        xmin={min}
        xstep={step}
      />
      <span className="ml-2 text-gray-400">
        {localValue}
        {suffix}
      </span>
    </div>
  );
};

export default RangeInput;

import React from 'react';
import { IconCash } from '@tabler/icons-react';
import isNil from 'lodash/isNil';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import { AccountPlan, FREE } from '@noloco/core/src/constants/accountPlans';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  activePlan: AccountPlan | undefined;
  billingPlan: BillingPlan;
};

const BillingCreditBox = ({ activePlan, billingPlan }: Props) => {
  const locale = useLocale();

  if (!billingPlan || isNil(billingPlan.balance) || billingPlan.balance >= 0) {
    return null;
  }

  return (
    <div className="flex mt-6 px-8 py-4 border items-center rounded-lg">
      <IconCash size={36} className="flex-shrink-0 mr-4 opacity-75" />
      <div className="flex flex-col">
        <h2 className="text-base font-medium">
          {getText(
            {
              credit: Math.abs(billingPlan.balance).toLocaleString(
                locale.code ?? 'en-US',
                {
                  style: 'currency',
                  currency: 'USD',
                  compactDisplay: 'short',
                  currencyDisplay: 'narrowSymbol',
                },
              ),
            },
            'billing.creditAlert.title',
          )}
        </h2>
        <p className="text-sm text-gray-600">
          {getText(
            'billing.creditAlert',
            activePlan === FREE ? 'upgrade' : 'nextInvoice',
          )}
        </p>
      </div>
    </div>
  );
};

export default BillingCreditBox;

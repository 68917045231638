import { Operation } from '@apollo/client';
import { BASE_CORE_URI, BASE_URI } from '../constants/auth';
import { IS_PRODUCTION, IS_QA } from '../constants/env';
import SafeStorage from './SafeStorage';

const LAMBDA_ENDPOINT_URI = `https://api.${
  IS_PRODUCTION ? 'noloco' : 'nolocoqa'
}.io`;

export const USE_LAMBDA_ENDPOINT_KEY = 'noloco.lambda-endpoint';
const hasLambdaLocalStorageFlag = new SafeStorage().getBoolean(
  USE_LAMBDA_ENDPOINT_KEY,
  false,
);

export const USE_PROJECT_LAMBDA_ENDPOINT =
  process.env.USE_LAMBDA_FOR_PROJECT_API === 'true' ||
  hasLambdaLocalStorageFlag;

export const USE_CORE_LAMBDA_ENDPOINT =
  process.env.USE_LAMBDA_FOR_CORE_API === 'true' || hasLambdaLocalStorageFlag;

const getProjectEndpointUri = (projectName: string, skipLambdas: boolean) => {
  let uri = BASE_URI;

  if (USE_PROJECT_LAMBDA_ENDPOINT && !skipLambdas && (IS_QA || IS_PRODUCTION)) {
    return `${LAMBDA_ENDPOINT_URI}/data/${projectName}`;
  }

  return `${uri}/data/${projectName}`;
};

export const getOperationUri = (baseProjectName?: string) => (
  operation: Operation,
) => {
  const {
    projectQuery,
    projectName = baseProjectName,
    skipLambdas,
  } = operation.getContext();

  const skipLambda =
    skipLambdas === true ||
    operation.query.definitions.some(
      (def) => def.kind === 'OperationDefinition' && def.operation !== 'query',
    );

  if (projectQuery && projectName) {
    return getProjectEndpointUri(projectName, skipLambda);
  }

  return USE_CORE_LAMBDA_ENDPOINT && !skipLambda && (IS_QA || IS_PRODUCTION)
    ? `${LAMBDA_ENDPOINT_URI}/core`
    : BASE_CORE_URI;
};

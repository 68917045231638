import React from 'react';
import RegisterForm from './RegisterForm';
import SimpleLayout from './SimpleLayout';

const CardRegister = ({
  children,
  disabled,
  errors,
  logoUrl,
  emailLabel,
  phoneNumberLabel,
  onSubmit,
  buttonText,
  confirmPasswordLabel,
  passwordLabel,
  loginText,
  titleText,
  hideEmail,
  email,
  phoneNumber,
  showPhoneNumber,
  password,
  confirmPassword,
  setEmail,
  setPhoneNumber,
  setPassword,
  setConfirmPassword,
  emailErrorMessage,
  phoneNumberErrorMessage,
  passwordErrorMessage,
  confirmPasswordErrorMessage,
  surface,
  socialLogins,
}: any) => (
  <SimpleLayout
    errors={errors}
    logoUrl={logoUrl}
    onSubmit={onSubmit}
    subTitleText={loginText}
    titleText={titleText}
  >
    <div className="bg-white p-10 sm:px-4 mt-6 shadow overflow-hidden sm:rounded-none rounded-lg">
      <RegisterForm
        disabled={disabled}
        emailLabel={emailLabel}
        phoneNumberLabel={phoneNumberLabel}
        buttonText={buttonText}
        confirmPasswordLabel={confirmPasswordLabel}
        passwordLabel={passwordLabel}
        hideEmail={hideEmail}
        email={email}
        phoneNumber={phoneNumber}
        showPhoneNumber={showPhoneNumber}
        password={password}
        confirmPassword={confirmPassword}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        emailErrorMessage={emailErrorMessage}
        phoneNumberErrorMessage={phoneNumberErrorMessage}
        passwordErrorMessage={passwordErrorMessage}
        confirmPasswordErrorMessage={confirmPasswordErrorMessage}
        surface={surface}
        socialLogins={socialLogins}
      >
        {children}
      </RegisterForm>
    </div>
  </SimpleLayout>
);

export default CardRegister;

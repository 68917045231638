import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { Dropdown, getColorShade } from '@noloco/components';
import { baseColors } from '@noloco/core/src/constants/tailwindStyles';
import { getText } from '@noloco/core/src/utils/lang';

const SimpleButton = ({ children, className }: any) => (
  <div className={classNames(className, '')}>{children}</div>
);

export const ColorSwatch = ({ className, color }: any) => (
  <Box
    className={classNames('w-5 h-5 rounded-full flex-shrink-0', className)}
    bg={getColorShade(color, 400)}
  />
);

const ColorOptionInput = ({ className, onChange, readOnly, value }: any) => {
  const [localValue, setLocalValue] = useState(value);
  const colorOptions = useMemo(
    () =>
      [...baseColors]
        .filter((color) => color !== 'slate')
        .reverse()
        .map((color) => ({
          value: color,
          label: (
            <div className="flex items-center w-40">
              <ColorSwatch className="mr-2" color={color} />
              <span>{getText('colors', color)}</span>
            </div>
          ),
        })),
    [],
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleOnChange = useCallback(
    (nextValue) => {
      if (onChange) {
        onChange(nextValue);
      }
      setLocalValue(nextValue);
    },
    [onChange],
  );

  return (
    <div className={className}>
      <Dropdown
        Button={SimpleButton}
        className="flex items-center justify-center"
        contained={true}
        disabled={readOnly}
        options={colorOptions}
        onChange={handleOnChange}
        value={localValue}
      >
        <ColorSwatch color={localValue || 'gray'} />
      </Dropdown>
    </div>
  );
};

export default ColorOptionInput;

import React, { memo } from 'react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { DEPRECATED_PLANS } from '@noloco/core/src/constants/accountPlanTypes';
import { AccountPlan } from '@noloco/core/src/constants/accountPlans';
import { getText } from '@noloco/core/src/utils/lang';

type Props = {
  activePlan: AccountPlan | undefined;
  className?: string;
  compact?: boolean;
  surface?: Surface;
};

const LANG_KEY = 'billing.legacyWarning';

const LegacyPlanBanner = memo(
  ({ activePlan, className, compact, surface }: Props) => {
    if (!activePlan || !DEPRECATED_PLANS.includes(activePlan)) {
      return null;
    }

    return (
      <div
        className={classNames(
          className,
          'flex text-white items-center justify-between rounded-lg',
          {
            'bg-pink-400': surface !== DARK,
            'bg-teal-400': surface === DARK,
            'p-8': !compact,
            'p-4': compact,
          },
        )}
      >
        <div className="flex flex-col">
          <h2
            className={classNames('font-medium', {
              'text-xl': !compact,
              'text-lg': compact,
            })}
          >
            {getText(LANG_KEY, 'title')}
          </h2>
          <p className={classNames('mt-2 text-white', { 'text-sm': compact })}>
            {getText(LANG_KEY, 'subtitle')}
          </p>
        </div>
        {!compact && (
          <a
            href="https://noloco.io/pricing"
            className={classNames(
              'ml-6 font-medium py-4 px-6 rounded-lg whitespace-nowrap',
              {
                'bg-pink-100 text-pink-500 hover:bg-pink-200 hover:text-pink-700':
                  surface !== DARK,
                'bg-teal-100 text-teal-500 hover:bg-teal-200 hover:text-teal-700':
                  surface === DARK,
              },
            )}
            target="_blank"
            rel="noreferrer noopener"
          >
            {getText(LANG_KEY, 'cta')}
          </a>
        )}
      </div>
    );
  },
);

export default LegacyPlanBanner;

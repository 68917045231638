import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import flagsmith from 'flagsmith';
import T from 'i18n-react';
import { Provider as AlertProvider } from 'react-alert';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import '@noloco/components/src/index.css';
import { LocaleProvider } from '@noloco/components/src/utils/hooks/useLocale';
import { AUTH_TOKEN } from '@noloco/core/src/constants/auth';
import { gitCommitHash } from '@noloco/core/src/gitCommit';
import { ProvideAuth } from '@noloco/core/src/utils/hooks/useAuth';
import App from './components/App';
import AlertNotification from './components/Notification';
import {
  FLAGSMITH_ENV_ID,
  IS_PLAYGROUND,
  IS_PRODUCTION,
} from './constants/env';
import tailwindTheme from './constants/tailwindTheme';
import langFile from './lang/en';
import * as serviceWorker from './serviceWorker';
import client from './utils/apolloClient';
import store from './utils/store';

flagsmith
  .init({
    environmentID: FLAGSMITH_ENV_ID,
  })
  .catch((e) => console.error('Failed to configure flags', e));

T.setTexts(langFile, { MDFlavor: 1 });

console.log('IS_PLAYGROUND', IS_PLAYGROUND);

if (IS_PRODUCTION) {
  Sentry.init({
    dsn:
      'https://e7715c8b78f24793923e8ca8b2fc2b38@o450569.ingest.sentry.io/5435141',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01,
    release: gitCommitHash,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider
      store={window.parent ? (window.parent as any).__REDUX_STORE__ : store}
    >
      <BrowserRouter>
        <LocaleProvider>
          <AlertProvider template={AlertNotification} offset="6px 18px">
            <ApolloProvider client={client}>
              <TailwindThemeProvider theme={tailwindTheme}>
                <ProvideAuth authTokenKey={AUTH_TOKEN}>
                  <App />
                </ProvideAuth>
              </TailwindThemeProvider>
            </ApolloProvider>
          </AlertProvider>
        </LocaleProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

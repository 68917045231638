import React, { forwardRef } from 'react';
import {
  FillButton,
  LinkButton,
  OutlineButton,
  TextButton,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { LG, MD, SM, ShirtSize } from '../../constants/tShirtSizes';
import { PRIMARY, Variant } from '../../constants/variants';
import {
  ButtonStyle,
  ROUNDED_FULL,
  ROUNDED_LARGE,
  SHADOW,
  SQUARE,
  UPPERCASE,
} from './buttonStyles';
import { ButtonType, FILLED, LINK, OUTLINE, TEXT } from './buttonTypes';

export const typeMap = {
  default: FillButton,
  [FILLED]: FillButton,
  [LINK]: LinkButton,
  [TEXT]: TextButton,
  [OUTLINE]: OutlineButton,
};

export const themeStyles = {
  default: () => '',
  [ROUNDED_LARGE]: () => ({ rounded: 'lg' }),
  [ROUNDED_FULL]: () => ({ rounded: 'full' }),
  [SQUARE]: () => ({ rounded: false }),
  [UPPERCASE]: () => ({ uppercase: true }),
  [SHADOW]: (type: any) => (type !== LINK ? { shadow: true } : {}),
};

type Props = {
  className?: string;
  disabled?: boolean;
  type?: ButtonType;
  buttonType?: string;
  size?: ShirtSize;
  style?: ButtonStyle;
  variant?: Variant;
  children: any;
  rounded: any;
  border: any;
  theme?: any;
};

const Button = forwardRef<any, Props & Record<any, any>>(
  (
    {
      buttonType,
      children,
      className,
      disabled,
      onClick,
      style,
      size,
      submitFormOnClick,
      type,
      variant,
      theme,
      ...rest
    },
    ref,
  ) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const Component = typeMap[type] || typeMap.default;
    const handleOnClick = (e: any) => {
      if (disabled) {
        e.preventDefault();
      }

      if (onClick && !disabled) {
        onClick(e);
      }
    };

    return (
      <Component
        brand={
          variant === 'custom' || variant === 'secondary' ? undefined : variant
        }
        disabled={disabled}
        className={classNames(className, 'font-medium tracking-wide', {
          'text-xs sm:h-8': size === SM,
          'text-sm sm:h-10': size === MD,
          'text-base': size === LG,
        })}
        onClick={handleOnClick}
        type={submitFormOnClick ? 'submit' : buttonType}
        ref={ref}
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        {...themeStyles[style](type, size)}
        {...(size === SM ? { p: { x: 1.5, y: 1.5 } } : {})}
        {...(variant === 'secondary'
          ? {
              bg:
                theme?.surfaceColors[
                  theme?.secondaryButton?.surface ?? 'default'
                ],
              text:
                theme?.secondaryButton?.textColor ?? theme?.surfaceColors.dark,
              border:
                type !== TEXT && type !== LINK
                  ? [
                      true,
                      `${
                        theme.borderColors[theme?.secondaryButton?.borderColor]
                      }`,
                    ]
                  : undefined,
            }
          : {})}
        {...rest}
        large={size === LG}
        small={size === SM}
      >
        {children}
      </Component>
    );
  },
);

Button.defaultProps = {
  className: '',
  disabled: false,
  type: 'default',
  size: MD,
  style: ROUNDED_LARGE,
  variant: PRIMARY,
};

export default withTheme(Button);

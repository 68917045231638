import React, { memo } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { trackGuideLinkClick } from '@noloco/core/src/utils/analytics';

type GuideProps = {
  children: React.ReactNode;
  className?: string;
  hideIcon?: boolean;
  href?: string;
};

const Guide = memo(
  ({ children, className, hideIcon = false, href }: GuideProps) =>
    href ? (
      <a
        className={classNames(
          className,
          'hover:text-blue-500 flex items-center text-blue-400 w-full',
        )}
        onClick={() => trackGuideLinkClick(href)}
        target="_blank"
        rel="noreferrer noopener"
        href={href}
      >
        <span className="font-medium mr-2">{children}</span>
        {!hideIcon && <IconInfoCircle className="ml-auto" size={16} />}
      </a>
    ) : null,
);

export default Guide;

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { Project } from '@noloco/core/src/models/Project';
import { GET_FULL_DATA_SOURCE } from '../../queries/project';

export const useExistingDataSource = (
  id: number | undefined,
  project: Project,
) => {
  const isUpdate = useMemo(() => id !== undefined, [id]);
  const isConnect = useMemo(() => !isUpdate, [isUpdate]);

  const dataSourceQueryResult = useQuery(GET_FULL_DATA_SOURCE, {
    variables: { projectId: project.name, id },
    fetchPolicy: 'no-cache',
    skip: !isUpdate,
  });

  const existingDataSource = useMemo(
    () => get(dataSourceQueryResult, 'data.dataSource'),
    [dataSourceQueryResult],
  );

  const loading = useMemo(() => get(dataSourceQueryResult, 'loading'), [
    dataSourceQueryResult,
  ]);

  return {
    existingDataSource,
    isConnect,
    isUpdate,
    loading,
  };
};

import React, {
  Suspense,
  lazy,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  IconCircleX,
  IconPlus,
  IconRefresh,
  IconUpload,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface, Tooltip } from '@noloco/components';
import Loader from '@noloco/components/src/components/loading/Loader';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  LG,
  MD,
  SM,
  ShirtSize,
  XL,
} from '@noloco/components/src/constants/tShirtSizes';
import BaseDropzone from '../../../components/dropzone/BaseDropzone';
import { CIRCLE, FILE_NAME, INLINE } from '../../../constants/fileDisplay';
import {
  FileFieldElementType,
  LINK,
  PLAIN,
  PREVIEW_MODAL,
} from '../../../constants/fileFieldElementTypes';
import fileTypes, { IMAGE } from '../../../constants/fileTypes';
import { IMAGE_HEIC } from '../../../constants/mimetypes';
import { FileLayout } from '../../../models/Element';
import { File } from '../../../models/File';
import { useFilePreview } from '../../../utils/hooks/useFilePreview';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import DropzonePreview from './DropzonePreview';

const LazyFilePreviewModal = lazy(() => import('./FilePreviewModal'));

const PREVIEW_SIZES = {
  [SM]: 14,
  [MD]: 20,
  [LG]: 36,
  [XL]: 48,
};

const getHeight = (size: ShirtSize | undefined, defaultVal: number) => {
  if (!size) {
    return defaultVal;
  }

  return PREVIEW_SIZES[size] ?? defaultVal;
};

type FilePreviewProps = {
  acceptedMimetypes?: string[];
  fileTypeValidation?: any;
  className?: string;
  elementType?: FileFieldElementType;
  layout?: FileLayout;
  files: File[];
  id?: string;
  isMultiple: boolean;
  maxH?: number;
  maxFiles?: number;
  maxSize?: number;
  onChange?: (value: null | any) => void;
  onRemove?: (fileId: number) => void;
  placeholder?: string;
  readOnly: boolean | undefined;
  surface?: Surface;
};

const FilePreview = memo(
  ({
    acceptedMimetypes,
    fileTypeValidation,
    className,
    elementType,
    layout,
    files,
    id,
    isMultiple,
    maxH = 20,
    maxFiles,
    maxSize,
    onChange = () => null,
    onRemove,
    placeholder,
    readOnly,
    surface,
  }: FilePreviewProps) => {
    const LANG_KEY = 'elements.VIEW';
    const [localOpenIndex, setOpenIndex] = useState(null);
    const disableModal = elementType && elementType !== PREVIEW_MODAL;
    const {
      replaceQueryParams,
      query: { _fileId, _fileField },
    } = useRouter();

    const FileElement = elementType === LINK ? 'a' : 'div';

    const filteredFiles = useMemo(() => (files ? files.filter(Boolean) : []), [
      files,
    ]);

    const openIndex = useMemo(
      () =>
        localOpenIndex ||
        (_fileField === String(id) &&
          filteredFiles.findIndex((file: any) => file.id === _fileId)),
      [_fileField, _fileId, filteredFiles, id, localOpenIndex],
    );

    const getOnFileClick = useCallback(
      (index: any) => {
        if (elementType === PLAIN) {
          return undefined;
        }

        return (event: any) => {
          event.stopPropagation();

          if (!disableModal) {
            event.preventDefault();

            if (id) {
              const file = files[index];
              if (file) {
                return replaceQueryParams({
                  _fileId: String(file.id),
                  _fileField: id,
                });
              }
            }

            return setOpenIndex(index);
          }
        };
      },
      [disableModal, elementType, files, id, replaceQueryParams],
    );

    const onClose = useCallback(() => {
      setOpenIndex(null);
      replaceQueryParams({
        _fileId: undefined,
        _fileField: undefined,
      });
    }, [replaceQueryParams]);

    const { squareRounding } = useFilePreview(maxH);

    const allowedFileTypesToolTip = useMemo(() => {
      const allowedFileTypes = fileTypes
        ?.filter((fileType) => fileTypeValidation?.[fileType] !== false)
        .map((fileType) =>
          getText(LANG_KEY, 'fileType.fileTypeButtons', fileType),
        );

      const toolTipText =
        allowedFileTypes.length > 1
          ? getText(
              {
                allowedFileTypes: allowedFileTypes.slice(0, -1).join(', '),
                lastFileType: allowedFileTypes.slice(-1)[0],
              },
              LANG_KEY,
              'fileType.list',
            )
          : getText(
              { fileTyle: allowedFileTypes[0] },
              LANG_KEY,
              'fileType.singlFileTypeToolTip',
            ) ?? '';

      return toolTipText;
    }, [fileTypeValidation, LANG_KEY]);

    return (
      <div className={classNames('flex flex-wrap w-full gap-2', className)}>
        {files.map(
          (file: any, index: any) =>
            file && (
              <FileElement
                className={classNames(
                  'overflow-hidden flex items-center flex-shrink-0',
                  {
                    'hover:ring-2 hover:ring-opacity-50 hover:ring-gray-400 cursor-pointer':
                      elementType !== PLAIN && layout?.display !== FILE_NAME,
                    'max-w-full':
                      layout?.display === FILE_NAME ||
                      layout?.display === INLINE,
                    'hover:underline cursor-pointer':
                      layout?.display === FILE_NAME && elementType !== PLAIN,
                    [squareRounding]:
                      layout?.display !== CIRCLE ||
                      file.fileType !== IMAGE ||
                      file.mimetype === IMAGE_HEIC,
                    'rounded-full':
                      layout?.display === CIRCLE &&
                      file.fileType === IMAGE &&
                      file.mimetype !== IMAGE_HEIC,
                  },
                )}
                target={elementType === LINK ? '_blank' : undefined}
                href={elementType === LINK ? file.url : undefined}
                onClick={getOnFileClick(index)}
                key={file.id}
              >
                <DropzonePreview
                  id={id}
                  loading={false}
                  className="max-w-full"
                  placeholder={placeholder}
                  fileType={file.fileType}
                  mediaItem={file}
                  maxH={getHeight(layout?.size, maxH)}
                  display={layout?.display}
                  showIcon={false}
                  disabled={true}
                  onRemove={
                    isMultiple
                      ? onRemove
                      : readOnly
                      ? undefined
                      : () => onChange(null)
                  }
                  surface={surface}
                />
              </FileElement>
            ),
        )}
        {!readOnly && (
          <Tooltip
            content={allowedFileTypesToolTip}
            disabled={fileTypeValidation === undefined}
          >
            <BaseDropzone
              acceptedMimetypes={acceptedMimetypes}
              disabled={false}
              id={id}
              maxSize={maxSize}
              maxFiles={isMultiple ? maxFiles : 1}
              onChange={onChange}
            >
              {({ isDragAccept, isDragReject, isDragActive }: any) => (
                <div
                  className={classNames(
                    squareRounding,
                    'flex items-center justify-center border-2 border-dashed',
                    {
                      'dark:border-gray-700 text-gray-500 dark:text-gray-200':
                        surface !== DARK,
                      'border-gray-500 text-gray-500': surface === DARK,
                      ' hover:bg-gray-100 dark:hover:bg-gray-900':
                        surface !== DARK && !isDragActive,
                      'hover:bg-slate-800': surface === DARK && !isDragActive,
                      'bg-blue-100 dark:bg-gray-700':
                        isDragActive && !isDragAccept && !isDragReject,
                      'bg-blue-200 dark:bg-gray-800':
                        isDragActive && isDragAccept && !isDragReject,
                      'bg-red-100 dark:bg-red-700':
                        isDragActive && !isDragAccept && isDragReject,
                    },
                    `h-${maxH}`,
                    {
                      [`w-${maxH}`]:
                        isMultiple ||
                        isDragActive ||
                        filteredFiles.length === 0,
                      'w-8':
                        !isMultiple &&
                        filteredFiles.length > 0 &&
                        !isDragActive,
                    },
                  )}
                >
                  {!isDragActive && (isMultiple || filteredFiles.length === 0) && (
                    <Tooltip disabled={!placeholder} content={placeholder}>
                      <span>
                        <IconPlus size={14} />
                      </span>
                    </Tooltip>
                  )}
                  {!isDragActive && !isMultiple && filteredFiles.length > 0 && (
                    <Tooltip disabled={!placeholder} content={placeholder}>
                      <span>
                        <IconRefresh size={14} />
                      </span>
                    </Tooltip>
                  )}
                  {isDragActive && isDragAccept && <IconUpload size={14} />}
                  {isDragActive && isDragReject && <IconCircleX size={14} />}
                </div>
              )}
            </BaseDropzone>
          </Tooltip>
        )}
        {openIndex !== false && openIndex >= 0 && !disableModal && (
          <Suspense fallback={<Loader />}>
            <LazyFilePreviewModal // @ts-expect-error TS(2322): Type '{ id: any; onClose: () => void; onRemove: an... Remove this comment to see the full error message
              id={id}
              onClose={onClose}
              onRemove={!readOnly ? onRemove : undefined}
              initialIndex={openIndex}
              files={filteredFiles}
              queryFileId={_fileId}
            />
          </Suspense>
        )}
      </div>
    );
  },
);

export default FilePreview;

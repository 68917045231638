import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button, FormField, Loader } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { SMART_SUITE } from '@noloco/core/src/constants/dataSources';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import smartSuiteLogo from '../../img/smartsuite-logo.png';
import { useAddDataSource } from '../../utils/hooks/useAddDataSource';
import Guide from '../Guide';
import ConnectSmartSuiteSolution from './ConnectSmartSuiteSolution';
import DataSourceImportStatus from './DataSourceImportStatus';

const LANG_KEY = 'data.smartSuite';

const AddSmartSuiteSolution = ({
  inOnboarding = false,
  project,
  onConnect,
  surface = DARK,
}: any) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const {
    query: { sourceName },
  }: any = useRouter();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | null>(
    null,
  );
  const [solutionName, setSolutionName] = useState(
    sourceName ?? 'My SmartSuite Solution',
  );
  const [selectedSolutionId, setSelectedSolutionId] = useState<number | null>(
    null,
  );

  useSetDocumentTitle(`${getText(LANG_KEY, 'title')} SmartSuite`);

  const hasSmartSuiteOAuthIntegration = !!get(
    project,
    'integrations.smartSuite',
    false,
  );

  const { token } = useAuth();

  const authToken = useMemo(
    () => (inOnboarding ? project.apiKeys?.project : token),
    [inOnboarding, project.apiKeys?.project, token],
  );

  const formSteps = 1;

  const {
    builtPages,
    createdDataSource,
    error,
    existingDataSource,
    hasSyncedData,
    inProgressPages,
    isConnect,
    isConnecting,
    isNameValid,
    isUpdate,
    isUpdating,
    onClickNext,
    onDataTypesSelected,
    onFinish,
    skippedPages,
    step,
  } = useAddDataSource({
    project,
    connection: {
      solutionId: selectedSolutionId,
      workspaceId: selectedWorkspaceId,
    },
    display: solutionName,
    type: SMART_SUITE,
    formSteps,
    onAuthenticationFail: null,
    inOnboarding,
    canShowTableChooser: true,
  });

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTo({
        top: bottomRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [step, bottomRef]);

  useEffect(() => {
    if (existingDataSource && Object.keys(existingDataSource).length > 0) {
      setSolutionName(existingDataSource.display);
    }
  }, [existingDataSource]);

  const isStepValid = useMemo(() => isNameValid && !!selectedSolutionId, [
    isNameValid,
    selectedSolutionId,
  ]);

  return (
    <div className="w-full flex">
      <div
        className={classNames('w-full max-w-xl text-white p-8', {
          'bg-slate-700': !inOnboarding,
          'border-r border-gray-100': inOnboarding,
        })}
      >
        {!inOnboarding && (
          <>
            <h1 className="text-xl flex items-center">
              <span>{getText(LANG_KEY, 'title')}</span>
              <img
                src={smartSuiteLogo}
                alt="SmartSuite logo"
                className="h-8 ml-2 mr-1"
              />
              <span>{getText(LANG_KEY, 'sourceName')}</span>
            </h1>
            <Guide
              className="mt-4"
              href="https://guides.noloco.io/data/smartsuite"
            >
              {getText(LANG_KEY, 'help')}
            </Guide>
          </>
        )}
        <ConnectSmartSuiteSolution
          authToken={authToken}
          disabled={!isConnect || step >= 2}
          hasSmartSuiteOAuthIntegration={hasSmartSuiteOAuthIntegration}
          onChangeSolutionId={setSelectedSolutionId}
          onChangeWorkspaceId={setSelectedWorkspaceId}
          onNameChange={setSolutionName}
          projectName={project.name}
          solutionId={selectedSolutionId}
          surface={surface}
          workspaceId={selectedWorkspaceId}
        />
        {selectedSolutionId && (
          <FormField
            name="name"
            type="text"
            readOnly={step > 0}
            disabled={step > 0}
            onChange={({ target: { value } }: any) => setSolutionName(value)}
            required
            errorMessage={
              !solutionName || isNameValid
                ? null
                : getText(LANG_KEY, 'name.invalid')
            }
            errorType="below-solid"
            label={getText(LANG_KEY, 'name.label')}
            help={getText(LANG_KEY, 'name.help')}
            placeholder={getText(LANG_KEY, 'name.placeholder')}
            value={solutionName}
            surface={surface}
          />
        )}
        {isConnect && step < 2 && (
          <Button
            className="disabled:opacity-75 flex items-center mt-6"
            disabled={!isStepValid}
            onClick={onClickNext}
          >
            {isUpdate && isUpdating ? (
              <>
                <span>{getText(LANG_KEY, 'updating')}</span>
                <Loader size="sm" className="ml-2" />
              </>
            ) : (
              <>
                <span>{getText(LANG_KEY, 'next')}</span>
                <IconArrowNarrowRight size={16} className="opacity-75 ml-2" />
              </>
            )}
          </Button>
        )}
      </div>
      <div
        className={classNames('w-full max-h-screen overflow-y-auto', {
          'bg-slate-800': !inOnboarding,
        })}
        ref={inOnboarding ? null : bottomRef}
      >
        <div
          className={classNames('w-full flex items-center justify-center', {
            'max-h-screen-75 overflow-y-auto': inOnboarding,
          })}
          ref={inOnboarding ? bottomRef : null}
        >
          {((isConnect && step >= formSteps) || error) && (
            <DataSourceImportStatus
              builtPages={builtPages}
              createdDataSource={createdDataSource}
              error={error}
              formSteps={formSteps}
              hasSyncedData={hasSyncedData}
              inProgressPages={inProgressPages}
              isConnecting={isConnecting}
              onDataTypesSelected={onDataTypesSelected}
              onFinish={inOnboarding ? onConnect : onFinish}
              project={project}
              sourceType={SMART_SUITE}
              skippedPages={skippedPages}
              step={step}
              inOnboarding={inOnboarding}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddSmartSuiteSolution;

import React, { useCallback, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IconExternalLink } from '@tabler/icons-react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, HelpTooltip } from '@noloco/components';
import { FLAT_PLANS } from '@noloco/core/src/constants/accountPlanTypes';
import {
  ENTERPRISE,
  EXPERT,
  FREE,
} from '@noloco/core/src/constants/accountPlans';
import { EXTERNAL_USERS, Feature } from '@noloco/core/src/constants/features';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { ExtendedPlanUsage, Usage } from '@noloco/core/src/models/Usage';
import { UserTeam } from '@noloco/core/src/models/User';
import { isTrialActive } from '@noloco/core/src/utils/billing';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { STRIPE_PK } from '../constants/env';
import { teamsUsageSelector } from '../selectors/teamsUsageSelectors';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import AccountUsage from './AccountUsage';
import BillingCreditBox from './BillingCreditBox';
import CancelPlanButton from './CancelPlanButton';
import ExpandableUsage from './ExpandableUsage';
import LegacyPlanBanner from './LegacyPlanBanner';
import PlanPicker from './PlanPicker';
import PlansHelpLink from './PlansHelpLink';
import ChangePlanModal from './dashboard/ChangePlanModal';
import OpenCustomerPortalButton from './dashboard/OpenCustomerPortalButton';
import TrialBanner from './dashboard/TrialBanner';

const stripePromise = loadStripe(STRIPE_PK);

const Billing = ({
  usage,
  billingPlan,
  workspace,
}: {
  usage: Usage;
  workspace: UserTeam;
  billingPlan: BillingPlan;
}) => {
  const { user } = useDashboardAuth();
  const { pushQueryParams } = useRouter();
  const [activePlan, setActivePlan] = useState(billingPlan.type);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const hasPaymentMethod = !!workspace.plan.paymentMethodId;

  const isTrialing = useMemo(() => isTrialActive(billingPlan), [billingPlan]);

  useTrackDashboardPage(PageTypes.BILLING);

  const onClickCancel = useCallback(() => {
    setActivePlan(FREE);
    setShowChangePlanModal(true);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className="flex flex-col p-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-medium">
            {getText('billing.plan.title')}
          </h1>
          <div className="flex items-center ml-auto">
            <span className="text-sm text-gray-500 tracking-wide">
              <Link
                className="hover:underline"
                to={`/workspaces/${workspace.id}`}
              >
                {workspace.name}
              </Link>
            </span>
            {!isTrialing && billingPlan && billingPlan.type !== FREE && (
              <Badge m={undefined} className="ml-4">
                <span className="text-sm">
                  {getText('billing.plans', billingPlan.type, 'name')}
                </span>
              </Badge>
            )}
          </div>
        </div>
        <TrialBanner
          linkIs="a"
          plan={billingPlan}
          href="https://noloco.io/pricing"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="flex items-center">
            <span className="mr-1"> {getText('billing.plan.help')}</span>
            <IconExternalLink size={14} />
          </div>
        </TrialBanner>
        <LegacyPlanBanner activePlan={activePlan} className="mt-6" />
        <BillingCreditBox billingPlan={billingPlan} activePlan={activePlan} />
        <PlanPicker
          activePlan={activePlan}
          billingPlan={billingPlan}
          className="mt-8"
          onSelectActivePlan={setActivePlan}
        />
        <div className="flex items-center mt-8">
          {billingPlan && ![EXPERT, ENTERPRISE].includes(billingPlan.type) && (
            <Button
              onClick={() => setShowChangePlanModal(true)}
              disabled={
                user!.team.plan.type === activePlan &&
                (activePlan === FREE || FLAT_PLANS.includes(activePlan))
              }
              className="mr-auto rounded-lg"
            >
              {getText(
                'billing.plan',
                hasPaymentMethod
                  ? user!.team.plan.type === activePlan
                    ? 'manage'
                    : 'change'
                  : 'add',
              )}
            </Button>
          )}
          {billingPlan && ![EXPERT, ENTERPRISE].includes(billingPlan.type) && (
            <CancelPlanButton
              className="mr-4"
              activePlan={billingPlan.type}
              onClick={onClickCancel}
            />
          )}
          <OpenCustomerPortalButton
            workspace={workspace}
            plan={workspace.plan.type}
          />
        </div>
        <PlansHelpLink className="mt-8" />
        {usage && get(usage, ['plan']) && (
          <>
            <div className="flex flex-col mb-4 mt-8">
              <div className="flex items-center">
                <h1 className="text-2xl font-medium">
                  {getText('features.usage.title')}
                </h1>
                <HelpTooltip className="text-gray-600 ml-2" placement="right">
                  <span>{getText('features.usage.tooltip.text')}</span>
                  <a
                    href="https://guides.noloco.io/account/pricing"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="mt-2 text-pink-500 hover:text-pink-700"
                  >
                    {getText('features.usage.tooltip.cta')}
                  </a>
                </HelpTooltip>
              </div>
              <p className="text-base mt-2">
                {getText('features.usage.description')}
              </p>
            </div>
            <AccountUsage
              usage={get(usage, ['plan']) as ExtendedPlanUsage}
              planType={billingPlan.type}
            />
            {get(usage, ['projects'], []).map((project: any) => (
              <ExpandableUsage
                filterFeatures={
                  project.addons.externalUsers
                    ? null
                    : (feature: Feature) => feature !== EXTERNAL_USERS
                }
                usage={project.usage}
              >
                <div className="flex items-center">
                  <h2 className="text-xl font-medium">{project.name}</h2>
                </div>
              </ExpandableUsage>
            ))}
          </>
        )}
        {showChangePlanModal && activePlan && user && (
          <ChangePlanModal
            billingPlan={billingPlan}
            workspace={workspace}
            onClose={() => {
              setShowChangePlanModal(false);
              pushQueryParams({ status: undefined });
            }}
            hasPaymentMethod={!!hasPaymentMethod}
            selectedPlan={activePlan}
            teamUsage={usage}
            user={user}
          />
        )}
      </div>
    </Elements>
  );
};

const BillingWrapper = () => {
  const {
    query: { workspace: workspaceId },
  } = useRouter();
  const { user } = useDashboardAuth();
  const teamsUsage = useSelector(teamsUsageSelector);

  const workspace = useMemo(
    () => user!.teams.find((team) => team.id === workspaceId),
    [user, workspaceId],
  );

  const usage = useMemo(
    () =>
      workspace
        ? teamsUsage.find((team) => String(team.id) === workspace.id)
        : undefined,
    [teamsUsage, workspace],
  );

  if (!workspace || !usage) {
    return null;
  }

  return (
    <Billing
      key={workspace.id}
      billingPlan={workspace.plan}
      usage={usage.plan.usage}
      workspace={workspace}
    />
  );
};

export default BillingWrapper;

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { DARK } from '../../constants/surface';
import { SM, ShirtSize } from '../../constants/tShirtSizes';
import { Surface } from '../../models';

export type OptionValue = string | number | boolean | undefined;

type OnChangeFunction = (value: OptionValue, path?: string[]) => void;

type SwitchButtonProps = {
  className?: string;
  inverseColors?: boolean;
  surface?: Surface;
  onChange: OnChangeFunction;
  options: {
    label: string;
    value: OptionValue;
    disabled?: boolean;
    path?: string;
    Icon?: any;
  }[];
  size?: ShirtSize;
  fullWidth?: boolean;
  value: OptionValue;
  multiple?: boolean;
};

const SwitchButton = ({
  className,
  inverseColors = false,
  surface = DARK,
  fullWidth = true,
  onChange,
  options,
  size = SM,
  value,
  multiple = false,
}: SwitchButtonProps) => {
  const cols = useMemo(() => {
    if (options.length <= 5) {
      return options.length;
    }

    if (options.length % 3) {
      return 4;
    }

    return 3;
  }, [options]);

  return (
    <div
      className={classNames(
        className,
        'flex items-center justify-center gap-1 rounded p-1 grid',
        `text-${size} grid-cols-${cols}`,
        surface === DARK
          ? {
              'text-gray-300': true,
              'bg-slate-900': !inverseColors,
              'bg-slate-700': inverseColors,
              'h-14': multiple,
              'w-full': fullWidth,
            }
          : {
              'bg-white border border-gray-200 text-gray-500': true,
            },
      )}
    >
      {options.map(
        (
          { value: optionValue, label, disabled = false, path, Icon },
          index,
        ) => (
          <div
            key={`${optionValue?.toString()}${index}`}
            onClick={
              !disabled
                ? multiple
                  ? () => onChange(!optionValue, [path ?? ''])
                  : () => onChange(optionValue)
                : undefined
            }
            className={classNames(
              'flex flex-col items-center justify-center rounded px-2',
              surface === DARK
                ? {
                    'hover:bg-slate-800': !disabled,
                    'bg-slate-600': multiple
                      ? optionValue !== false && !inverseColors
                      : value === optionValue && !inverseColors,
                    'bg-slate-900': multiple
                      ? optionValue !== false && inverseColors
                      : value === optionValue && inverseColors,
                    'hover:bg-gray-200':
                      value !== optionValue && inverseColors && !disabled,
                  }
                : {
                    'bg-gray-500 text-white hover:bg-gray-600':
                      value === optionValue,
                    'hover:bg-gray-200': value !== optionValue,
                  },
              {
                'py-1': multiple,
                'cursor-pointer': !disabled,
                'cursor-not-allowed opacity-75': disabled,
              },
            )}
          >
            {Icon && <Icon size={20} />}
            {label}
          </div>
        ),
      )}
    </div>
  );
};

export default SwitchButton;
